import NavbarMain from "../../Components/NavbarMain";
import "./main.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import Storecard from "../../Components/StoreCardHome";
import { useEffect, useRef, useState } from "react";
import { getAllCategories, getServiceData } from "../../api/apiListingService";
import { format } from "date-fns";
import {
  capitalizeFirstLetterInSentence,
  formatAmount,
} from "../../utils/generalUtils";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil/atoms/userAtom";
import { formatSearchDateTime } from "../../utils/dateFomater";
import AboutStore from "../StoreDetail/AboutStore";
import { Link, useHistory } from "react-router-dom";
// import {
//   tourGuideStatusState,
//   tourGuideStepState,
// } from "../../recoil/atoms/tourAtom";
import Slider from "react-slick";
import moment from "moment-timezone";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  ltr: true,
};

const Landing = () => {
  const [opens, setOpens] = useState<any>(false);
  const [services, setData] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategories] = useState<any>(null);
  const [activeIndex, setActIndex] = useState<any>();
  const [currentUser] = useRecoilState(userState);
  const [scrollPosition, setScrollPosition] = useState(0);
  const categoryContainerRef = useRef<any>(null);
  const [, setTotalWidth] = useState(0);
  // const [, setTourStep] = useRecoilState(tourGuideStepState);
  // const [tourGuideStatus, setTourGuideStatus] =
  //   useRecoilState(tourGuideStatusState);
  const [upcomingServices, setUpcomingServices] = useState([]);
  const history = useHistory();

  // useEffect(() => {
  //   setTourStep(0);
  //   if (tourGuideStatus !== "skipped") {
  //     setTourGuideStatus("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // Calculate total width of all buttons when the component mounts or when categories change
    if (categoryContainerRef.current) {
      const totalWidth = Array.from(
        categoryContainerRef.current.children
      ).reduce((acc, child) => acc + (child as any).offsetWidth, 0);
      const maxScroll = Math.max(
        0,
        (totalWidth as any) - categoryContainerRef.current.offsetWidth
      );
      setMaxScroll(maxScroll);
    }
  }, [categories]);

  const [maxScroll, setMaxScroll] = useState(0);

  useEffect(() => {
    if (categoryContainerRef.current) {
      const width = Array.from(categoryContainerRef.current.children).reduce(
        (acc, child) =>
          acc +
          (child as any).offsetWidth +
          parseFloat(getComputedStyle(child as any).marginLeft) +
          parseFloat(getComputedStyle(child as any).marginRight),
        0
      );
      setTotalWidth(width as any);
      const maxScroll = Math.max(
        0,
        (width as any) - categoryContainerRef.current.offsetWidth
      );
      setMaxScroll(maxScroll);
    }
  }, [categories]);

  // useEffect(() => {
  //   console.log("Categories:", categories);
  //   console.log(
  //     "Button Container Width:",
  //     categoryContainerRef.current?.offsetWidth
  //   );
  //   console.log("Total Width:", totalWidth);
  //   console.log("Max Scroll:", maxScroll);
  // }, [categories, categoryContainerRef, totalWidth, maxScroll]);

  const scrollCategories = (distance: any) => {
    const newScrollPosition = Math.max(
      0,
      Math.min(scrollPosition + distance, maxScroll)
    );
    setScrollPosition(newScrollPosition);
  };

  const fetchDataFunction = async (date: string, time: any) => {
    try {
      setOpens(true);
      setData([]);
      // if (JSON.stringify(time).length > 10) {
      //    time = setDefaultTime()
      // }
      time = new Date(time);
      const hours = time.getHours();
      const minutes = time.getMinutes().toString().padStart(2, "0");
      time = `${hours}:${minutes}`;
      let newDate: any = new Date(date);
      const timeParts = time.split(":");
      // hour and minute
      newDate.setHours(parseInt(timeParts[0]));
      newDate.setMinutes(parseInt(timeParts[1]));
      // Get services data from api
      newDate = format(newDate, "yyyy-MM-dd'T'HH:mm:ss");
      let body: any = { date: formatSearchDateTime(date) };
      if (selectedCategory && selectedCategory !== 0) {
        body.categoryId = selectedCategory;
      }
      const resp = await getServiceData(body, currentUser?.access_token);
      if (resp && resp?.result?.length > 0) {
        resp.result.date = formatSearchDateTime(date);
        resp?.result?.forEach((store: any) => {
          store?.services?.forEach((service: any) => {
            service.currentprice = formatAmount(service?.currentprice);
          });
        });
        setData(resp.result);
      }
      if (resp && resp?.upcomingServices?.length > 0) {
        setUpcomingServices(resp?.upcomingServices);
      }
      setOpens(false);
    } catch (error) {
      setOpens(false);
      console.error("Error fetching data:", error);
    }
  };

  const getServicesByCategory = async (index: any) => {
    setActIndex(index);
  };
  const getAllCategoriesData = async () => {
    try {
      const resp = await getAllCategories();
      const allCategory = {
        id: 0,
        name: "All",
        icon: null,
      };
      const categoriesList = resp.slice(0);
      categoriesList.unshift(allCategory);
      setCategories(categoriesList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const setDefaultTime = () => {
  //    const currentTime = new Date();
  //    const hours = currentTime.getHours();
  //    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  //    return `${hours}:${minutes}`;
  // };

  useEffect(() => {
    let date: any = sessionStorage.getItem("dateTime");
    const currentTime: any = date ? new Date(JSON.parse(date)) : new Date();
    //   const hours = currentTime.getHours();
    //   const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    fetchDataFunction(currentTime.toString(), currentTime);
    getAllCategoriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let date: any = sessionStorage.getItem("dateTime");
    const currentTime: any = date ? new Date(JSON.parse(date)) : new Date();
    fetchDataFunction(currentTime.toString(), currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  return (
    <>
      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <NavbarMain />
      <FilterNavbarMain fetchData={fetchDataFunction} />
      {/* <Categories/> */}
      {categories?.length > 0 ? (
        <section className="categories">
          <div
            className="container-main-self p-0"
            style={{
              position: "relative",
              width: "90%",
              margin: "auto",
              maxWidth: "1500px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "15px",
              }}
              className="category-container"
            >
              <Box
                component="button"
                onClick={() => scrollCategories(-50)}
                disabled={scrollPosition === 0}
                sx={{
                  // position: "absolute",
                  width: "40px",
                  height: "40px",
                  margin: "0 !important",
                  padding: "0 !important",
                  zIndex: 1000,
                  left: "-30px",
                  border: "none !important",
                  boxShadow: "none !important",
                  top: 0,
                  background:
                    "url('https://res.cloudinary.com/drt6vurtt/image/upload/v1699516445/Group_30850_u49fv1.svg') center center no-repeat !important",

                  "&::before": {
                    display: "none",
                    fontSize: "57px",
                  },
                }}
              ></Box>
              <div className="innercateriesrow" ref={categoryContainerRef}>
                {categories.map((cat: any, index: number) => (
                  <button
                    style={{
                      position: "relative",
                      left: `-${scrollPosition}px`,
                      marginLeft: index === 0 ? "0px" : "13px",
                      whiteSpace: "nowrap",
                    }}
                    key={index}
                    onClick={() => {
                      setSelectedCategories(cat.id);
                      getServicesByCategory(index);
                    }}
                    className={
                      index === activeIndex ? `slot-selected` : "primaryChip"
                    }
                  >
                    {cat?.name}
                  </button>
                ))}
              </div>
              <Box
                component="button"
                sx={{
                  // position: "absolute",
                  width: "40px",
                  height: "40px",
                  margin: "0 !important",
                  padding: "0 !important",
                  zIndex: 1000,
                  right: "-42px",
                  border: "none !important",
                  boxShadow: "none !important",
                  top: 0,
                  background:
                    "url('https://res.cloudinary.com/drt6vurtt/image/upload/v1699516445/Group_30600_czlu3e.svg') center center no-repeat !important",

                  "&::before": {
                    display: "none",
                    fontSize: "57px",
                  },
                }}
                onClick={() => scrollCategories(50)}
                disabled={scrollPosition === maxScroll}
              ></Box>
            </Box>
          </div>
        </section>
      ) : null}

      {upcomingServices?.length > 0 ? (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          className="container-main-self"
          my="40px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              component="p"
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                paddingRight: "3.1px",
              }}
            >
              Upcoming Appointments
            </Box>
            <Link to="/upcoming-appointments">
              <Box
                component="span"
                sx={{
                  color: "#4B2763",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textDecorationLine: "underline",

                  "&:hover": {
                    color: "#4B2763",
                  },
                }}
              >
                View All
              </Box>
            </Link>
          </Box>
          <Box component="div" className="upcoming-appointments-container">
            <Box component="div" className="sliderhere">
              <Slider {...settings}>
                {upcomingServices.map((serviceItem: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      component="div"
                      onClick={() =>
                        history.push(
                          `/bookingdetail/${serviceItem?.booking_id}`
                        )
                      }
                      p="24px"
                      sx={{
                        cursor: "pointer",
                        border: "1px solid rgba(75, 39, 99, 0.2)",
                        borderRadius: "17px",
                        backgroundColor:
                          serviceItem?.service_status === "cancelled"
                            ? "#FFEDED!important"
                            : serviceItem?.service_status === "accepted"
                            ? "#E9FFFE!important"
                            : serviceItem?.service_status === "pending"
                            ? "#FFFDEC!important"
                            : "#FFF",
                      }}
                    >
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="column"
                        gap="12px"
                      >
                        <Box
                          component="div"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pb="20px"
                          borderBottom="1px dashed rgba(75, 39, 99, 0.2)"
                        >
                          <Box display="flex" alignItems="center" gap={3}>
                            <Box display="flex" alignItems="center" gap={1}>
                              <img
                                src="\assets\majesticons_calendar.svg"
                                alt="cnctwltimg"
                                className="cnctwltimg"
                              />
                              <h6>
                                {serviceItem?.timezone_id &&
                                serviceItem?.timezone_id !==
                                  moment.tz.guess(true)
                                  ? moment(serviceItem?.service_start_time)
                                      .tz(serviceItem?.timezone_id)
                                      .format("dddd MMM DD, YYYY")
                                  : moment(
                                      serviceItem?.service_start_time
                                    ).format("dddd MMM DD, YYYY")}
                              </h6>
                            </Box>
                            <Box display="flex" alignItems="center" gap={1}>
                              <img
                                src="\assets\ri_time-line.svg"
                                alt="cnctwltimg"
                                className="cnctwltimsdchjudsbcg"
                              />
                              <h6>
                                {moment(serviceItem?.service_start_time)
                                  .tz(serviceItem?.timezone_id)
                                  .format("hh:mm A")}{" "}
                                -{" "}
                                {moment(serviceItem?.service_end_time)
                                  .tz(serviceItem?.timezone_id)
                                  .format("hh:mm A")}{" "}
                                {serviceItem?.timezone_id &&
                                serviceItem?.timezone_id !==
                                  moment.tz.guess(true)
                                  ? `(${moment
                                      .tz(serviceItem?.timezone_id)
                                      .format("z")})`
                                  : ""}
                              </h6>
                            </Box>
                          </Box>

                          <Box
                            component="p"
                            textTransform="uppercase"
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "normal",
                              color:
                                serviceItem?.service_status === "cancelled"
                                  ? "#C82A2F"
                                  : serviceItem?.service_status === "accepted"
                                  ? "#28a745"
                                  : serviceItem?.service_status === "pending"
                                  ? "#D08846"
                                  : "#FFF",
                            }}
                          >
                            {capitalizeFirstLetterInSentence(
                              serviceItem?.service_status || ""
                            )}
                          </Box>
                        </Box>

                        <Box
                          component="div"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box
                            component="div"
                            display="flex"
                            flexDirection="column"
                            gap="12px"
                          >
                            <Box
                              component="p"
                              sx={{
                                color: "rgb(0, 0, 0)",
                                fontFamily: "Poppins",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                              }}
                            >
                              {capitalizeFirstLetterInSentence(
                                serviceItem?.servicename || ""
                              )}
                            </Box>
                            <Box
                              component="p"
                              sx={{
                                color: "rgb(75, 39, 99)",
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                              }}
                            >
                              With{" "}
                              <Box component="span">
                                {capitalizeFirstLetterInSentence(
                                  serviceItem?.specialist_name || ""
                                )}
                              </Box>
                            </Box>
                            <Box display="flex" alignItems="center" gap="10px">
                              <Box
                                sx={{
                                  background: "#89CA8C",
                                  color: "#000",
                                  borderRadius: "17px",
                                  padding: "5px 20px",
                                }}
                              >
                                <Box component="p" fontWeight={500}>
                                  Payment Status:{" "}
                                  {capitalizeFirstLetterInSentence(
                                    serviceItem?.paymentStatus || ""
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          <Box component="div" width={150} height={150}>
                            <img
                              style={{
                                objectFit: "cover",
                                width: "inherit",
                                height: "inherit",
                                borderRadius: "17px",
                              }}
                              src={serviceItem?.specialist_display_picture}
                              alt="cnctwltimg"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}

      <section className="mainsectioncard">
        {opens || services?.length > 0 ? (
          <>
            <Storecard services={services} />
            {services?.length === 1 &&
            services?.[0]?.endpoint_url === "smoke_show_studio" ? (
              <AboutStore />
            ) : (
              ""
            )}
          </>
        ) : (
          <Box
            sx={{
              py: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "26px",
                color: "#000",
                fontFamily: "Poppins",
                fontWeight: 500,
              }}
            >
              No Data Found!!
            </Box>
          </Box>
        )}
      </section>
      <FooterMain />
    </>
  );
};

export default Landing;
