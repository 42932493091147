import { useState, useEffect, useRef } from "react";
// import React, {useEffect, useState} from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./BookBetter/MainPage/Landing";
import Login from "./BookBetter/Account/Login";
import SignUp from "./BookBetter/Account/Signup";
import Verification from "./BookBetter/Account/VerificationCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "react-time-picker/dist/TimePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import StoreAll from "./BookBetter/AllStores/AllStore";
import SpecialDetail from "./BookBetter/SpecialistDetail/SpecialListDetail";
import Basket from "./BookBetter/Basket/Basket";
import StoreDetail from "./BookBetter/StoreDetail/StoreDetail";
import BookList from "./BookBetter/BookListCalender/BookList";
import CalenderView from "./BookBetter/BookListCalender/Calender/CalenderView";
import BookDetail from "./BookBetter/BookListCalender/Bookingdetail";
import MerchantPassword from "./BookBetter/Account/MerchantPassword";
// import IOS from "./BookBetter/Account/Ios";
// import Dummy from "./BookBetter/Dummypage/DummyPage";
import Terms from "./BookBetter/TermsandConditions/Terms";
import Privacy from "./BookBetter/Privacypolicy/Privacy";
import DummyMobile from "./BookBetter/Dummypage/MobilePage";
import DummyMerchantMobile from "./BookBetter/Dummypage/MobileMerchantPage";
import GiftCardList from "./BookBetter/Giftcard/GiftCardList";
import RescheduleBooking from "./BookBetter/Reschedule/Reschedule";
// import { useRecoilState } from "recoil";
// import { cartState } from "./recoil/atoms/cartAtom";

// import Joyride, { CallBackProps } from "react-joyride";
import { Box } from "@mui/material";
import UpcomingAppointments from "./BookBetter/UpcomingAppointments/UpcomingAppointments";
import GroupSpecialistDetail from "./BookBetter/SpecialistDetail/GroupSpecialistDetail";
// import { useRecoilState } from "recoil";
// import {
//   tourGuideState,
//   tourGuideStatusState,
//   tourGuideStepState,
// } from "./recoil/atoms/tourAtom";

// const steps = [
//   {
//     target: ".outermain-filter",
//     content:
//       "Click on the date field to select a date and time. The dashboard will display services for your selected date and time.",
//     disableBeacon: true,
//     title: "Step 1",
//     spotlightClicks: true,
//   },
//   {
//     target: ".category-container",
//     content:
//       "Click here to select a category. The services on the dashboard will be filtered by the selected category. ",
//     disableBeacon: true,
//     title: "Step 2",
//   },
//   {
//     target: ".slot-1",
//     content:
//       "Click on a time slot to view the specialists available to provide this service on the selected time slot.",
//     disableBeacon: true,
//     title: "Step 3",
//     locale: {
//       next: "Close",
//     },
//   },
//   {
//     target: ".caresousaldownbaloon .specialist-0 .reserveBtn",
//     content:
//       "Click on Reserve under any specialist to add this service to the basket at the selected date and time.",
//     disableBeacon: true,
//     title: "Step 4",
//   },
//   {
//     target: ".caresousaldownbaloon .specialist-0 .calenderimg",
//     content:
//       "Click on calendar icon to view specialist availability for this service on different dates.",
//     disableBeacon: true,
//     title: "Step 5",
//     locale: {
//       next: "Close",
//     },
//   },
//   {
//     target: ".bookingdate",
//     content: "Click here to select a date to view available time slots",
//     disableBeacon: true,
//     title: "Step 6",
//   },
//   {
//     target: ".allslots",
//     content: "Click on a time slot to add to basket",
//     disableBeacon: true,
//     title: "Step 7",
//   },
//   {
//     target: ".buttonaddtobacket",
//     content: "Click here to add the selected timeslot to Basket",
//     disableBeacon: true,
//     title: "Step 8",
//   },
// ];

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const correctPassword = "test123";
  const [hidePasswordProtection, setHidePasswordProtection] = useState(
    sessionStorage.getItem("hidePassProtection")
      ? sessionStorage.getItem("hidePassProtection") === "true"
      : false
  );
  const currentPath = window.location.pathname;

  // const [showTourGuide, setShowTourGuide] = useRecoilState(tourGuideState);
  // const [tourStep, setTourStep] = useRecoilState(tourGuideStepState);
  // const [tourGuideStatus, setTourGuideStatus] =
  //   useRecoilState(tourGuideStatusState);
  // const [overlayHeight, setOverlayHeight] = useState(0);
  const contentRef = useRef(null);

  // const [cartItemsList] = useRecoilState(cartState);

  // console.log("CART ITEMS LIST", cartItemsList);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // useEffect(() => {
  //   const calculateOverlayHeight = () => {
  //     const docHeight = Math.max(
  //       document.body.scrollHeight,
  //       document.documentElement.scrollHeight,
  //       document.body.offsetHeight,
  //       document.documentElement.offsetHeight,
  //       document.body.clientHeight,
  //       document.documentElement.clientHeight
  //     );
  //     setOverlayHeight(docHeight);
  //   };

  //   calculateOverlayHeight(); // Initial calculation

  //   const resizeHandler = () => {
  //     calculateOverlayHeight(); // Recalculate height on resize
  //   };

  //   const observer = new MutationObserver(() => {
  //     calculateOverlayHeight(); // Recalculate height on DOM mutation
  //   });

  //   if (contentRef.current) {
  //     observer.observe(contentRef.current, {
  //       childList: true,
  //       subtree: true,
  //       attributes: true,
  //     });
  //   }

  //   window.addEventListener("resize", resizeHandler);

  //   if (!showTourGuide && tourGuideStatus !== "skipped") {
  //     setShowTourGuide(true);
  //     setTourGuideStatus("");
  //   }

  //   return () => {
  //     observer.disconnect();
  //     window.removeEventListener("resize", resizeHandler);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  // const handleCallback = (data: CallBackProps) => {
  //   const { action, index, type, lifecycle, status } = data;
  //   // console.log("DATA: ", data);

  //   if (type === "tour:end") {
  //     if (status === "skipped" || status === "finished") {
  //       setTourGuideStatus("skipped");
  //     }
  //     setShowTourGuide(false);
  //   } else {
  //     if (
  //       action === "next" &&
  //       index === 2 &&
  //       lifecycle === "complete" &&
  //       type === "step:after"
  //     ) {
  //       setShowTourGuide(false);
  //       return;
  //     } else if (
  //       action === "next" &&
  //       index === 4 &&
  //       lifecycle === "complete" &&
  //       type === "step:after"
  //     ) {
  //       setTourGuideStatus("paused");
  //       setShowTourGuide(false);
  //       return;
  //     }

  //     if (index > 0 && action === "prev") return;
  //     if (action === "next" && type === "step:after") {
  //       setTourStep(tourStep + 1);
  //     } else if (action === "prev" && type === "step:after") {
  //       setTourStep(tourStep - 1);
  //     }
  //   }
  // };

  useEffect(() => {
    if (
      !hidePasswordProtection &&
      Boolean(process.env.REACT_APP_PASSWORD_PROTECTION)
    ) {
      promptPassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidePasswordProtection]);

  const promptPassword = () => {
    const userPassword = window.prompt("Please enter the password:");
    if (userPassword === correctPassword) {
      sessionStorage.setItem("hidePassProtection", JSON.stringify(true));
      setHidePasswordProtection(true);
    } else {
      alert("Incorrect password, please try again.");
      promptPassword();
    }
  };

  if (!hidePasswordProtection) {
    return null; // Render nothing until the user is authenticated
  }

  return (
    <>
      <ToastContainer
        autoClose={5000}
        style={{ fontSize: 12, fontWeight: 300 }}
        theme="dark"
        position="top-center"
      />
      <Router>
        <Box ref={contentRef} className="mainouterhidden">
          {/* {showTourGuide &&
          (window.location.pathname === "/" ||
            window.location.pathname.startsWith("/speciallist/detail")) ? (
            <Joyride
              styles={{
                overlay: {
                  height: overlayHeight,
                },
                buttonNext: {
                  background: "#4b2763",
                  padding: "10px 20px",
                },
                buttonBack: {
                  color: "rgb(51, 51, 51)",
                },
              }}
              locale={{
                close: "Finish",
                skip: "Skip the guide",
              }}
              floaterProps={{
                placement: "bottom",
              }}
              callback={(data) => {
                handleCallback(data);
              }}
              hideBackButton
              run={showTourGuide}
              disableOverlayClose
              disableCloseOnEsc
              steps={steps}
              continuous
              hideCloseButton
              scrollToFirstStep
              showSkipButton
              stepIndex={tourStep}
            />
          ) : null} */}
          {windowWidth <= 767 && currentPath !== "/merchant-app" && (
            <DummyMobile />
          )}

          {windowWidth <= 767 && currentPath === "/merchant-app" && (
            <DummyMerchantMobile />
          )}

          {windowWidth >= 768 && (
            <Switch>
              {/* <Route exact path="/" component={Dummy} /> */}
              <Route exact path="/" component={Landing} />
              <Route exact path="/terms&conditon" component={Terms} />
              <Route exact path="/privacypolicy" component={Privacy} />
              {/* <Route exact path="/mobiledevicedetect" component={IOS} /> */}
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/merchant/password"
                component={MerchantPassword}
              />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/verification" component={Verification} />
              <Route exact path="/stores" component={StoreAll} />
              <Route
                exact
                path="/speciallist/detail/:id"
                component={SpecialDetail}
              />
              <Route
                exact
                path="/groupsession/specialist/:id"
                component={GroupSpecialistDetail}
              />
              <Route exact path="/cart" component={Basket} />
              <Route path="/merchants/:name" component={StoreDetail} />
              <Route exact path="/booking" component={BookList} />
              <Route exact path="/calender" component={CalenderView} />
              <Route exact path="/bookingdetail/:id" component={BookDetail} />
              <Route exact path="/giftcardlist" component={GiftCardList} />
              <Route
                exact
                path="/upcoming-appointments"
                component={UpcomingAppointments}
              />
              <Route
                exact
                path="/reschedulebooking/:id"
                component={RescheduleBooking}
              />
            </Switch>
          )}
        </Box>
      </Router>
    </>
  );
}

export default App;
