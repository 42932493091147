import React from "react";
// import Footer from "../landing/footer/Footer";
// import Navbar from "../landing/header/Navbar";
import "./terms.scss";
import { Link } from "react-router-dom";
// import NavbarMain from "../../Components/NavbarMain";
// import FooterMain from "../../Components/Footer";

const Terms = () => {
  return (
    <>
      <div className="main-presspage">
        {/* <NavbarMain /> */}
        <section className="main-cloud">
          <div className="container-main-self">
            <div className="row">
              <div className="col-xl-12 col-12 m-auto p-0">
                <div className="row">
                  <div className="col-xl-12 col-12 m-auto p-0">
                    <div className="banner_nav">
                      <div className="row">
                        <div className="col-xl-12 col-12 m-auto p-0">
                          <Link to="/" className="sdsddsx">
                            <button type="button" className="btnnav">
                              <p className="btn-tag">Back</p>
                            </button>
                          </Link>
                          <h1 className="mainheading">
                            Book Better Terms & Conditions
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-12 m-auto p-0">
                        <div className="secpara">
                          <h6 className="paraheading">
                            Terms of Use for Book Better
                          </h6>
                          <p className="secparaa">
                            Welcome to Book Better! These Terms of Use
                            (&#8220;Terms&#8221;) govern your access to and use
                            of the Book Better website and related services. By
                            accessing or using the Book Better website, you
                            agree to be bound by these Terms. If you do not
                            agree with any provision of these Terms, please
                            refrain from using the website.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">1. Use of the Website</h6>
                          <h6 className="thrrddppaarra">1.1 Eligibility: </h6>
                          <p className="secparaa">
                            You must be at least 18 years old to use the Book
                            Better website. By accessing or using the website,
                            you represent and warrant that you meet this
                            eligibility requirement.
                          </p>
                          <h6 className="thrrddppaarra">
                            1.2 Account Registration:{" "}
                          </h6>
                          <p className="secparaa">
                            In order to access certain features of the website,
                            you may need to create an account. When registering,
                            you agree to provide accurate, complete, and
                            up-to-date information. You are solely responsible
                            for maintaining the confidentiality of your account
                            credentials and for all activities that occur under
                            your account. You must promptly notify Book Better
                            of any unauthorized use or security breach of your
                            account.
                          </p>
                          <h6 className="thrrddppaarra">
                            1.3 User Responsibilities:{" "}
                          </h6>
                          <p className="secparaa">
                            You agree to use the Book Better website in
                            compliance with applicable laws and these Terms. You
                            are responsible for all content you post, transmit,
                            or otherwise make available through the website. You
                            must not engage in any activity that may disrupt or
                            interfere with the proper functioning of the website
                            or compromise its security. You shall not use the
                            website for any illegal or unauthorized purposes.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">
                            2. Booking and Appointment Management
                          </h6>
                          <h6 className="thrrddppaarra">
                            2.1 Customer Bookings:{" "}
                          </h6>
                          <p className="secparaa">
                            As a customer, you may use the Book Better website
                            to book appointments with merchants offering salon
                            services. You understand and acknowledge that the
                            availability and quality of services are determined
                            by the merchants themselves. Book Better does not
                            guarantee the accuracy, reliability, or suitability
                            of the services provided by the merchants. It is
                            your responsibility to verify the details of the
                            services, such as availability, pricing, and any
                            special requirements or restrictions.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.2 Merchant Services:{" "}
                          </h6>
                          <p className="secparaa">
                            Merchants may create stores, services, and manage
                            their employee accounts through the Book Better
                            website. Merchants are solely responsible for
                            accurately representing their services and
                            availability. Book Better does not endorse or
                            warrant the services provided by the merchants. Any
                            transaction or engagement with a merchant is solely
                            between you and the merchant, and Book Better shall
                            not be liable for any issues or disputes that may
                            arise.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.3 Booking Management:{" "}
                          </h6>
                          <p className="secparaa">
                            Merchants can view, manage, and communicate with
                            customers regarding their bookings through the Book
                            Better platform. Merchants have the ability to
                            cancel, reschedule, or mark bookings as complete. It
                            is the responsibility of the merchant to accurately
                            update the booking status and communicate any
                            changes or updates to the customer in a timely
                            manner.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.4 Google Calendar Integration:{" "}
                          </h6>
                          <p className="secparaa">
                            Book Better provides a feature to integrate with
                            Google Calendar for better appointment management.
                            By enabling this integration, you authorize Book
                            Better to access and synchronize your appointments
                            between Book Better and your Google Calendar. You
                            understand and acknowledge that Book Better will
                            collect and store information from your Google
                            Calendar, such as event details and attendee lists,
                            solely for the purpose of synchronizing your
                            appointments. Book Better does not share this
                            information with any third parties.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">
                            3. Intellectual Property
                          </h6>
                          <h6 className="thrrddppaarra">3.1 Ownership: </h6>
                          <p className="secparaa">
                            The Book Better website, including all content,
                            features, and functionality, is owned by Book Better
                            or its licensors and is protected by intellectual
                            property laws. You may not copy, modify, distribute,
                            or reproduce any part of the website without prior
                            written permission from Book Better.
                          </p>
                          <h6 className="thrrddppaarra">3.2 User Content: </h6>
                          <p className="secparaa">
                            By posting or providing any content on the website,
                            including reviews, comments, or feedback, you grant
                            Book Better a non-exclusive, transferable,
                            worldwide, royalty-free license to use, display,
                            reproduce, distribute, and modify such content for
                            the purpose of providing and improving the Book
                            Better services. You represent and warrant that you
                            have the necessary rights and permissions to grant
                            this license to Book Better and that your content
                            does not infringe upon the intellectual property
                            rights or violate the rights of any third party.
                          </p>
                          <h6 className="thrrddppaarra">3.3 Feedback: </h6>
                          <p className="secparaa">
                            Book Better welcomes and encourages feedback,
                            suggestions, or ideas regarding the website and its
                            services. Any feedback or suggestions you provide to
                            Book Better may be used by Book Better without any
                            obligation to compensate you, and you waive any
                            claims or rights you may have regarding such
                            feedback or suggestions.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">4.Privacy</h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            Your privacy is important to us. Please review our{" "}
                            <Link to="/privacypolicy">Privacy Policy </Link>
                            to understand how we collect, use, and protect your
                            personal information.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">
                            5. Disclaimer of Warranties
                          </h6>
                          <h6 className="thrrddppaarra">
                            5.1 Website Availability:{" "}
                          </h6>
                          <p className="secparaa">
                            Book Better strives to provide a reliable and
                            uninterrupted website experience. However, we do not
                            guarantee that the website will always be available,
                            error-free, or free from viruses or other harmful
                            components. You acknowledge that your use of the
                            website is at your own risk.
                          </p>
                          <h6 className="thrrddppaarra">
                            5.2 Merchant Services:{" "}
                          </h6>
                          <p className="secparaa">
                            Book Better does not endorse or guarantee the
                            services provided by merchants. We do not warrant
                            the accuracy, reliability, or suitability of the
                            information provided by merchants, including their
                            availability, pricing, or quality of services. Any
                            transactions or interactions with merchants are
                            solely between you and the merchant, and Book Better
                            shall not be responsible or liable for any issues,
                            damages, or disputes that may arise.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">
                            6. Limitation of Liability
                          </h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            To the maximum extent permitted by applicable law,
                            Book Better and its affiliates, officers, directors,
                            employees, and agents shall not be liable for any
                            indirect, incidental, special, consequential, or
                            punitive damages arising out of or in connection
                            with your use of the Book Better website or the
                            services provided by merchants, regardless of the
                            cause of action or the theory of liability. In no
                            event shall Book Better&#8217;s total liability to
                            you for all claims, whether in contract, tort, or
                            otherwise, exceed the amount paid by you, if any,
                            for accessing or using the website.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">
                            7. Governing Law and Dispute Resolution
                          </h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            These Terms shall be governed by and construed in
                            accordance with the laws of [Jurisdiction]. Any
                            dispute arising out of or relating to these Terms or
                            your use of the Book Better website shall be
                            submitted to the exclusive jurisdiction of the
                            courts of [Jurisdiction]
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">
                            8. Modifications to the Terms
                          </h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            Book Better reserves the right to modify or update
                            these Terms at any time, with or without notice. It
                            is your responsibility to review these Terms
                            periodically for any changes. By continuing to use
                            the Book Better website after any modifications to
                            the Terms, you agree to be bound by the updated
                            Terms.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">9.Contact Us</h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            If you have any questions, concerns, or requests
                            regarding these Terms, please contact us at
                            amber.naqvi@nimbusconsulting.tech. We will respond
                            to your inquiries in a timely manner.
                            <br />
                            Thank you for reading and accepting our Terms of
                            Use. We hope you enjoy using Book Better!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <FooterMain /> */}
    </>
  );
};

export default Terms;
