import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import WarningIcon from "@mui/icons-material/Warning";

export const AddToCartModal = (props: any) => {
  return (
    <Modal
      className="detailmodal detailbooking bookingdetailcancelbutton"
      show={props?.showModal}
      onHide={props?.handleClose}
      centered
    >
      <Modal.Body>
        <Box display="flex" alignItems="center" flexDirection="column" gap={4}>
          <Box>
            <WarningIcon sx={{ width: 50, height: 50, color: "#dc3545" }} />
            <Box
              component="p"
              sx={{
                pt: 2,
                color: "#2E2B2B",
                fontFamily: "Lexend",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              You cannot add items from multiple merchants to your cart. If you
              proceed, your current cart will be emptied.
            </Box>
          </Box>
          <Box display="flex" gap={2} className="arrowimg">
            <button
              className="buttoncancel outlined"
              onClick={() => {
                props?.handleClose() && props?.handleClose();
              }}
            >
              Cancel
            </button>
            <button
              className="buttoncancel"
              onClick={() => {
                props?.handleResetAndNewItems() &&
                  props?.handleResetAndNewItems();
              }}
            >
              Yes, Continue
            </button>
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
