import React, { useState, useEffect } from "react";
import "./giftcard.scss";
import NavbarMain from "../../Components/NavbarMain";
// import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import { getGiftCards } from "../../api/apiListingService";
// import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import GiftCardtable from "./GiftCardTable";

const GiftCardList = () => {
  const [giftCardsData, setGiftCardsData] = useState<any>([]);
  const [userType, setUserType] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  // const [page, setPage] = useState<any>(0);
  // const [pageStatus, setStatus] = useState<number>(0);
  // console.log("page no", page)
  // const [pageCount, setPageCount] = useState(10);
  // console.log("booking detail", giftCardsData)

  const getGiftCardsData = async (status: any, pageList: any) => {
    setLoading(true);
    try {
      //setGiftCardsData([]);
      // setStatus(status);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        const resp = await getGiftCards(status, pageList, data?.access_token);
        if (resp?.resCards?.length > 0) {
          setGiftCardsData(resp.resCards);
        } else {
          // toast.warn("Gift cards not found!", {
          //   autoClose: 5000,
          // });
          setGiftCardsData([]);
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message, {
        autoClose: 5000,
      });
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGiftCardsData(0, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data: any = sessionStorage.getItem("user");
    data = JSON.parse(data);
    setUserType(data?.userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handlePageChange = (e: any) => {
  //   const selectedPage = e.selected;
  //   setPage(selectedPage);
  //   getGiftCardsData(pageStatus, e.selected + 1);
  // };

  return (
    <>
      <NavbarMain />
      {/* <FilterNavbarMain /> */}
      <section className="bookingmain bookingcheckbox">
        <div className="container-main-self">
          <div className="texthere">
            <h3>Gift Card</h3>
          </div>
          <div className="lllpppllpp">
            <ul className="nav nav-tabs " id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Booking-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Booking"
                  type="button"
                  role="tab"
                  aria-controls="Booking"
                  aria-selected="true"
                  onClick={() => {
                    // setPage(0);
                    getGiftCardsData(0, 1);
                  }}
                >
                  All Gifts Cards
                </button>
              </li>
              {userType === "merchant" && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Pending-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Pending"
                    type="button"
                    role="tab"
                    aria-controls="Pending"
                    aria-selected="false"
                    onClick={() => {
                      // setPage(0);
                      getGiftCardsData(3, 1);
                    }}
                  >
                    Unassigned
                  </button>
                </li>
              )}

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Accepted-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Accepted"
                  type="button"
                  role="tab"
                  aria-controls="Accepted"
                  aria-selected="false"
                  onClick={() => {
                    // setPage(0);
                    getGiftCardsData(1, 1);
                  }}
                >
                  Assigned
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Cancelled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Cancelled"
                  type="button"
                  role="tab"
                  aria-controls="Cancelled"
                  aria-selected="false"
                  onClick={() => {
                    // setPage(0);
                    getGiftCardsData(2, 1);
                  }}
                >
                  Assigned Used
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content tabsbookingliust" id="myTabContent">
            <div
              className="tab-pane fade show active p-r"
              id="Booking"
              role="tabpanel"
              aria-labelledby="Booking-tab"
            >
              <GiftCardtable giftCardsData={giftCardsData} loading={loading} />
            </div>
            {userType === "merchant" && (
              <div
                className="tab-pane fade p-r"
                id="Pending"
                role="tabpanel"
                aria-labelledby="Pending-tab"
              >
                <GiftCardtable
                  giftCardsData={giftCardsData}
                  loading={loading}
                />
              </div>
            )}
            <div
              className="tab-pane fade p-r"
              id="Accepted"
              role="tabpanel"
              aria-labelledby="Accepted-tab"
            >
              <GiftCardtable giftCardsData={giftCardsData} loading={loading} />
            </div>
            <div
              className="tab-pane fade p-r"
              id="Cancelled"
              role="tabpanel"
              aria-labelledby="Cancelled-tab"
            >
              <GiftCardtable giftCardsData={giftCardsData} loading={loading} />
            </div>
          </div>
          {/* {giftCardsData.totalPages !== 0 ? (
            <ReactPaginate
              previousLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M10 13L5 8L10 3"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              nextLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M5 13L10 8L5 3"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={page}
            ></ReactPaginate>
          ) : (
            <></>
          )} */}
        </div>
      </section>
      <FooterMain />
    </>
  );
};

export default GiftCardList;
