import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import moment from "moment-timezone";

const SlotReservationModal = (props: any) => {
  return (
    <Modal
      className="detailmodal detailbooking bookingdetailcancelbutton"
      show={props?.showModal}
      onHide={props?.handleClose}
      centered
    >
      <Modal.Body>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap={4}
          width="90%"
          m="auto"
        >
          <Box>
            <Box
              component="p"
              sx={{
                pt: 2,
                color: "#2E2B2B",
                fontFamily: "Lexend",
                fontSize: "20px",
                fontWeight: 500,
                letterSpacing: "1px",
              }}
            >
              Are you sure you want to book{" "}
              {props?.seats
                ? `${props?.seats} Seat${props?.seats > 1 ? "s" : ""} of`
                : ""}{" "}
              {props?.serviceName} with {props?.specialistName} on{" "}
              {props?.timezone && props?.timezone !== moment.tz.guess(true)
                ? moment(props?.startDate)
                    .tz(props?.timezone)
                    .format("MMM DD, YYYY")
                : moment(props?.bookingDate).format("MMM DD, YYYY")}{" "}
              at{" "}
              {moment(props?.startDate).tz(props?.timezone).format("hh:mm A")} -{" "}
              {moment(props?.endDate).tz(props?.timezone).format("hh:mm A")}{" "}
              {props?.timezone && props?.timezone !== moment.tz.guess(true)
                ? `(${moment.tz(props?.timezone).format("z")})`
                : ""}{" "}
              ?
            </Box>
          </Box>
          <Box className="arrowimg">
            <Box
              component="button"
              className="buttoncancel acceptbutton"
              onClick={() => {
                props?.handleAddToCart && props?.handleAddToCart();
              }}
            >
              Yes, Confirm
            </Box>
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default SlotReservationModal;
