import React from "react";

const SpeciallistStore = (props: any) => {
  return (
    <div className="Specialistss m-b-150">
      <h2>Specialist</h2>
      <div className="row p-0">
        {props.data?.specialists?.map((sp: any, index: number) => (
          <div key={index} className="col-lg-3 pppppp">
            <div className="outmainminnerspecilist">
              <div className="outmaininnerspecia">
                <div className="leftsidem">
                  <img src={sp.photo} alt="cnctwltimg" className="cnctwltimg" />
                  <div className="innerrightsidespec">
                    <h3>{sp.name}</h3>
                  </div>
                </div>
                {/* <div className='rightsidem'>
                                <div className='upperright'>
                                    <div className="adasdasa">
                                        <div className="ratings">
                                            <i className="fa fa-star rating-color"></i>
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <h4>4.7</h4>
                                    </div>
                                </div>
                            </div> */}
              </div>
              {/* <button className='buttonviewavailablity'>
                            <img src="\assets\storeimage\img16.svg" alt="cnctwltimg" className="cnctwltimg" />
                            <h6>View Availability</h6>
                        </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpeciallistStore;
