import { atom } from 'recoil';
import { persistAtom, persistAtomLocalStorage } from '../recoil-persist';

export const userState = atom({
  key: 'currentUser',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const savedCredState = atom({
  key: 'savedCredentials',
  default: null,
  effects_UNSTABLE: [persistAtomLocalStorage],
});