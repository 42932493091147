import React, { FC, useState, useEffect } from "react";
import DatePickers from "react-datepicker";
import "./Navbar.scss";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link } from "react-router-dom";
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
// import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FilterNavbarMainProps } from "./types";
// import { Modal } from 'react-bootstrap';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

const FilterNavbarMain: FC<FilterNavbarMainProps> = ({ fetchData }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [formatedTime, setTime] = useState<any>(new Date());
  const [cTime, setCTime] = useState<any>(new Date());
  // const [show2, setShow2] = useState(false);
  // const handleClose2 = () => setShow2(false);
  // const handleShow2 = () => {
  //     setShow2(true);
  // };
  //sessionStorage.setItem('dateTime', JSON.stringify(new Date()));
  let setDateTime = (dateTime: any, time: any) => {
    if (JSON.stringify(time).length < 10) {
      const timeParts = time.split(":");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);
      dateTime.setHours(hours);
      dateTime.setMinutes(minutes);
    }
    setTime(dateTime);
    sessionStorage.setItem("dateTime", JSON.stringify(dateTime));
  };
  useEffect(() => {
    let date: any = sessionStorage.getItem("dateTime");
    const currentTime: any = date ? new Date(JSON.parse(date)) : new Date();
    setStartDate(currentTime);
    setCTime(currentTime);
    setTime(currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="Navbar-new-filter">
      <div className="container-main-self">
        <Link
          className="sdasdasdwgerbe"
          style={{ display: "block", maxWidth: "fit-content" }}
          to="/"
        >
          <button className="buttonforbasket">Back</button>
        </Link>
        <div className="outermain-filter">
          <div className="left-side-main">
            <div className="inner-11 ooooo">
              <DatePickers
                selected={startDate}
                onChange={(date: any) => {
                  setStartDate(date);
                  setDateTime(date, formatedTime);
                }}
                dateFormat="MMM dd, yyyy"
              />
              <img
                src="\assets\majesticons_calendar.svg"
                alt="cnctwltimg"
                className="cnctwltimg"
              />
            </div>
            <div className="inner-22 ooooo">
              {/* <input type="text" id="search" name="search" placeholder='Select Time' /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    "TimePicker",
                    "MobileTimePicker",
                    "DesktopTimePicker",
                    "StaticTimePicker",
                  ]}
                >
                  {/* <DemoItem >
                                        <TimePicker defaultValue={dayjs(formatedTime)} onChange={(time: any) => setTime(time.$H+":"+time.$m)}/>
                                    </DemoItem> */}
                  <DemoItem>
                    <MobileTimePicker
                      orientation="landscape"
                      value={dayjs(cTime)}
                      onChange={(time: any) => {
                        setDateTime(startDate, time.$H + ":" + time.$m);
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              <img
                src="\assets\ri_time-line.svg"
                alt="cnctwltimg"
                className="cnctwltimsdchjudsbcg"
              />
            </div>
            {/* <div className='inner-33 ooooo'>
                            <input type="text" id="search" name="search" placeholder='Location' />
                            <img src="\assets\carbon_location-filled.svg" alt="cnctwltimg" className="cnctwltimg" />
                        </div> */}
          </div>
          {/* <div className='mid-search'>
                        <img src="\assets\Vector (9).svg" alt="cnctwltimg" className="cnctwltimg" />
                        <input type="text" id="search" name="search" placeholder='Smoke Show Studios' />
                    </div> */}
          <div className="maininderrightside">
            <div className="buttonfind">
              {fetchData && (
                <button onClick={() => fetchData(`${startDate}`, formatedTime)}>
                  Search
                </button>
              )}
            </div>
            {/* <div className='filterbutton'>
                            <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                <img src="\assets\cropeddd.svg" alt="cnctwltimg" className="cnctwltimg" />
                            </button>
                        </div> */}
          </div>

          {/* <div className='mapbutton'>
                        <button>
                            <img src="\assets\clarity_map-solid.svg" alt="cnctwltimg" className="cnctwltimg" />
                        </button>
                    </div> */}
        </div>
      </div>

      <div
        className="Filternavbar offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Filter</h5>
          <button className="restbittn" type="button">
            Reset
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="firstfilter">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                checked
                name="fff1"
                id="fff1"
              />
              <label className="form-check-label" htmlFor="fff1">
                Store
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fff2"
                id="fff2"
              />
              <label className="form-check-label" htmlFor="fff2">
                Specialist
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fff3"
                id="fff3"
              />
              <label className="form-check-label" htmlFor="fff3">
                Services
              </label>
            </div>
          </div>
          <div className="downallinputs">
            <div className="searchmain">
              <input type="text" id="fname" name="fname" placeholder="search" />
              <img
                src="\assets\allstore\searchicon.svg"
                alt="cnctwltimg"
                className="cnctwltimg"
              />
            </div>
            <div className="dateform">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "MobileDatePicker",
                    "DesktopDatePicker",
                    "StaticDatePicker",
                  ]}
                >
                  <DatePicker defaultValue={dayjs("2022-04-17")} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="timepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    "TimePicker",
                    "MobileTimePicker",
                    "DesktopTimePicker",
                    "StaticTimePicker",
                  ]}
                >
                  <TimePicker defaultValue={dayjs("2022-04-17T15:30")} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="locationsmain">
              <input
                type="text"
                id="fname"
                name="fname"
                placeholder="Location"
              />
              <img
                src="\assets\allstore\location.svg"
                alt="cnctwltimg"
                className="cnctwltimg"
              />
            </div>
          </div>
          <div className="firstfilter">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="fff8"
                id="fff8"
              />
              <label className="form-check-label" htmlFor="fff8">
                In Store
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                checked
                name="fff4"
                id="fff4"
              />
              <label className="form-check-label" htmlFor="fff4">
                On Location
              </label>
            </div>
          </div>
          <div className="firstfilter llllllllllll">
            <h4>Store</h4>
            <div className="asdasfasfasf">
              <div className="form-check">
                <input
                  className="form-check-input"
                  checked
                  type="radio"
                  name="fff5"
                  id="fff5"
                />
                <label className="form-check-label" htmlFor="fff5">
                  Male
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="fff6"
                  id="fff6"
                />
                <label className="form-check-label" htmlFor="fff6">
                  Female
                </label>
              </div>
            </div>
          </div>
          <button className="creditcardbuton">Apply Filter</button>
        </div>
      </div>
      {/* <Modal className='detailmodal modaltimmer' show={show2} onHide={handleClose2} centered>
                <Modal.Body>
                    <div className='modaldatetime'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                ]}
                            >
                                <DemoItem>
                                    <StaticTimePicker orientation="landscape" defaultValue={dayjs(formatedTime)} />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </Modal.Body>
            </Modal> */}
    </section>
  );
};

export default FilterNavbarMain;
