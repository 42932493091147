import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import LoginModal from "../BookBetter/Account/AllModals";
// import { cronofyRequest } from "../api/apiListingService";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { useRecoilState } from "recoil";
import { cartState } from "../recoil/atoms/cartAtom";
import { userState } from "../recoil/atoms/userAtom";

const NavbarMain = () => {
  const [cartItemsList] = useRecoilState(cartState);
  const [currentUser] = useRecoilState(userState);
  const [isUser, setIsUser] = useState<boolean>(false);
  const history = useHistory();
  const addtocart = () => {
    history.push("/cart");
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  // const handleShow3 = () => {
  //     setShow3(true);
  // };

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  // const handleShow4 = () => {
  //     setShow4(true);
  // };
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  };
  // const cronofyReq = async () => {
  //   try {
  //     if (sessionStorage.getItem("user")) {
  //       let data: any = sessionStorage.getItem("user");
  //       data = JSON.parse(data);
  //       const resp = await cronofyRequest(data?.access_token);
  //       window.open(resp?.url, "_blank");
  //     }
  //   } catch (error: any) {
  //     console.error("Error fetching datasssss:", error);
  //     console.error(error.response);
  //   }
  // };
  useEffect(() => {
    if (currentUser && currentUser?.access_token) {
      setIsUser(true);
    }
  }, [currentUser]);

  let userName = currentUser?.name;
  var emailWithoutDomain = userName
    ? userName?.split("@")[0]
    : currentUser?.email
    ? currentUser?.email?.split("@")[0]
    : "";
  var userType = currentUser?.userType;
  return (
    <>
      <section className="Navbar-new">
        <div className="container-main-self">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <div className="left-side-logo">
                  <img
                    src="\assets\LogoSymbol.svg"
                    alt="cnctwltimg"
                    className="cnctwltimg"
                  />
                  <h5>
                    Welcome{" "}
                    {emailWithoutDomain ? emailWithoutDomain : "To BookBetter"}{" "}
                  </h5>
                </div>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/* <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/stores">Store</a>
                                </li> */}
                </ul>
                <form className="d-flex">
                  <div className="rightside">
                    <div className="cartadd" onClick={addtocart}>
                      <img
                        src="\assets\Vector (8).svg"
                        alt="cnctwltimg"
                        className="cnctwltimg"
                      />
                      <div className="asdadasdas">
                        <h2>{cartItemsList.length}</h2>
                      </div>
                    </div>
                    <div className="profile">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle knmkmkmkmk"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="\assets\user-icon.png"
                            alt="cnctwltimg"
                            className=" cnctwltimgsfsdfdsfs us-ic"
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <div className="uppermenubar">
                            <div className="leftsidelogin">
                              <div className="profileimage">
                                <img
                                  src="\assets\user-icon.png"
                                  alt="cnctwltimg"
                                  className="fewfewfweasad"
                                />
                              </div>
                              <div className="textss">
                                {isUser ? (
                                  <h4>
                                    {" "}
                                    {emailWithoutDomain
                                      ? emailWithoutDomain
                                      : emailWithoutDomain}
                                  </h4>
                                ) : (
                                  <>
                                    <h4>Guest</h4>
                                    <h5>
                                      Please{" "}
                                      <span
                                        className="loginnavbar"
                                        onClick={handleShow1}
                                      >
                                        Login
                                      </span>
                                    </h5>
                                  </>
                                )}
                              </div>
                            </div>
                            {currentUser?.access_token &&
                              userType === "customer" && (
                                <div className="rightsidelogin">
                                  <Link to="/calender">
                                    <img
                                      src="\assets\prof\🦆 icon _calendar_ (1).svg"
                                      alt="cnctwltimg"
                                      className=" "
                                    />
                                  </Link>
                                </div>
                              )}
                          </div>
                          {isUser ? (
                            <>
                              {userType === "customer" && (
                                <li className="midrange ppppllppp">
                                  <Link className="dropdown-item" to="/booking">
                                    <img
                                      src="\assets\prof\mdi_calendar.svg"
                                      alt="cnctwltimg"
                                      className=" cnctwltimgsfsdfdsfs"
                                    />
                                    <h6>View Bookings</h6>
                                  </Link>
                                  <img
                                    src="\assets\prof\Vector (10).svg"
                                    alt="cnctwltimg"
                                    className="rightsidearrownew"
                                  />
                                </li>
                              )}
                              {userType === "customer" && (
                                <li className="midrange">
                                  <Link
                                    className="dropdown-item"
                                    to="/giftcardlist"
                                  >
                                    <CardGiftcardIcon
                                      sx={{ width: 16, height: 16, mr: "10px" }}
                                    />

                                    <h6>Gift Cards</h6>
                                  </Link>
                                </li>
                              )}
                              {/* <li
                                className={
                                  userType === "customer" ? "d-n" : "midrange"
                                }
                              >
                                <span className="dropdown-item c-p">
                                  <img
                                    src="\assets\prof\iconamoon_profile-fill.svg"
                                    alt="cnctwltimg"
                                    className=" cnctwltimgsfsdfdsfs"
                                  />
                                  <h6 onClick={cronofyReq}>Calendar Sync</h6>
                                </span>
                              </li> */}
                              <li className="midrange lllllllll">
                                <a className="dropdown-item" href="/">
                                  <img
                                    src="\assets\prof\solar_settings-bold.svg"
                                    alt="cnctwltimg"
                                    className=" cnctwltimgsfsdfdsfs"
                                  />
                                  <h6
                                    onClick={() => {
                                      logout();
                                    }}
                                  >
                                    Logout
                                  </h6>
                                </a>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </section>
      <LoginModal
        show1={show1}
        setShow1={setShow1}
        handleClose1={handleClose1}
        show3={show3}
        handleClose3={handleClose3}
        show4={show4}
        handleClose4={handleClose4}
      />
    </>
  );
};

export default NavbarMain;
