import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";

const BookingCreationModal = (props: any) => {
  return (
    <Modal
      className="detailmodal detailbooking bookingdetailcancelbutton"
      show={props?.showModal}
      centered
    >
      <Modal.Body>
        <Box display="flex" alignItems="center" flexDirection="column" gap={4}>
          <Box>
            <Box
              component="p"
              sx={{
                pt: 2,
                color: "#2E2B2B",
                fontFamily: "Lexend",
                fontSize: "20px",
                fontWeight: 400,
                letterSpacing: "1px",
              }}
            >
              Booking succesfully created with Booking ID{" "}
              <Box component="span" fontWeight="700">
                BK-{props?.bookingId}
              </Box>
              . <br />
              All appointments are subject to acceptance by your Merchant,
              please ensure to check the status before your service.
            </Box>
          </Box>
          <Box className="arrowimg">
            <Box
              component="button"
              className="buttoncancel acceptbutton"
              onClick={() => {
                props?.navigateToHome && props?.navigateToHome();
              }}
            >
              Back to Home
            </Box>
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default BookingCreationModal;
