import React, { useState, useEffect } from "react";
import "./reschedule.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import {
  getSpecialistsData,
  getSpecialistsDetails,
  rescheduleService,
} from "../../api/apiListingService";
import {
  addServiceTime,
  adjustEndTime,
  adjustStartTime,
  checkTimeOverlap,
  formatSearchDateTime,
  formatTime,
} from "../../utils/dateFomater";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { cartState } from "../../recoil/atoms/cartAtom";
import {
  capitalizeFirstLetterInSentence,
  formatAmount,
} from "../../utils/generalUtils";
import { AddToCartModal } from "../../Components/Dialog/AddToCart";
import moment from "moment-timezone";
import { Box, MenuItem, Skeleton, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Modal } from "react-bootstrap";
import { rescheduleBookingState } from "../../recoil/atoms/rescheduleBookingAtom";
import { userState } from "../../recoil/atoms/userAtom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RescheduleBooking = (props: any) => {
  const [bookingDetailData] = useRecoilState(rescheduleBookingState);
  const createdAtDate = bookingDetailData?.serviceDetails?.serviceDate;
  // const createdAt = bookingDetailData?.serviceDetails?.serviceDate
  //   ? moment(
  //       bookingDetailData?.serviceDetails?.serviceDate,
  //       "Do MMM YYYY"
  //     ).toISOString()
  //   : "";
  // if (createdAt) {
  //   createdAtDate = moment(createdAt).format("MMM DD, YYYY");
  // }
  const currentDate: any = createdAtDate ? new Date(createdAtDate) : new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [specialistDetails, setSpecialistsData] = useState<any>([]);
  const [timeSlots, setTimeSlots] = useState<any>([]);
  const [cartItem, setCartItem] = useState<any>(null);
  const [activeIndex, setActIndex] = useState<any>();
  const [cartItemsList, setCartItemsList] = useRecoilState(cartState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [ssEmpId, setSSEmpId] = useState<any>(
    window.location.pathname.split("/").pop() || null
  );
  const merchantId = bookingDetailData?.merchantDetails?.id || "";
  const [value, setValue] = React.useState(0);
  const [timeSlotsLoading, setTimeSlotsLoading] = useState<boolean>(false);
  const [showSpecialistModal, setShowSpecialistModal] =
    useState<boolean>(false);
  const [currentUser] = useRecoilState(userState);
  const [specialists, setSpecialistData] = useState<any>([]);
  const history = useHistory();
  const [opens, setOpens] = useState<boolean>(false);

  const closeSpecialistModal = () => setShowSpecialistModal(false);

  const speciallist = () => {
    setShowSpecialistModal(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchServiceSpecialists = async () => {
    try {
      const body = {
        date: formatSearchDateTime(startDate),
        start: formatSearchDateTime(startDate),
        end: formatSearchDateTime(startDate),
        serviceId: bookingDetailData?.serviceDetails?.serviceId,
        merchantId: merchantId,
      };
      // Get services data from api
      const resp = await getSpecialistsData(body);
      setSpecialistData(resp.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      speciallist();
    }
  };

  const fetchSpecialistDetails = async (date: any) => {
    setTimeSlotsLoading(true);
    try {
      setTimeSlots([]);
      setActIndex(-1);
      const resp = await getSpecialistsDetails(
        ssEmpId,
        format(date, "yyyy-MM-dd"),
        bookingDetailData?.serviceDetails?.line_id
      );
      setSpecialistsData(resp);
      const prePrepDuration = resp?.prePrepDuration;
      const postPrepDuration = resp?.postPrepDuration;

      let spCartServices = cartItemsList.filter(
        (arr: any, index: number) =>
          arr.employeeId === resp.employeeId && arr.cartReserveStatus !== "cart"
      );
      spCartServices.forEach((element: any) => {
        resp.timeSlotObject.forEach((ele: any) => {
          if (ele.availibility) {
            const newStartTime = adjustStartTime(ele.timeSlot, prePrepDuration);
            // console.log("NEW START TIME: ", newStartTime);

            const newEndTime: any = addServiceTime(
              ele.timeSlot,
              resp.serviceDuration,
              postPrepDuration
            );
            // console.log("NEW END TIME: ", newEndTime);

            // let endTime: any = addServiceTime(
            //   ele.timeSlot,
            //   resp.serviceDuration
            // );

            const newExistingStartTime = adjustStartTime(
              element?.start,
              element?.prePrepDuration
            );
            const newExistingEndTime = adjustEndTime(
              element?.end,
              element?.postPrepDuration
            );

            // let checkOverlap = checkTimeOverlap(
            //   element.start,
            //   element.end,
            //   ele.timeSlot,
            //   endTime
            // );

            let checkOverlap = checkTimeOverlap(
              newExistingStartTime,
              newExistingEndTime,
              newStartTime,
              newEndTime
            );

            if (!checkOverlap) {
              ele.availibility = checkOverlap;
            }
          }
        });
      });
      resp.timeSlotObject.forEach((element: any) => {
        element.selected = false;
      });
      setTimeSlots(resp.timeSlotObject);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTimeSlotsLoading(false);
    }
  };
  const setTimeSlot = (timeSlot: any, startTime: any, index: any) => {
    if (cartItem && cartItem?.location === specialistDetails?.address) {
      // console.log("EXISTING CART ITEM");
      cartItem.bookingDate = startDate;
      cartItem.start = startTime;
      cartItem.end = addServiceTime(startTime, cartItem.serviceDuration);
    } else {
      // console.log("NEW CART ITEM");
      const currentprice = formatAmount(specialistDetails?.servicePrice);
      let cartItem = {
        ...specialistDetails,
        bookingDate: startDate,
        start: startTime,
        end: addServiceTime(startTime, specialistDetails.serviceDuration),
        ssEmpId: ssEmpId,
        tip: 0,
        cartReserveStatus: "cart",
        servicename: specialistDetails.serviceName,
        nickName: specialistDetails.nick_name,
        currentprice: currentprice,
        employeeId: specialistDetails.employeeId,
        serviceDuration: specialistDetails.serviceDuration,
        location: specialistDetails.address,
        merchantId: merchantId ? parseInt(merchantId) : "",
        rescheduledService: true,
      };
      setCartItem(cartItem);
    }
    setActIndex(index);
  };

  const handleRescheduleService = async () => {
    setOpens(true);
    const body = {
      bookingId: parseInt(bookingDetailData?.bookingDetails?.bookingId),
      bookingDetailId: parseInt(bookingDetailData?.serviceDetails?.line_id),
      startDateTime: cartItem?.start,
      ssEmpId: parseInt(ssEmpId),
    };
    try {
      const resp = await rescheduleService(body, currentUser?.access_token);
      toast.success(resp?.message, {
        autoClose: 5000,
      });
      history.goBack();
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          autoClose: 5000,
        });
      } else {
        toast.error("Something went wrong. Please try again.!", {
          autoClose: 5000,
        });
      }
      console.error("Error fetching data:", error?.response);
    } finally {
      setOpens(false);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleResetAndNewItems = () => {
    cartItem.cartReserveStatus = "incart";
    toast.success("Service added to the basket", {
      autoClose: 5000,
    });
    setActIndex(-1);
    const mergedObject = Object.assign({}, cartItem);
    setCartItemsList([mergedObject]);
    handleClose();
  };

  useEffect(() => {
    fetchSpecialistDetails(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, cartItemsList, ssEmpId]);

  const zoneAbbr = specialistDetails?.timezone
    ? `(${moment.tz(specialistDetails?.timezone).format("z")})`
    : "";

  const serviceDuration = bookingDetailData?.serviceDetails?.serviceDuration
    ? bookingDetailData?.serviceDetails?.serviceDuration <= 30
      ? `${bookingDetailData?.serviceDetails?.serviceDuration} minutes`
      : `${moment
          .duration(
            bookingDetailData?.serviceDetails?.serviceDuration,
            "minutes"
          )
          .asHours()} ${
          moment
            .duration(
              bookingDetailData?.serviceDetails?.serviceDuration,
              "minutes"
            )
            .asHours() > 1
            ? "hours"
            : "hour"
        }`
    : "";

  return (
    <>
      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="spciallistdetailpagecss">
        <NavbarMain />
        <FilterNavbarMain />
        <section className="Specialdetail">
          <div className="container-main-self">
            <div className="texthere">
              <h3>Reschedule Booking</h3>
            </div>
            <div className="outermain">
              <div className="uppermainfirst sdasasdasddasd">
                <div className="leftsidefirst">
                  <div className="outmain-main">
                    <div className="detailbooking dsfdwfdsfsdfsdf">
                      <div className="ppppppp rrttrrtt">
                        <div className="left">
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere"
                              style={{ width: "auto" }}
                            >
                              <h3>Name:</h3>
                              <h3 className="feerffer">
                                {bookingDetailData?.customerDetails?.name
                                  ? capitalizeFirstLetterInSentence(
                                      bookingDetailData?.customerDetails?.name
                                    )
                                  : ""}
                              </h3>
                            </div>
                          </div>
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere"
                              style={{ width: "auto" }}
                            >
                              <h3>Email:</h3>
                              <h3 className="feerffer">
                                {
                                  bookingDetailData?.customerDetails
                                    ?.email_address
                                }
                              </h3>
                            </div>
                          </div>
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere"
                              style={{ width: "auto" }}
                            >
                              <h3>Booking Date:</h3>
                              <h3 className="feerffer">{createdAtDate}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rrttrrtt">
                        <div className="left">
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere"
                              style={{ width: "auto" }}
                            >
                              <h3>Timings:</h3>
                              <h3 className="feerffer">
                                {moment(
                                  bookingDetailData?.serviceDetails?.start
                                )
                                  .tz(
                                    bookingDetailData?.serviceDetails?.timezone
                                  )
                                  .format("hh:mm A")}{" "}
                                -{" "}
                                {moment(bookingDetailData?.serviceDetails?.end)
                                  .tz(
                                    bookingDetailData?.serviceDetails?.timezone
                                  )
                                  .format("hh:mm A")}{" "}
                                {bookingDetailData?.serviceDetails?.timezone &&
                                bookingDetailData?.serviceDetails?.timezone !==
                                  moment.tz.guess(true)
                                  ? `(${moment
                                      .tz(
                                        bookingDetailData?.serviceDetails
                                          ?.timezone
                                      )
                                      .format("z")})`
                                  : ""}
                              </h3>
                            </div>
                          </div>
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere "
                              style={{ width: "auto" }}
                            >
                              <h3>Specialist:</h3>
                              <h3 className="feerffer">
                                {bookingDetailData?.serviceDetails?.specialist
                                  ? capitalizeFirstLetterInSentence(
                                      bookingDetailData?.serviceDetails
                                        ?.specialist
                                    )
                                  : ""}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rrttrrtt">
                        <div className="left">
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere oopp"
                              style={{ width: "auto" }}
                            >
                              <h3>Payment Method:</h3>
                              <h3 className="feerffer cvbcvbvcvcvb">
                                {bookingDetailData?.paymentDetails
                                  ?.paymentMethod === "pay_after_service"
                                  ? "Pay after service"
                                  : capitalizeFirstLetterInSentence(
                                      bookingDetailData?.paymentDetails
                                        ?.paymentMethod
                                    )}
                              </h3>
                            </div>
                          </div>
                          <div className="innnerrrr reschedule">
                            <div
                              className="leftsidere oopp"
                              style={{ width: "auto" }}
                            >
                              <h3>Service Name:</h3>
                              <h3 className="feerffer">
                                {bookingDetailData?.serviceDetails?.serviceName
                                  ? capitalizeFirstLetterInSentence(
                                      bookingDetailData?.serviceDetails
                                        ?.serviceName
                                    )
                                  : ""}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uppermainfirst" style={{ gap: "30px" }}>
                <div className="leftsidefirst">
                  <div className="outmain-main">
                    <div className="leftsideinner">
                      <img
                        src={specialistDetails?.cover_photo}
                        alt="cnctwltimg"
                        className="storeimageleft"
                      />
                    </div>
                    <div className="rightsideinner">
                      <h2
                        style={{ lineHeight: "normal", whiteSpace: "nowrap" }}
                      >
                        {specialistDetails.nick_name}{" "}
                      </h2>
                      <h3 style={{ whiteSpace: "nowrap", padding: 0 }}>
                        {specialistDetails.merchantName}
                      </h3>
                      {/* <div className='upperright'>
                                                <div className="adasdasa">
                                                    <div className="ratings">
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                    </div>
                                                </div>
                                                <div className='text'>
                                                    <h4>4.7</h4>
                                                    <h5>(4.2k)</h5>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
                {currentUser?.userType === "specialist" && (
                  <div className="rightsidefirst" style={{ margin: 0 }}>
                    <div className="maplocations">
                      <button
                        onClick={() => {
                          fetchServiceSpecialists();
                        }}
                        className="buttonaddtobacket mt-0"
                      >
                        Change Specialist
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <Box pb={3.5} display="flex" flexDirection="column" gap={2}>
                <div className="textjust" style={{ marginBottom: 0 }}>
                  <div className="haiers"></div>
                  <h4>
                    {specialistDetails?.serviceName} at{" "}
                    {specialistDetails?.merchantName}
                  </h4>
                </div>
                {serviceDuration && (
                  <div className="textjust" style={{ marginBottom: 0 }}>
                    <div className="haiers"></div>
                    <h4>{serviceDuration}</h4>
                  </div>
                )}
              </Box>
              <div className="bookingdate">
                <label>Booking Date</label>
                <div className="inner-11 ooooo">
                  <DatePicker
                    selected={startDate}
                    minDate={moment().toDate()}
                    onChange={(date: any) => {
                      setStartDate(date);
                    }}
                    dateFormat="MMM dd, yyyy"
                  />
                  <img
                    src="\assets\allstore\Calender.png"
                    alt="cnctwltimg"
                    className="cnctwltimsdsdsddsg"
                  />
                </div>
              </div>
              <Box
                sx={{
                  border: "none!important",
                  marginBottom: "0px!important",
                }}
                flexDirection="column"
                gap={2}
                className="timeavaible"
              >
                <Box display="flex" alignItems="center">
                  <img
                    src="\assets\allstore\Clock.png"
                    alt="cnctwltimg"
                    className="sadasasdasda"
                  />
                  <div className="inneravable">
                    <h5>Available Time Slots</h5>
                    {/* <h6>{format(startDate, "MM/dd/yyy")}</h6> */}
                  </div>
                </Box>
                <Box
                  sx={{
                    touchAction: "none",
                  }}
                >
                  {specialistDetails?.stores?.length > 1 ? (
                    <TextField
                      variant="outlined"
                      id="outlined-select-location"
                      select
                      value={ssEmpId}
                      onChange={(e: any) => {
                        setCartItem(null);
                        setSSEmpId(e.target.value);
                      }}
                      sx={{
                        borderRadius: "5px",
                        border: "1px solid #4B2763",
                        background: "#EDF0FF",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        width: "470px",
                        height: "55px",
                        paddingLeft: "12px",
                        outline: "none",
                      }}
                    >
                      {specialistDetails?.stores?.map(
                        (option: { ssEmpId: number; address: string }) => (
                          <MenuItem key={option.ssEmpId} value={option.ssEmpId}>
                            {option.address}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  ) : (
                    <h5>{specialistDetails?.address}</h5>
                  )}
                </Box>
              </Box>

              {specialistDetails?.timezone !== moment.tz.guess(true) ? (
                <Box sx={{ width: "100%" }}>
                  <Box display="flex" gap={1} alignItems="baseline" mb={2.4}>
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: "#fd7e14",
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      Indicates the time slots for{" "}
                      {moment(startDate).add(1, "day").format("MMM DD, YYYY")}
                    </Box>
                  </Box>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Timezone tabs"
                      sx={{
                        "&.MuiTabs-root": {
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },

                          "& .MuiButtonBase-root": {
                            // borderRadius: "20px",
                            backgroundColor: "#DADADA",
                            color: "#707070",
                            width: 235,

                            "&.local": {
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                            },

                            "&.store": {
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                            },

                            "&.Mui-selected": {
                              backgroundColor: "#4B2763",
                              color: "#fff",
                              fontWeight: 700,
                            },
                          },
                        },
                      }}
                    >
                      <Tab
                        className="local"
                        label="Local Time"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="store"
                        label={`Store Time ${zoneAbbr}`}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Box className="allslots" gap={timeSlotsLoading ? 2 : 0}>
                      {timeSlotsLoading ? (
                        <>
                          {new Array(30).fill("").map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="rectangular"
                              width={96}
                              height={36}
                            />
                          ))}
                        </>
                      ) : timeSlots?.length > 0 ? (
                        timeSlots.map((sl: any, index: any) => (
                          <div
                            key={index}
                            style={{ position: "relative" }}
                            onClick={() => {
                              sl.selected = "slot-selected";
                              setTimeSlot(timeSlots, sl?.timeSlot, index);
                            }}
                            className={
                              sl.availibility
                                ? `slotdesignsimple`
                                : "slotdesigndisabled"
                            }
                          >
                            {moment(sl?.timeSlot).format("MMM DD YYYY") !==
                            moment(sl?.timeSlot)
                              .tz(specialistDetails?.timezone)
                              .format("MMM DD YYYY") ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: "#fd7e14",
                                    borderRadius: "50%",
                                  }}
                                />
                              </Box>
                            ) : null}

                            <h2
                              className={
                                index === activeIndex ? `slot-selected` : ""
                              }
                            >
                              {formatTime(sl.timeSlot)}
                            </h2>
                          </div>
                        ))
                      ) : (
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          No slots available for {specialistDetails?.nick_name}{" "}
                          at {moment(startDate).format("MMM DD, YYYY")} in{" "}
                          {specialistDetails?.address}
                        </Box>
                      )}
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="allslots">
                      {timeSlotsLoading ? (
                        <>
                          {new Array(30).fill("").map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="rectangular"
                              width={96}
                              height={36}
                            />
                          ))}
                        </>
                      ) : timeSlots?.length > 0 ? (
                        timeSlots.map((sl: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => {
                              sl.selected = "slot-selected";
                              setTimeSlot(timeSlots, sl.timeSlot, index);
                            }}
                            className={
                              sl.availibility
                                ? `slotdesignsimple`
                                : "slotdesigndisabled"
                            }
                          >
                            <h2
                              className={
                                index === activeIndex ? `slot-selected` : ""
                              }
                            >
                              {moment(sl.timeSlot)
                                .tz(specialistDetails?.timezone)
                                .format("h:mm A")}
                            </h2>
                          </div>
                        ))
                      ) : (
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          No slots available for {specialistDetails?.nick_name}{" "}
                          at {moment(startDate).format("MMM DD, YYYY")} in{" "}
                          {specialistDetails?.address}
                        </Box>
                      )}
                    </div>
                  </CustomTabPanel>
                </Box>
              ) : (
                <div className="allslots">
                  {timeSlotsLoading ? (
                    <>
                      {new Array(30).fill("").map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rectangular"
                          width={96}
                          height={36}
                        />
                      ))}
                    </>
                  ) : timeSlots?.length > 0 ? (
                    timeSlots.map((sl: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => {
                          sl.selected = "slot-selected";
                          setTimeSlot(timeSlots, sl.timeSlot, index);
                        }}
                        className={
                          sl.availibility
                            ? `slotdesignsimple`
                            : "slotdesigndisabled"
                        }
                      >
                        <h2
                          className={
                            index === activeIndex ? `slot-selected` : ""
                          }
                        >
                          {moment(sl.timeSlot)
                            .tz(specialistDetails?.timezone)
                            .format("h:mm A")}
                        </h2>
                      </div>
                    ))
                  ) : (
                    <Box
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      No slots available for {specialistDetails?.nick_name} at{" "}
                      {moment(startDate).format("MMM DD, YYYY")} in{" "}
                      {specialistDetails?.address}
                    </Box>
                  )}
                </div>
              )}

              <button
                onClick={() => {
                  handleRescheduleService();
                }}
                style={{
                  marginTop: "30px",
                }}
                className={
                  activeIndex >= 0
                    ? "buttonaddtobacket"
                    : "disabled-btn buttonaddtobacket"
                }
              >
                Reschedule
              </button>
            </div>
          </div>
        </section>
        <FooterMain />
      </div>
      <AddToCartModal
        showModal={showModal}
        handleClose={handleClose}
        handleResetAndNewItems={handleResetAndNewItems}
      />

      <Modal
        className="detailmodalspecialist"
        show={showSpecialistModal}
        onHide={closeSpecialistModal}
        centered
      >
        <Modal.Body>
          <div className="arrowimg">
            <div className="speciallist">
              <h6>Select Specialist</h6>
            </div>
            <div
              className="outerdivspeciallist"
              style={{
                justifyContent:
                  specialists?.length > 0 ? "flex-start" : "center",
              }}
            >
              {specialists?.length > 0 ? (
                specialists
                  ?.filter(
                    (sp: any) => parseInt(ssEmpId) !== parseInt(sp?.ssEmpId)
                  )
                  .map((sp: any, index: number) => (
                    <div key={index} className="innerprofile">
                      <div className="upperprofile">
                        <img
                          src={
                            sp?.coverPhoto
                              ? sp?.coverPhoto
                              : "/assets/storeimage/placeholder-img.png"
                          }
                          alt="cnctwltimg"
                          className="profileimg"
                        />
                      </div>
                      <div className="lowerside">
                        <div className="maintool">
                          <h4>{sp.nickName}</h4>
                          <span className="tooltiptext">{sp.nickName}</span>
                        </div>
                        <div className="outmainh5">
                          <h5>Location</h5>
                          <div className="mainouterlocation">
                            <img
                              src="\assets\allstore\carbon_location.svg"
                              alt="cnctwltimg"
                              className="calenderimg"
                            />
                            <div className="text">
                              <p>{sp.location}</p>
                              <h6>{specialistDetails?.merchantName}</h6>
                            </div>
                          </div>
                        </div>
                        <button
                          // className={!sp.availibility ? "incart" : ""}
                          style={{ width: "auto" }}
                          onClick={() => {
                            setCartItem(null);
                            setSSEmpId(sp?.ssEmpId);
                            closeSpecialistModal();
                          }}
                        >
                          Swap
                        </button>
                      </div>
                    </div>
                  ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 5,
                  }}
                >
                  <Box
                    component="p"
                    sx={{
                      fontSize: "16px",
                      color: "#000",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    No specialists are available for the service "
                    {specialistDetails?.serviceName}" on "
                    {moment(startDate).format("MMM DD, YYYY")}"
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RescheduleBooking;
