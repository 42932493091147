import React from "react";
// import { Link } from 'react-router-dom';
import "./footer.scss";
import Dummy from "../BookBetter/Dummypage/DummyPage";

const FooterMain = () => {
  return (
    <>
      <Dummy />
      {/* <section className="Footermain ">
            <div className="container-main-self p-0">
                <div className="row">
                    <div className="col-lg-12 p-0">
                        <div className='row'>
                            <div className='col-lg-5 col-12 p-0'>
                                <div className='logossss'>
                                    <h2>About Book Better</h2>
                                    <h6>Lorem ipsum dolor sit amet, consectetur <br></br> adipiscing elit. Sed ornare cursus sed nunc eget <br></br> dictum  Sed ornare cursus sed nunc eget <br></br> dictumd nunc eget dictum  Sed ornare cursus<br></br> sed nunc eget dictum  </h6>
                                    <div className='logoss-here'>
                                        <Link to='#' className='llpllp'>
                                            <img src="\assets\newm\uil_facebook.svg" alt="cnctwltimg" className="cnctwltimg" />
                                        </Link>
                                        <Link to='#' className='llpllp'>
                                            <img src="\assets\newm\ri_instagram-fill.svg" alt="cnctwltimg" className="cnctwltimg" />
                                        </Link>
                                        <Link to='#' className='llpllp'>
                                            <img src="\assets\newm\mdi_twitter.svg" alt="cnctwltimg" className="cnctwltimg" />
                                        </Link>
                                        <Link to='#' className='llpllp'>
                                            <img src="\assets\newm\ri_youtube-fill.svg" alt="cnctwltimg" className="cnctwltimg" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-7 p-0'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-4 col-12 p-0'>
                                        <div className='mainheadingabout'>
                                            <h3>Popular</h3>

                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12'>
                                        <div className='mainheadingabout'>
                                            <h3>Contact Us</h3>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 p-0'>
                                        <div className='mainheadingabout'>
                                            <h3>Quick Links</h3>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                            <Link className="" to="#" target="_blank">
                                                <div className='inner-link'>
                                                    <img src="\assets\newm\Arrow - Right 2.svg" alt="cnctwltimg" className="cnctwltimg" />
                                                    <h3>Button</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-main-self downgrade p-0">
                <img src="\assets\logobookbetter.svg" alt="cnctwltimg" className="cnctwltimg" />
            </div>
        </section> */}
    </>
  );
};

export default FooterMain;
