import React, { useState, useEffect } from "react";
import "./storedetail.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import { Link } from "react-router-dom";
import Gallery from "./Gallery";
import StoreDetailSmoke from "./StoredetailSmoke";
import ServiceSlider from "./ServicesSlider";
import { getMerchantDetails } from "../../api/apiListingService";
import SpeciallistStore from "./Specialliststore";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { format } from "date-fns";
import { formatAmount } from "../../utils/generalUtils";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil/atoms/userAtom";
import { formatSearchDateTime } from "../../utils/dateFomater";

const StoreDetail = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [formatedTime, setTime] = useState<any>(new Date());
  const [cTime, setCTime] = useState<any>();
  const [merchantDetails, setMerchatDetails] = useState<any>(null);
  const [services, setServicesData] = useState<any>([]);
  const [currentUser] = useRecoilState(userState);
  const [opens, setOpens] = useState<any>(false);
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const merchantName =
    pathSegments.length >= 3 ? pathSegments.at(-2) : pathSegments.at(-1);

  console.log("PATH: ", merchantName);

  const getMerchantDetailsData = async (date: any, time: any) => {
    try {
      // if (JSON.stringify(time).length > 10) {
      //     time = setDefaultTime()
      //  }
      setOpens(true);
      time = new Date(time);
      const hours = time.getHours();
      const minutes = time.getMinutes().toString().padStart(2, "0");
      time = `${hours}:${minutes}`;
      let newDate: any = new Date(date);
      const timeParts = time.split(":");
      // hour and minute
      newDate.setHours(parseInt(timeParts[0]));
      newDate.setMinutes(parseInt(timeParts[1]));
      // Get services data from api
      newDate = format(newDate, "yyyy-MM-dd'T'HH:mm:ss");
      const resp = await getMerchantDetails(
        merchantName,
        formatSearchDateTime(date),
        currentUser?.access_token
      );
      let property = "seq_no";
      resp.merchantDetails.gallery = [...resp?.merchantDetails?.gallery].sort(
        (a, b) => {
          if (a[property] < b[property]) {
            return -1;
          }
          if (a[property] > b[property]) {
            return 1;
          }
          return 0;
        }
      );
      resp.storesServicesRes.forEach((element: any) => {
        element.price = formatAmount(element?.price);
        element.servicename = element?.name;
      });
      setMerchatDetails(resp);
      resp.date = formatSearchDateTime(date);
      setCTime(date);
      setServicesData(resp);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpens(false);
    }
  };
  // const setDefaultTime = () => {
  //     const currentTime = new Date();
  //     const hours = currentTime.getHours();
  //     const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  //     return `${hours}:${minutes}`;
  //  };
  let setDateTime = (dateTime: any, time: any) => {
    if (JSON.stringify(time).length < 10) {
      const timeParts = time.split(":");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);
      dateTime.setHours(hours);
      dateTime.setMinutes(minutes);
    }
    setTime(dateTime);
    sessionStorage.setItem("dateTime", JSON.stringify(dateTime));
  };
  useEffect(() => {
    let date: any = sessionStorage.getItem("dateTime");
    const currentTime: any = date ? new Date(JSON.parse(date)) : new Date();
    setStartDate(currentTime);
    setCTime(currentTime);
    setTime(currentTime);
    // const hours = currentTime.getHours();
    // const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    getMerchantDetailsData(currentTime.toString(), currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const galleryImages =
    merchantDetails?.merchantDetails?.gallery?.map(
      (obj: any) => obj.mediaurl
    ) || "";

  const renderHelmet = merchantDetails && (
    <Helmet>
      <title>{merchantDetails?.merchantDetails?.merchant_name}</title>

      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "${merchantDetails?.merchantDetails?.merchant_name}",
        "address": ${JSON.stringify(
          merchantDetails?.merchantDetails?.locations || ""
        )},
        "longitude": ${JSON.stringify(
          merchantDetails?.merchantDetails?.longitudes || ""
        )},
        "latitude": ${JSON.stringify(
          merchantDetails?.merchantDetails?.langitudes || ""
        )},
        "founder": {
          "@type": "Person",
          "name": "${merchantDetails?.merchantDetails?.OwnerName}"
        },
        "logo": "${merchantDetails?.merchantDetails?.logo || ""}",
        "url": "https://bookbetter.io/merchant/${encodeURIComponent(
          merchantName as string
        )}",
        "description": "Schedule an appointment with our service.",
        "image": ${JSON.stringify(galleryImages || "")}
      }
    `}
      </script>
    </Helmet>
  );

  console.log("GOOGLE BOOKING FLAG: ", process.env.REACT_APP_GOOGLE_BOOKING);

  return (
    <>
      {process.env.REACT_APP_GOOGLE_BOOKING === "true" ? renderHelmet : null}

      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="storedetailpagefor">
        <NavbarMain />
        <FilterNavbarMain />
        <section className="StoreDetail">
          <div className="container-main-self">
            <Gallery merchantDetails={merchantDetails?.merchantDetails} />
            <StoreDetailSmoke
              opens={opens}
              merchantDetails={merchantDetails?.merchantDetails}
            />
            <div className="StoreDetail">
              <div className="smokeshowstudio filter-nav">
                <div className="downrow">
                  <div className="mainoutermasin">
                    <div className="left-side-main">
                      <div className="inner-11 ooooo">
                        <DatePicker
                          selected={startDate}
                          onChange={(date: Date | null) => {
                            setStartDate(date);
                            setDateTime(date, formatedTime);
                          }}
                          dateFormat="MMM dd, yyyy"
                        />
                        <img
                          src="\assets\majesticons_calendar.svg"
                          alt="cnctwltimg"
                          className="cnctwltimg"
                        />
                      </div>
                      <div className="inner-22 ooooo">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              "TimePicker",
                              "MobileTimePicker",
                              "DesktopTimePicker",
                              "StaticTimePicker",
                            ]}
                          >
                            <DemoItem>
                              <MobileTimePicker
                                orientation="landscape"
                                // defaultValue={dayjs(formatedTime)}
                                value={dayjs(cTime)}
                                onChange={(time: any) => {
                                  setDateTime(
                                    startDate,
                                    time.$H + ":" + time.$m
                                  );
                                }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <img
                          src="\assets\ri_time-line.svg"
                          alt="cnctwltimg"
                          className="cnctwltimsdchjudsbcg"
                        />
                      </div>
                    </div>
                    <div className="buttonfind">
                      <button
                        onClick={() => {
                          getMerchantDetailsData(startDate, formatedTime);
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ServiceSlider data={services} />
            <SpeciallistStore data={services} />
            <div className="aboutsmoke d-n">
              <div className="main-upper">
                <h3>About {merchantDetails?.merchantDetails?.merchant_name}</h3>
              </div>
              <div className="outermainabout">
                <div className="left-side-about">
                  <img
                    src="\assets\storeimage\img17.svg"
                    alt="cnctwltimg"
                    className="storeimageleft"
                  />
                  <div className="text">
                    <h5>{merchantDetails?.merchantDetails?.OwnerName}</h5>
                    <h6>Sarah.Crawford@smokeshowstudio.com</h6>
                  </div>
                </div>
                <div className="right-side-about">
                  <Link
                    to={{
                      pathname: `https://${merchantDetails?.merchantDetails?.merchant_website}`,
                    }}
                    target="_blank"
                  >
                    {merchantDetails?.merchantDetails?.merchant_website}
                  </Link>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur. Sit elementum eget
                dictum urna ut venenatis. Hac aliquam adipiscing ut nascetur.
                Commodo tellus vitae habitant mauris porta odio quis vitae at.
                Egestas nulla enim velit odio. Dictumst et et mattis eu quisque
                nunc id. Massa sit aenean ornare mattis volutpat volutpat
                aliquet vitae. Facilisi laoreet massa habitant sit vel eget
                morbi dictum. Ullamcorper orci scelerisque augue risus nibh
                arcu. Feugiat rutrum vitae feugiat parturient etiam scelerisque
                mi ut. Lorem sagittis
              </p>
              <div className="outermainnewone">
                <div className="clockss">
                  <img
                    src="\assets\prof\new11\uil_calender.svg"
                    alt="cnctwltimg"
                    className="left"
                  />
                  <h6>Joined in March 2016</h6>
                </div>
                <div className="clockss">
                  <img
                    src="\assets\prof\new11\tabler_message-2.svg"
                    alt="cnctwltimg"
                    className="left"
                  />
                  <h6>Response rate - 100</h6>
                </div>
                <div className="clockss">
                  <img
                    src="\assets\prof\new11\mdi_clock-outline.svg"
                    alt="cnctwltimg"
                    className="left"
                  />
                  <h6>Fast response - within a few hours</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterMain />
      </div>
    </>
  );
};

export default StoreDetail;
