import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from "react";

type Item = {
  // Adjust the type according to your data structure
  nickName: string;
};

type MainContextType = {
  itemArray: Item[];
  setitemArray: Dispatch<SetStateAction<Item[]>>;
};

const Context = createContext<MainContextType | undefined>(undefined);

const MainContext = ({ children }: { children: ReactNode }) => {
  const [itemArray, setitemArray] = useState<Item[]>([]);

  return (
    <Context.Provider value={{ itemArray, setitemArray }}>
      {children}
    </Context.Provider>
  );
};

const useCustomContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useCustomContext must be used within a MainContext");
  }
  return context;
};

export default useCustomContext;
export { MainContext };