import React, { useState, useEffect } from "react";
import "./specialdetail.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import DatePicker from "react-datepicker";
// import { Link } from 'react-router-dom';
import { getSpecialistsDetails } from "../../api/apiListingService";
import {
  addServiceTime,
  adjustEndTime,
  adjustStartTime,
  checkTimeOverlap,
  formatTime,
} from "../../utils/dateFomater";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { cartState } from "../../recoil/atoms/cartAtom";
import {
  capitalizeFirstLetterInSentence,
  formatAmount,
} from "../../utils/generalUtils";
import { AddToCartModal } from "../../Components/Dialog/AddToCart";
import moment from "moment-timezone";
import { Box, MenuItem, Skeleton, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import {
//   tourGuideState,
//   tourGuideStatusState,
//   tourGuideStepState,
// } from "../../recoil/atoms/tourAtom";
import SlotReservationModal from "../../Components/Dialog/SlotReservation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SpecialDetail = (props: any) => {
  const date: any = sessionStorage.getItem("dateTime");
  const currentDate: any = date ? new Date(JSON.parse(date)) : new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [specialistDetails, setSpecialistsData] = useState<any>([]);
  const [timeSlots, setTimeSlots] = useState<any>([]);
  const [cartItem, setCartItem] = useState<any>(null);
  const [activeIndex, setActIndex] = useState<any>();
  const [cartItemsList, setCartItemsList] = useRecoilState(cartState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [ssEmpId, setSSEmpId] = useState<any>(
    window.location.pathname.split("/").pop() || null
  );
  const merchantId = props?.location?.state?.params?.merchantId || "";
  const [value, setValue] = React.useState(0);
  const [timeSlotsLoading, setTimeSlotsLoading] = useState<boolean>(false);
  // const [, setShowTourGuide] = useRecoilState(tourGuideState);
  // const [tourGuideStatus] = useRecoilState(tourGuideStatusState);
  // const [, setTourStep] = useRecoilState(tourGuideStepState);
  const [showReservationModal, setShowReservationModal] =
    useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   if (tourGuideStatus !== "skipped") {
  //     setTourStep(5);
  //     setTimeout(() => {
  //       setShowTourGuide(true);
  //     }, 100);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const fetchSpecialistDetails = async (date: any) => {
    // console.log("Date", date);
    setTimeSlotsLoading(true);
    try {
      setTimeSlots([]);
      setActIndex(-1);
      const resp = await getSpecialistsDetails(
        ssEmpId,
        format(date, "yyyy-MM-dd")
      );
      setSpecialistsData(resp);
      const prePrepDuration = resp?.prePrepDuration;
      const postPrepDuration = resp?.postPrepDuration;

      let spCartServices = cartItemsList.filter(
        (arr: any, index: number) =>
          arr.employeeId === resp.employeeId && arr.cartReserveStatus !== "cart"
      );
      spCartServices.forEach((element: any) => {
        resp.timeSlotObject.forEach((ele: any) => {
          if (ele.availibility) {
            const newStartTime = adjustStartTime(ele.timeSlot, prePrepDuration);
            // console.log("NEW START TIME: ", newStartTime);

            const newEndTime: any = addServiceTime(
              ele.timeSlot,
              resp.serviceDuration,
              postPrepDuration
            );
            // console.log("NEW END TIME: ", newEndTime);

            // let endTime: any = addServiceTime(
            //   ele.timeSlot,
            //   resp.serviceDuration
            // );

            const newExistingStartTime = adjustStartTime(
              element?.start,
              element?.prePrepDuration
            );
            const newExistingEndTime = adjustEndTime(
              element?.end,
              element?.postPrepDuration
            );

            // let checkOverlap = checkTimeOverlap(
            //   element.start,
            //   element.end,
            //   ele.timeSlot,
            //   endTime
            // );

            let checkOverlap = checkTimeOverlap(
              newExistingStartTime,
              newExistingEndTime,
              newStartTime,
              newEndTime
            );
            if (!checkOverlap) {
              ele.availibility = checkOverlap;
            }
          }
        });
      });
      resp.timeSlotObject.forEach((element: any) => {
        element.selected = false;
      });
      setTimeSlots(resp.timeSlotObject);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTimeSlotsLoading(false);
    }
  };
  const setTimeSlot = (timeSlot: any, startTime: any, index: any) => {
    if (cartItem && cartItem?.location === specialistDetails?.address) {
      // console.log("EXISTING CART ITEM");
      cartItem.bookingDate = startDate;
      cartItem.start = startTime;
      cartItem.end = addServiceTime(startTime, cartItem.serviceDuration);
    } else {
      // console.log("NEW CART ITEM");
      const currentprice = formatAmount(specialistDetails?.servicePrice);
      let cartItem = {
        ...specialistDetails,
        bookingDate: startDate,
        start: startTime,
        end: addServiceTime(startTime, specialistDetails.serviceDuration),
        ssEmpId: ssEmpId,
        tip: 0,
        cartReserveStatus: "cart",
        servicename: specialistDetails.serviceName,
        nickName: specialistDetails.nick_name,
        currentprice: currentprice,
        employeeId: specialistDetails.employeeId,
        serviceDuration: specialistDetails.serviceDuration,
        location: specialistDetails.address,
        merchantId: merchantId ? parseInt(merchantId) : "",
      };
      setCartItem(cartItem);
    }
    setActIndex(index);
  };

  // console.log("SPECIALIST DETAILS", specialistDetails);

  // console.log("ACTIVE INDEX: ", activeIndex);

  const handleReservationModal = () => setShowReservationModal(false);

  const handleAddToCart = () => {
    setShowReservationModal(false);
    addToBasket();
  };

  const addToBasket = () => {
    if (
      cartItemsList.length === 0 ||
      cartItemsList.every(
        (item: any) => item.merchantId === cartItem.merchantId
      )
    ) {
      cartItem.cartReserveStatus = "incart";
      toast.success("Service added to the basket", {
        autoClose: 5000,
      });
      setActIndex(-1);
      setCartItemsList((prv: any) => {
        const mergedObject = Object.assign({}, cartItem);
        return [...prv, mergedObject];
      });
    } else {
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleResetAndNewItems = () => {
    cartItem.cartReserveStatus = "incart";
    toast.success("Service added to the basket", {
      autoClose: 5000,
    });
    setActIndex(-1);
    const mergedObject = Object.assign({}, cartItem);
    setCartItemsList([mergedObject]);
    handleClose();
  };

  useEffect(() => {
    fetchSpecialistDetails(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, cartItemsList, ssEmpId]);

  const zoneAbbr = specialistDetails?.timezone
    ? `(${moment.tz(specialistDetails?.timezone).format("z")})`
    : "";

  const serviceDuration = specialistDetails?.serviceDuration
    ? specialistDetails?.serviceDuration <= 30
      ? `${specialistDetails?.serviceDuration} minutes`
      : `${moment
          .duration(specialistDetails?.serviceDuration, "minutes")
          .asHours()} ${
          moment
            .duration(specialistDetails?.serviceDuration, "minutes")
            .asHours() > 1
            ? "hours"
            : "hour"
        }`
    : "";

  return (
    <>
      <div className="spciallistdetailpagecss">
        <NavbarMain />
        <FilterNavbarMain />
        <section className="Specialdetail">
          <div className="container-main-self">
            <div className="texthere">
              <h3>Specialist Detail</h3>
            </div>
            <div className="outermain">
              <div className="uppermainfirst" style={{ gap: "30px" }}>
                <div className="leftsidefirst">
                  <div className="outmain-main">
                    <div className="leftsideinner">
                      <img
                        src={specialistDetails?.cover_photo}
                        alt="cnctwltimg"
                        className="storeimageleft"
                      />
                    </div>
                    <div className="rightsideinner">
                      <h2
                        style={{ lineHeight: "normal", whiteSpace: "nowrap" }}
                      >
                        {capitalizeFirstLetterInSentence(
                          specialistDetails?.nick_name || ""
                        )}{" "}
                      </h2>
                      <h3 style={{ whiteSpace: "nowrap", padding: 0 }}>
                        {capitalizeFirstLetterInSentence(
                          specialistDetails.merchantName || ""
                        )}
                      </h3>
                      {/* <div className='upperright'>
                                                <div className="adasdasa">
                                                    <div className="ratings">
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                    </div>
                                                </div>
                                                <div className='text'>
                                                    <h4>4.7</h4>
                                                    <h5>(4.2k)</h5>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
                <div className="rightsidefirst" style={{ margin: 0 }}>
                  <div className="maplocations">
                    <h5>
                      {capitalizeFirstLetterInSentence(
                        specialistDetails?.address || ""
                      )}
                    </h5>
                    {/* <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://maps.app.goo.gl/6G2V8c44yCuZsqKq8?g_st=ic"
                    >
                      <img
                        src="\assets\imag\clarity_map-solid.svg"
                        alt="cnctwltimg"
                        className="storeimageleft"
                      />
                    </a> */}
                  </div>
                  {/* <Link to="/booking"><button className='bookingdetailcpecilist'>Booking Details</button></Link> */}
                </div>
              </div>
              <Box pb={3.5} display="flex" flexDirection="column" gap={2}>
                <div className="textjust" style={{ marginBottom: 0 }}>
                  <div className="haiers"></div>
                  <h4>
                    {specialistDetails?.serviceName || ""} at{" "}
                    {capitalizeFirstLetterInSentence(
                      specialistDetails?.merchantName || ""
                    )}
                  </h4>
                </div>
                {serviceDuration && (
                  <div className="textjust" style={{ marginBottom: 0 }}>
                    <div className="haiers"></div>
                    <h4>Service duration - {serviceDuration}</h4>
                  </div>
                )}
                {specialistDetails?.servicePrice && (
                  <div className="textjust" style={{ marginBottom: 0 }}>
                    <div className="haiers"></div>
                    <h4>
                      Service price - $
                      {formatAmount(specialistDetails?.servicePrice)}
                    </h4>
                  </div>
                )}
              </Box>
              <div className="bookingdate">
                <label>Booking Date</label>
                <div className="inner-11 ooooo">
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date);
                    }}
                    dateFormat="MMM dd, yyyy"
                  />
                  <img
                    src="\assets\allstore\Calender.png"
                    alt="cnctwltimg"
                    className="cnctwltimsdsdsddsg"
                  />
                </div>
              </div>
              <Box
                sx={{
                  border: "none!important",
                  marginBottom: "0px!important",
                }}
                flexDirection="column"
                gap={2}
                className="timeavaible"
              >
                <Box display="flex" alignItems="center">
                  <img
                    src="\assets\allstore\Clock.png"
                    alt="cnctwltimg"
                    className="sadasasdasda"
                  />
                  <div className="inneravable">
                    <h5>Available Time Slots</h5>
                    {/* <h6>{format(startDate, "MM/dd/yyy")}</h6> */}
                  </div>
                </Box>
                <Box
                  sx={{
                    touchAction: "none",
                  }}
                >
                  {specialistDetails?.stores?.length > 1 ? (
                    <TextField
                      variant="outlined"
                      id="outlined-select-location"
                      select
                      value={ssEmpId}
                      onChange={(e: any) => {
                        setCartItem(null);
                        setSSEmpId(e.target.value);
                      }}
                      sx={{
                        borderRadius: "5px",
                        border: "1px solid #4B2763",
                        background: "#EDF0FF",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        width: "470px",
                        height: "55px",
                        paddingLeft: "12px",
                        outline: "none",
                      }}
                    >
                      {specialistDetails?.stores?.map(
                        (option: { ssEmpId: number; address: string }) => (
                          <MenuItem key={option.ssEmpId} value={option.ssEmpId}>
                            {option.address}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  ) : (
                    <h5>{specialistDetails?.address}</h5>
                  )}
                </Box>
              </Box>

              {specialistDetails?.timezone !== moment.tz.guess(true) ? (
                <Box sx={{ width: "100%" }}>
                  <Box display="flex" gap={1} alignItems="baseline" mb={2.4}>
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: "#fd7e14",
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      Indicates the time slots for{" "}
                      {moment(startDate).add(1, "day").format("MMM DD, YYYY")}
                    </Box>
                  </Box>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Timezone tabs"
                      sx={{
                        "&.MuiTabs-root": {
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },

                          "& .MuiButtonBase-root": {
                            // borderRadius: "20px",
                            backgroundColor: "#DADADA",
                            color: "#707070",
                            width: 235,

                            "&.local": {
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                            },

                            "&.store": {
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                            },

                            "&.Mui-selected": {
                              backgroundColor: "#4B2763",
                              color: "#fff",
                              fontWeight: 700,
                            },
                          },
                        },
                      }}
                    >
                      <Tab
                        className="local"
                        label="Local Time"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="store"
                        label={`Store Time ${zoneAbbr}`}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Box className="allslots" gap={timeSlotsLoading ? 2 : 0}>
                      {timeSlotsLoading ? (
                        <>
                          {new Array(30).fill("").map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="rectangular"
                              width={96}
                              height={36}
                            />
                          ))}
                        </>
                      ) : timeSlots?.length > 0 ? (
                        timeSlots.map((sl: any, index: any) => (
                          <div
                            key={index}
                            style={{ position: "relative" }}
                            onClick={() => {
                              sl.selected = "slot-selected";
                              setTimeSlot(timeSlots, sl?.timeSlot, index);
                            }}
                            className={
                              sl.availibility
                                ? `slotdesignsimple`
                                : "slotdesigndisabled"
                            }
                          >
                            {moment(sl?.timeSlot).format("MMM DD YYYY") !==
                            moment(sl?.timeSlot)
                              .tz(specialistDetails?.timezone)
                              .format("MMM DD YYYY") ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: "#fd7e14",
                                    borderRadius: "50%",
                                  }}
                                />
                              </Box>
                            ) : null}

                            <h2
                              className={
                                index === activeIndex ? `slot-selected` : ""
                              }
                            >
                              {formatTime(sl.timeSlot)}
                            </h2>
                          </div>
                        ))
                      ) : (
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          No slots available for {specialistDetails?.nick_name}{" "}
                          at {moment(startDate).format("MMM DD, YYYY")} in{" "}
                          {specialistDetails?.address}
                        </Box>
                      )}
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="allslots">
                      {timeSlotsLoading ? (
                        <>
                          {new Array(30).fill("").map((_, index) => (
                            <Skeleton
                              key={index}
                              variant="rectangular"
                              width={96}
                              height={36}
                            />
                          ))}
                        </>
                      ) : timeSlots?.length > 0 ? (
                        timeSlots.map((sl: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => {
                              sl.selected = "slot-selected";
                              setTimeSlot(timeSlots, sl.timeSlot, index);
                            }}
                            className={
                              sl.availibility
                                ? `slotdesignsimple`
                                : "slotdesigndisabled"
                            }
                          >
                            <h2
                              className={
                                index === activeIndex ? `slot-selected` : ""
                              }
                            >
                              {moment(sl.timeSlot)
                                .tz(specialistDetails?.timezone)
                                .format("h:mm A")}
                            </h2>
                          </div>
                        ))
                      ) : (
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          No slots available for {specialistDetails?.nick_name}{" "}
                          at {moment(startDate).format("MMM DD, YYYY")} in{" "}
                          {specialistDetails?.address}
                        </Box>
                      )}
                    </div>
                  </CustomTabPanel>
                </Box>
              ) : (
                <div className="allslots">
                  {timeSlotsLoading ? (
                    <>
                      {new Array(30).fill("").map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rectangular"
                          width={96}
                          height={36}
                        />
                      ))}
                    </>
                  ) : timeSlots?.length > 0 ? (
                    timeSlots.map((sl: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => {
                          sl.selected = "slot-selected";
                          setTimeSlot(timeSlots, sl.timeSlot, index);
                        }}
                        className={
                          sl.availibility
                            ? `slotdesignsimple`
                            : "slotdesigndisabled"
                        }
                      >
                        <h2
                          className={
                            index === activeIndex ? `slot-selected` : ""
                          }
                        >
                          {moment(sl.timeSlot)
                            .tz(specialistDetails?.timezone)
                            .format("h:mm A")}
                        </h2>
                      </div>
                    ))
                  ) : (
                    <Box
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      No slots available for {specialistDetails?.nick_name} at{" "}
                      {moment(startDate).format("MMM DD, YYYY")} in{" "}
                      {specialistDetails?.address}
                    </Box>
                  )}
                </div>
              )}

              <button
                onClick={() => {
                  setShowReservationModal(true);
                }}
                style={{
                  marginTop: "30px",
                }}
                className={
                  activeIndex >= 0
                    ? "buttonaddtobacket"
                    : "disabled-btn buttonaddtobacket"
                }
              >
                Add to Basket
              </button>
            </div>
          </div>
        </section>
        <FooterMain />
      </div>
      <AddToCartModal
        showModal={showModal}
        handleClose={handleClose}
        handleResetAndNewItems={handleResetAndNewItems}
      />
      {showReservationModal && cartItem && (
        <SlotReservationModal
          showModal={showReservationModal}
          handleClose={handleReservationModal}
          serviceName={cartItem?.servicename}
          specialistName={cartItem?.nickName}
          timezone={cartItem?.timezone}
          startDate={cartItem?.start}
          endDate={cartItem?.end}
          bookingDate={cartItem?.bookingDate}
          handleAddToCart={handleAddToCart}
        />
      )}
    </>
  );
};

export default SpecialDetail;
