import React, { useEffect, useState } from "react";
import { formatSearchDateTime } from "../../utils/dateFomater";
import { getServiceData } from "../../api/apiListingService";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil/atoms/userAtom";
import { format } from "date-fns";
import { Box } from "@mui/material";
import { capitalizeFirstLetterInSentence } from "../../utils/generalUtils";
import moment from "moment-timezone";
import { Link, useHistory } from "react-router-dom";
import NavbarMain from "../../Components/NavbarMain";
import FooterMain from "../../Components/Footer";

const UpcomingAppointments = () => {
  const [upcomingServices, setUpcomingServices] = useState([]);
  const [opens, setOpens] = useState<any>(false);
  const [currentUser] = useRecoilState(userState);
  const history = useHistory();

  const fetchDataFunction = async (date: string, time: any) => {
    try {
      setOpens(true);

      time = new Date(time);
      const hours = time.getHours();
      const minutes = time.getMinutes().toString().padStart(2, "0");
      time = `${hours}:${minutes}`;
      let newDate: any = new Date(date);
      const timeParts = time.split(":");
      // hour and minute
      newDate.setHours(parseInt(timeParts[0]));
      newDate.setMinutes(parseInt(timeParts[1]));
      // Get services data from api
      newDate = format(newDate, "yyyy-MM-dd'T'HH:mm:ss");
      let body: any = { date: formatSearchDateTime(date) };

      const resp = await getServiceData(body, currentUser?.access_token);
      if (resp && resp?.upcomingServices?.length > 0) {
        setUpcomingServices(resp?.upcomingServices);
      }
      setOpens(false);
    } catch (error) {
      setOpens(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    let date: any = sessionStorage.getItem("dateTime");
    const currentTime: any = date ? new Date(JSON.parse(date)) : new Date();
    fetchDataFunction(currentTime.toString(), currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <NavbarMain />
      {upcomingServices?.length > 0 ? (
        <Box
          sx={{
            background: "#FFFFFF",
          }}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            className="container-main-self"
            py="58px"
          >
            <Box display="flex" flexDirection="column" gap="30px" mb="30px">
              <Link to="/">
                <Box
                  component="button"
                  sx={{
                    border: "1px solid #4b2763",
                    color: "#4b2763",
                    borderRadius: "10px",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 400,
                    height: "50px",
                    lineHeight: "normal",
                    outline: "none",
                    width: "100px",

                    "&:hover": {
                      background: "#4b2763",
                      color: "#fff",
                    },
                  }}
                >
                  Back
                </Box>
              </Link>
              <Box
                component="p"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "35px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                Upcoming Appointments
              </Box>
            </Box>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="30px"
            >
              {upcomingServices.map((serviceItem: any, index: number) => {
                return (
                  <Box
                    key={index}
                    component="div"
                    onClick={() =>
                      history.push(`/bookingdetail/${serviceItem?.booking_id}`)
                    }
                    p="24px"
                    sx={{
                      cursor: "pointer",
                      border: "1px solid rgba(75, 39, 99, 0.2)",
                      borderRadius: "17px",
                      backgroundColor:
                        serviceItem?.service_status === "cancelled"
                          ? "#FFEDED!important"
                          : serviceItem?.service_status === "accepted"
                          ? "#E9FFFE!important"
                          : serviceItem?.service_status === "pending"
                          ? "#FFFDEC!important"
                          : "#FFF",
                    }}
                  >
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb="20px"
                        borderBottom="1px dashed rgba(75, 39, 99, 0.2)"
                      >
                        <Box display="flex" alignItems="center" gap={3}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <img
                              src="\assets\majesticons_calendar.svg"
                              alt="cnctwltimg"
                              className="cnctwltimg"
                            />
                            <h6>
                              {serviceItem?.timezone_id &&
                              serviceItem?.timezone_id !== moment.tz.guess(true)
                                ? moment(serviceItem?.service_start_time)
                                    .tz(serviceItem?.timezone_id)
                                    .format("dddd MMM DD, YYYY")
                                : moment(
                                    serviceItem?.service_start_time
                                  ).format("dddd MMM DD, YYYY")}
                            </h6>
                          </Box>
                          <Box display="flex" alignItems="center" gap={1}>
                            <img
                              src="\assets\ri_time-line.svg"
                              alt="cnctwltimg"
                              className="cnctwltimsdchjudsbcg"
                            />
                            <h6>
                              {moment(serviceItem?.service_start_time)
                                .tz(serviceItem?.timezone_id)
                                .format("hh:mm A")}{" "}
                              -{" "}
                              {moment(serviceItem?.service_end_time)
                                .tz(serviceItem?.timezone_id)
                                .format("hh:mm A")}{" "}
                              {serviceItem?.timezone_id &&
                              serviceItem?.timezone_id !== moment.tz.guess(true)
                                ? `(${moment
                                    .tz(serviceItem?.timezone_id)
                                    .format("z")})`
                                : ""}
                            </h6>
                          </Box>
                        </Box>

                        <Box
                          component="p"
                          textTransform="uppercase"
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            color:
                              serviceItem?.service_status === "cancelled"
                                ? "#C82A2F"
                                : serviceItem?.service_status === "accepted"
                                ? "#28a745"
                                : serviceItem?.service_status === "pending"
                                ? "#D08846"
                                : "#FFF",
                          }}
                        >
                          {capitalizeFirstLetterInSentence(
                            serviceItem?.service_status || ""
                          )}
                        </Box>
                      </Box>

                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          component="div"
                          display="flex"
                          flexDirection="column"
                          gap="12px"
                        >
                          <Box
                            component="p"
                            sx={{
                              color: "rgb(0, 0, 0)",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "normal",
                            }}
                          >
                            {capitalizeFirstLetterInSentence(
                              serviceItem?.servicename || ""
                            )}
                          </Box>
                          <Box
                            component="p"
                            sx={{
                              color: "rgb(75, 39, 99)",
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            With{" "}
                            <Box component="span">
                              {capitalizeFirstLetterInSentence(
                                serviceItem?.specialist_name || ""
                              )}
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center" gap="10px">
                            <Box
                              sx={{
                                background: "#89CA8C",
                                color: "#000",
                                borderRadius: "17px",
                                padding: "5px 20px",
                              }}
                            >
                              <Box component="p" fontWeight={500}>
                                Payment Status:{" "}
                                {capitalizeFirstLetterInSentence(
                                  serviceItem?.paymentStatus || ""
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box component="div" width={150} height={150}>
                          <img
                            style={{
                              objectFit: "cover",
                              width: "inherit",
                              height: "inherit",
                              borderRadius: "17px",
                            }}
                            src={serviceItem?.specialist_display_picture}
                            alt="cnctwltimg"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
      <FooterMain />
    </>
  );
};

export default UpcomingAppointments;
