import { useState, useEffect } from "react";
import "./booking.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
// import { useHistory } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  acceptMutipleLineItems,
  cancelMutipleLineItems,
  cancelSingleLineItems,
  getBookingsDetails,
  acceptSingleLineItems,
  addAdditionalNote,
} from "../../api/apiListingService";
import { toast } from "react-toastify";
import {
  capitalizeFirstLetterInSentence,
  formatAmount,
  parseNotes,
} from "../../utils/generalUtils";
import moment from "moment-timezone";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { rescheduleBookingState } from "../../recoil/atoms/rescheduleBookingAtom";
import { calculateDurationInMinutes } from "../../utils/dateFomater";
import { userState } from "../../recoil/atoms/userAtom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useForm, FormProvider as Form } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdditionalNotesList from "../../Components/AdditionalNotesList";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const additionalNotesSchema = yup.object().shape({
  notes: yup.string(),
});

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pb: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BookDetail = () => {
  // const history = useHistory()
  // const calenderview = () => {
  //     history.push("/calender")
  // }
  const [opens, setOpens] = useState<any>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectAlls, setSelectAlls] = useState<boolean>(false);
  const [bookingDetail, setBookingList] = useState<any>([]);
  const [userType, setUserType] = useState<any>();
  const [count, setCount] = useState(0);
  const [, setBookingDetailData] = useRecoilState(rescheduleBookingState);
  const [currentUser] = useRecoilState(userState);
  const [value, setValue] = useState(0);

  const additionalNotesMethods = useForm({
    resolver: yupResolver(additionalNotesSchema),
  });
  const {
    register: additionalNotesRegister,
    handleSubmit: handleAdditionalNotesSubmit,
    formState: { errors },
    reset: additionalNotesReset,
  } = additionalNotesMethods;

  const onAdditionalNotesSubmit = async (data: any) => {
    try {
      const response = await addAdditionalNote(
        window.location.pathname.split("/").pop() as string,
        data,
        currentUser?.access_token
      );
      if (response && response?.message) {
        toast.success(response?.message, {
          autoClose: 5000,
        });
        additionalNotesReset();
        getBookingsData();
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const setRescheduleBookingData = (service: any) => {
    const data = {
      ...bookingDetail,
      serviceDetails: {
        ...service,
        serviceDuration: calculateDurationInMinutes(
          service?.start,
          service?.end
        ),
        serviceDate:
          service?.timezone !== moment.tz.guess(true)
            ? moment(service?.start)
                .tz(service?.timezone)
                .format("MMM DD, YYYY")
            : moment(service?.start).format("MMM DD, YYYY"),
      },
    };
    setBookingDetailData(data);
  };

  const getBookingsData = async () => {
    try {
      setOpens(true);
      setBookingList([]);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        const resp = await getBookingsDetails(
          window.location.pathname.split("/").pop(),
          data?.access_token
        );
        resp.notes = parseNotes(resp?.notes);
        resp.serviceDetails.forEach((element: any) => {
          element.date = element.date.split(" ");
        });
        resp.serviceDetails.map((b: any) => (b.checked = false));
        resp.paymentSummary.total =
          parseFloat(resp?.paymentSummary?.subtotal) +
          parseFloat(resp?.paymentSummary?.tip);
        setUserType(data?.userType);
        setBookingList(resp);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpens(false);
    }
  };

  useEffect(() => {
    getBookingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  // const handleShow2 = () => setShow2(true);

  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  const [show24, setShow24] = useState(false);
  const handleClose24 = () => setShow24(false);
  const handleShow24 = () => setShow24(true);
  const handleAllCheckboxChange = () => {
    const toggleAll = !selectAlls;
    bookingDetail?.serviceDetails.map((row: any) => (row.checked = toggleAll));
    setCount(
      bookingDetail?.serviceDetails?.filter((row: any) => row.checked === true)
        .length
    );
    setSelectAlls(toggleAll);
  };

  const handleSingleCheckboxChange = (rowId: any) => {
    const toggleAll = !selectAll;
    const updatedRows = bookingDetail?.serviceDetails?.map((row: any) =>
      row.line_id === rowId ? (row.checked = !row.checked) : false
    );
    setCount(
      bookingDetail?.serviceDetails?.filter((row: any) => row.checked === true)
        .length
    );
    setSelectAll(toggleAll);
    setSelectAlls(updatedRows.every((row: any) => row.checked));
  };
  const acceptBookinglineItems = async () => {
    try {
      setOpens(true);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let bookingLineItemIds: any = [];
        let bookingId: any = window.location.pathname.split("/").pop();
        bookingDetail?.serviceDetails.forEach((element: any) => {
          if (element.checked) {
            bookingLineItemIds.push(element.line_id);
          }
        });
        if (bookingLineItemIds.length === 0) {
          toast.error("Please Select Booking Line items", {
            autoClose: 5000,
          });
          setOpens(false);
          return false;
        }
        const resp = await acceptMutipleLineItems(
          {
            bookingDetails: bookingLineItemIds,
            bookingId: parseInt(bookingId),
          },
          data?.access_token
        );
        toast.success(resp.message, {
          autoClose: 5000,
        });
        setOpens(false);
        handleClose24();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      handleClose24();
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      setOpens(false);
    }
  };

  const cancelBookingLineItems = async () => {
    try {
      setOpens(true);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let bookingLineItemIds: any = [];
        let bookingId: any = window.location.pathname.split("/").pop();
        bookingDetail?.serviceDetails.forEach((element: any) => {
          if (element.checked) {
            bookingLineItemIds.push(element.line_id);
          }
        });
        if (bookingLineItemIds.length === 0) {
          toast.error("Please Select Booking Line items", {
            autoClose: 5000,
          });
          setOpens(false);
          return false;
        }
        const resp = await cancelMutipleLineItems(
          {
            bookingDetails: bookingLineItemIds,
            bookingId: parseInt(bookingId),
          },
          data?.access_token
        );
        handleClose23();
        toast.success(resp.message, {
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setOpens(false);
      }
    } catch (error: any) {
      handleClose23();
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      setOpens(false);
    }
  };
  const cancelSingleLineItem = async (lineId: any) => {
    try {
      setOpens(true);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let bookingId: any = window.location.pathname.split("/").pop();
        const resp = await cancelSingleLineItems(
          lineId,
          parseInt(bookingId),
          data?.access_token
        );
        toast.success(resp.message, {
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setOpens(false);
      }
    } catch (error: any) {
      handleClose23();
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      setOpens(false);
    }
  };
  const acceptSingleLineItem = async (lineId: any) => {
    try {
      setOpens(true);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let bookingId: any = window.location.pathname.split("/").pop();
        const resp = await acceptSingleLineItems(
          lineId,
          parseInt(bookingId),
          data?.access_token
        );
        toast.success(resp.message, {
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setOpens(false);
      }
    } catch (error: any) {
      handleClose23();
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      setOpens(false);
    }
  };

  let createdAtDate = bookingDetail?.bookingDetails?.createdAt;
  const createdAt = bookingDetail?.bookingDetails?.createdAt
    ? moment(
        bookingDetail?.bookingDetails?.createdAt,
        "Do MMM YYYY"
      ).toISOString()
    : "";
  if (createdAt) {
    createdAtDate = moment(createdAt).format("MMM DD, YYYY");
  }

  return (
    <>
      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <NavbarMain />
      <FilterNavbarMain />
      <section className="bookingmain">
        <div className="container-main-self">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Booking Detail tabs"
            sx={{
              my: "30px",

              "&.MuiTabs-root": {
                "& .MuiTabs-indicator": {
                  display: "none",
                },

                "& .MuiButtonBase-root": {
                  backgroundColor: "#DADADA",
                  color: "#707070",
                  width: 235,

                  "&.local": {
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                  },

                  "&.store": {
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  },

                  "&.Mui-selected": {
                    backgroundColor: "#4B2763",
                    color: "#fff",
                    fontWeight: 700,
                  },
                },
              },
            }}
          >
            <Tab
              sx={{
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
              label="Booking Details"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
              label={"Booking Notes"}
              {...a11yProps(1)}
            />
          </Tabs>
          {/* <div className="texthere">
            <h3>Booking Details</h3>
          </div> */}
          <CustomTabPanel value={value} index={0}>
            <div className="row detailbooking" style={{ paddingTop: "15px" }}>
              <div className="col-lg-4 ppppppp">
                <Box>
                  <Box component="div" className="left">
                    <h2>Customer Details</h2>
                    <Box component="div" className="innnerrrr" gap={2.4}>
                      <Box display="flex" flexDirection="column" gap={2.2}>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Name:
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Email:
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap={2.2}>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.customerDetails?.name
                            ? capitalizeFirstLetterInSentence(
                                bookingDetail?.customerDetails?.name
                              )
                            : ""}
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.customerDetails?.email_address}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className="col-lg-5">
                <Box>
                  <Box component="div" className="left">
                    <h2>Store Details</h2>
                    <Box component="div" className="innnerrrr" gap={2.4}>
                      <Box display="flex" flexDirection="column" gap={2.2}>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Name:
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Phone:
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Location:
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap={2.2}>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.merchantDetails?.name
                            ? capitalizeFirstLetterInSentence(
                                bookingDetail?.merchantDetails?.name
                              )
                            : ""}
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.merchantDetails?.phone}
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.merchantDetails?.locations?.[0]}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className="col-lg-3">
                <Box>
                  <Box component="div" className="left">
                    <h2>Payment Details</h2>
                    <Box component="div" className="innnerrrr" gap={2.4}>
                      <Box display="flex" flexDirection="column" gap={2.2}>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Payment Method:
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          Payment Status:
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap={2.2}>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            // wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.paymentDetails?.paymentMethod ===
                          "pay_after_service"
                            ? "Pay after service"
                            : capitalizeFirstLetterInSentence(
                                bookingDetail?.paymentDetails?.paymentMethod
                              )}
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            wordBreak: "break-all",
                          }}
                        >
                          {bookingDetail?.paymentDetails?.paymentStatus
                            ? capitalizeFirstLetterInSentence(
                                bookingDetail?.paymentDetails?.paymentStatus
                              )
                            : ""}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>

            <div className="row detailbooking bookingtwo">
              <div className="col-lg-4 ppppppp">
                <div className="left">
                  <h2>Booking Detail</h2>
                  <div className="innnerrr">
                    <div className="leftsidee">
                      <h3>Booking ID:</h3>
                    </div>
                    <div className="rightsidee content-right">
                      <h3>{bookingDetail?.bookingDetails?.bookingId}</h3>
                    </div>
                  </div>
                  {/* <div className='innnerrr'>
                                    <div className='leftsidee'>
                                        <h3>Booking Date:</h3>
                                    </div>
                                    <div className='rightsidee'>
                                        <h3>{bookingDetail?.bookingDetails?.createdAt}</h3>
                                    </div>
                                </div> */}
                  <div className="innnerrr">
                    <div className="leftsidee">
                      <h3>Created Date:</h3>
                    </div>
                    <div className="rightsidee content-right">
                      <h3>{createdAtDate}</h3>
                    </div>
                  </div>
                  <div className="innnerrr">
                    <div className="leftsidee">
                      <h3 className="p-0">Status:</h3>
                    </div>
                    <div className="rightsidee content-right">
                      <h3 className="p-0 llppllp">
                        {bookingDetail?.bookingDetails?.status}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 detailbooking">
                <div className="left">
                  <h2>Payment Summary</h2>
                  <div className="innnerrrr">
                    <div className="leftsidere oopp">
                      <h3>Subtotal</h3>
                    </div>
                    <div className="rightsideer content-right">
                      <h3>
                        ${formatAmount(bookingDetail?.paymentSummary?.subtotal)}
                      </h3>
                    </div>
                  </div>
                  <div className="innnerrrr">
                    <div className="leftsidere oopp">
                      <h3>Tip</h3>
                    </div>
                    <div className="rightsideer content-right">
                      <h3>
                        $
                        {formatAmount(
                          bookingDetail?.paymentSummary?.tip || 0.0
                        )}
                      </h3>
                    </div>
                  </div>
                  <div className="innnerrrr">
                    <div className="leftsidere oopp">
                      <h3>Discount/Gift Card</h3>
                    </div>
                    <div className="rightsideer content-right">
                      <h3>${formatAmount(bookingDetail?.discountAmount)}</h3>
                    </div>
                  </div>
                  <div className="innnerrrr">
                    <div className="leftsidere oopp">
                      <h3 style={{ color: "#4b2763" }}>Total</h3>
                    </div>
                    <div className="rightsideer content-right">
                      <h3
                        style={{
                          fontSize: 25,
                          fontWeight: 700,
                          color: "#4b2763",
                        }}
                      >
                        $
                        {formatAmount(
                          bookingDetail?.paymentSummary?.total -
                            bookingDetail?.discountAmount
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 detailbooking">
                <div className="left" style={{ wordBreak: "break-word" }}>
                  <h2>Additional Notes</h2>
                  <p>{bookingDetail?.notes}</p>
                </div>
              </div> */}
            </div>
            <div className="downdivservice">
              <div
                className="service_detsilcheck"
                style={{
                  justifyContent: "space-between",
                  paddingRight: "46px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2>Service Details</h2>
                  {!bookingDetail?.serviceDetails?.every(
                    (item: any) => item.refType === "gift_card"
                  ) ? (
                    <div className="checkboxxes">
                      <div className="form-check mmmmlllmmll">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked1"
                          checked={selectAlls}
                          onChange={handleAllCheckboxChange}
                        />
                        <label>Select All</label>
                      </div>
                      {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                    <label>Hide completed</label>
                                </div> */}
                    </div>
                  ) : null}
                </div>
                {!bookingDetail?.serviceDetails?.every(
                  (item: any) => item.refType === "gift_card"
                ) ? (
                  <div className="buttonall" style={{ gap: "34px" }}>
                    <button onClick={handleShow23}>
                      <img
                        src="\assets\prof\Group 30862.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </button>
                    {!bookingDetail?.serviceDetails?.every(
                      (item: any) => item.status === "accepted"
                    ) ? (
                      <button
                        onClick={handleShow24}
                        className={userType === "customer" ? "d-n" : ""}
                      >
                        <img
                          src="\assets\prof\Group 30896.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </button>
                    ) : null}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="outernewgiftcard">
              <div className="rowdgiftcard">
                <div className="leftsidegiorft">
                  <h1 className="one">150$</h1>
                  <h1 className="two">Gift Card</h1>
                </div>
                <div className="rightsidegiorft">
                  <h1 className="three">Assigned</h1>
                </div>
              </div>
              <div className="rowdgiftcard">
                <div className="leftsidegiorft">
                  <h1 className="one">150$</h1>
                  <h1 className="two">Gift Card</h1>
                </div>
                <div className="rightsidegiorft">
                  <h1 className="three">Assigned</h1>
                </div>
              </div>
              <div className="rowdgiftcard">
                <div className="leftsidegiorft">
                  <h1 className="one">150$</h1>
                  <h1 className="two">Gift Card</h1>
                </div>
                <div className="rightsidegiorft">
                  <h1 className="three">Assigned</h1>
                </div>
              </div>
              <div className="rowdgiftcard">
                <div className="leftsidegiorft">
                  <h1 className="one">150$</h1>
                  <h1 className="two">Gift Card</h1>
                </div>
                <div className="rightsidegiorft">
                  <h1 className="three">Assigned</h1>
                </div>
              </div>
            </div> */}
              {bookingDetail?.serviceDetails?.map((sd: any, index: number) => {
                let isPastDate = false;
                if (sd?.refType !== "gift_card") {
                  const serviceDate =
                    sd?.timezone !== moment.tz.guess(true)
                      ? moment(sd?.start).tz(sd?.timezone)
                      : moment(sd?.start);
                  isPastDate = serviceDate.isSameOrBefore(
                    moment().format("YYYY-MM-DD"),
                    "day"
                  );
                }
                return (
                  <div key={index} className="outerinner">
                    {sd.refType === "gift_card" ? (
                      <>
                        <Box
                          className={`rightsideinnerdetail ${
                            bookingDetail?.serviceDetails?.some(
                              (item: any) => item.refType === null
                            ) > 1
                              ? "m-l-170"
                              : ""
                          }`}
                          sx={{
                            width: "100%",
                            border: "1px solid rgba(119, 44, 162, 0.6)",
                            backgroundColor: "#EDF0FF!important",
                            py: "15px!important",
                          }}
                        >
                          {/* <div className="mainrightsideborder"></div> */}
                          <div className="maindiv">
                            <div className="asasasasasas">
                              <div className="lefiside">
                                <h3 style={{ color: "#4B2763" }}>Gift Card</h3>
                                <h6 style={{ color: "#4B2763" }}>
                                  Gift Card Value: $
                                  {formatAmount(sd.giftCardValue)}
                                </h6>
                              </div>
                              <div className="rigiside">
                                <Box
                                  sx={{
                                    backgroundColor: "#4B2763",
                                    px: 3,
                                    py: 0.6,
                                    borderRadius: "14px",
                                    fontWeight: 700,
                                  }}
                                >
                                  <h5 style={{ margin: 0, color: "#fff" }}>
                                    {sd?.giftCardStatus}
                                  </h5>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </>
                    ) : (
                      <>
                        {/* <div className="outmainleft_sidecheck">
                      <div className="checkboxxes">
                        <div className="form-check">
                          {sd?.status === "cancelled" ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              disabled={true}
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked1"
                              checked={sd.checked}
                              onChange={() =>
                                handleSingleCheckboxChange(sd.line_id)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="leftsideinnerdetail">
                        <div className="datehi">
                          <h3>{sd?.date[0]}</h3>
                          <h5>
                            {sd?.date[1]} {sd?.date[2]}{" "}
                            {sd?.timezone &&
                            sd?.timezone !== moment.tz.guess(true)
                              ? `(${moment.tz(sd?.timezone).format("z")})`
                              : ""}
                          </h5>
                        </div>
                        <div className="timess">
                          <h6>
                            {moment(sd?.start)
                              .tz(sd?.timezone)
                              .format("hh:mm A")}
                          </h6>
                          <div className="leftborder"></div>
                          <h6>
                            {moment(sd?.end).tz(sd?.timezone).format("hh:mm A")}
                          </h6>
                        </div>
                      </div>
                    </div> */}

                        <Box
                          className="rightsideinnerdetail"
                          sx={{
                            width: "100%",
                            border: "1px solid rgba(75, 39, 99, 0.2)",
                            backgroundColor:
                              sd?.status === "cancelled"
                                ? "#FFEDED!important"
                                : sd?.status === "accepted"
                                ? "#E9FFFE!important"
                                : sd?.status === "pending"
                                ? "#FFFDEC!important"
                                : "#FFF",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={2.5}
                          >
                            <Box display="flex" alignItems="center" gap={2.4}>
                              <div className="outmainleft_sidecheck">
                                <div className="checkboxxes">
                                  <div className="form-check">
                                    {sd?.status === "cancelled" ? (
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        disabled={true}
                                        style={{ marginTop: 0 }}
                                      />
                                    ) : (
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked1"
                                        checked={sd.checked}
                                        style={{ marginTop: 0 }}
                                        onChange={() =>
                                          handleSingleCheckboxChange(sd.line_id)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Box
                                component="p"
                                sx={{
                                  fontSize: "14px",
                                  color: "#212529",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                }}
                              >
                                Appointment date
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                component="span"
                                sx={{
                                  color:
                                    sd?.status === "cancelled"
                                      ? "#C82A2F!important"
                                      : sd?.status === "accepted"
                                      ? "#28a745!important"
                                      : sd?.status === "pending"
                                      ? "#D08846!important"
                                      : "#FFF",
                                  fontFamily: "Poppins",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                }}
                              >
                                {sd?.status?.toUpperCase()}
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            component="div"
                            className="maindiv"
                            pb={2}
                            sx={{
                              borderBottom: "1px dashed rgba(75, 39, 99, 0.2)",
                              alignItems: "center!important",
                            }}
                          >
                            <Box display="flex" alignItems="center" gap={3}>
                              <Box display="flex" alignItems="center" gap={1}>
                                <img
                                  src="\assets\majesticons_calendar.svg"
                                  alt="cnctwltimg"
                                  className="cnctwltimg"
                                />
                                <h6>
                                  {sd?.timezone &&
                                  sd?.timezone !== moment.tz.guess(true)
                                    ? moment(sd?.start)
                                        .tz(sd?.timezone)
                                        .format("dddd MMM DD, YYYY")
                                    : moment(sd?.start).format(
                                        "dddd MMM DD, YYYY"
                                      )}
                                </h6>
                              </Box>
                              <Box display="flex" alignItems="center" gap={1}>
                                <img
                                  src="\assets\ri_time-line.svg"
                                  alt="cnctwltimg"
                                  className="cnctwltimsdchjudsbcg"
                                />
                                <h6>
                                  {moment(sd?.start)
                                    .tz(sd?.timezone)
                                    .format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(sd?.end)
                                    .tz(sd?.timezone)
                                    .format("hh:mm A")}{" "}
                                  {sd?.timezone &&
                                  sd?.timezone !== moment.tz.guess(true)
                                    ? `(${moment.tz(sd?.timezone).format("z")})`
                                    : ""}
                                </h6>
                              </Box>
                            </Box>
                            <div
                              className="buttoninner"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "34px",
                              }}
                            >
                              <button
                                className={
                                  sd?.status === "cancelled" ? "d-n" : ""
                                }
                                onClick={() => cancelSingleLineItem(sd.line_id)}
                              >
                                <img
                                  src="\assets\prof\Group 30862.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </button>
                              <button
                                className={
                                  userType === "customer" ||
                                  sd?.status === "accepted" ||
                                  sd?.status === "cancelled"
                                    ? "d-n"
                                    : ""
                                }
                                onClick={() => acceptSingleLineItem(sd.line_id)}
                              >
                                <img
                                  src="\assets\prof\Group 30896.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </button>
                              {userType === "specialist" &&
                                currentUser?.userId === sd?.employeeUserId &&
                                sd?.rescheduleIndicator && (
                                  <button
                                    className={
                                      sd?.status === "accepted" ||
                                      sd?.status === "pending"
                                        ? ""
                                        : "d-n"
                                    }
                                    onClick={() => setRescheduleBookingData(sd)}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/reschedulebooking/${sd?.ssEmpId}`,
                                      }}
                                    >
                                      <img
                                        src="\assets\prof\Group 31033.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </Link>
                                  </button>
                                )}
                              {!isPastDate && userType === "customer" && (
                                <button
                                  className={
                                    (sd?.status === "accepted" ||
                                      sd?.status === "pending") &&
                                    sd?.rescheduleIndicator
                                      ? ""
                                      : "d-n"
                                  }
                                  onClick={() => setRescheduleBookingData(sd)}
                                >
                                  <Link
                                    to={{
                                      pathname: `/reschedulebooking/${sd?.ssEmpId}`,
                                    }}
                                  >
                                    <img
                                      src="\assets\prof\Group 31033.svg"
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </Link>
                                </button>
                              )}
                            </div>
                          </Box>

                          <Box
                            sx={{
                              pt: 1,
                            }}
                          >
                            {/* <div className="mainrightsideborder"></div> */}
                            <Box
                              component="p"
                              sx={{
                                color: "#000",
                                fontFamily: "Poppins",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                              }}
                            >
                              {sd.serviceName}
                            </Box>
                            <Box
                              component="p"
                              sx={{
                                color: "#4B2763",
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                py: "12px",
                              }}
                            >
                              With {sd.specialist}
                            </Box>
                            <Box display="flex" alignItems="center" gap={5}>
                              <h6>
                                Services Charges: ${formatAmount(sd.amount)}
                              </h6>
                              <Box
                                component="h6"
                                sx={{
                                  color: "#000",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "normal",
                                }}
                              >
                                Tip Amount: ${formatAmount(sd.tip)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AdditionalNotesList notes={bookingDetail?.notes || []} />
            <div className="additional-notes">
              <div className="centerdiv_right">
                <Form {...additionalNotesMethods}>
                  <form
                    autoComplete="off"
                    onSubmit={handleAdditionalNotesSubmit(
                      onAdditionalNotesSubmit
                    )}
                  >
                    <div className="outermain_form">
                      <div className="outerinnerfeild">
                        <Box
                          component="input"
                          {...additionalNotesRegister("notes")}
                          type="text"
                          autoComplete="off"
                          placeholder="Add a note"
                          sx={{
                            border:
                              "1px solid rgba(75, 39, 99, 0.2) !important",
                            minHeight: "100px",
                          }}
                        />
                        {errors?.notes && (
                          <span
                            className="text-danger"
                            style={{ paddingTop: 5 }}
                          >
                            {errors.notes.message}
                          </span>
                        )}
                      </div>
                      <Box display="flex" justifyContent="flex-end">
                        <Box
                          component="button"
                          sx={{
                            marginTop: "20px !important",
                            width: "10% !important",
                            fontSize: "18px !important",
                            borderRadius: "17px !important",
                          }}
                          className="button-s"
                          type="submit"
                        >
                          Submit
                        </Box>
                      </Box>
                    </div>
                  </form>
                </Form>
              </div>
            </div>
          </CustomTabPanel>
        </div>
        <Modal
          className="detailmodal detailbooking"
          show={show2}
          onHide={handleClose2}
          centered
        >
          <Modal.Body>
            <div className="arrowimg">
              <p>Booking Edit</p>
              <div className="downallinputs">
                <div className="dateform">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        "DatePicker",
                        "MobileDatePicker",
                        "DesktopDatePicker",
                        "StaticDatePicker",
                      ]}
                    >
                      <DatePicker defaultValue={dayjs("2022-04-17")} />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="timepicker">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        "TimePicker",
                        "MobileTimePicker",
                        "DesktopTimePicker",
                        "StaticTimePicker",
                      ]}
                    >
                      <TimePicker defaultValue={dayjs("2022-04-17T15:30")} />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="outerinnerfeild">
                  <div className="dropdown">
                    <Link
                      className="btn btn-secondary dropdown-toggle"
                      to="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Change Specialist
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <Link className="dropdown-item" to="#">
                          Action
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Another action
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Something else here
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <button className="oooookkkkk">Save Changes</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="detailmodal detailbooking bookingdetailcancelbutton"
          show={show23}
          onHide={handleClose23}
          centered
        >
          <Modal.Body>
            <div className="arrowimg">
              <h4>
                Cancelling will mark{" "}
                <span className={count > 1 ? "d-n" : ""}>this service </span>
                <span className={count === 1 || count === 0 ? "d-n" : ""}>
                  all selected services
                </span>{" "}
                to 'Cancelled'.
              </h4>
              {/* <h5>Your time is exceeded money will not refunded</h5> */}
              <h5> </h5>
              <button
                className="buttoncancel"
                onClick={() => {
                  cancelBookingLineItems();
                }}
              >
                Yes, Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="detailmodal detailbooking bookingdetailcancelbutton"
          show={show24}
          onHide={handleClose24}
          centered
        >
          <Modal.Body>
            <div className="arrowimg">
              <h4>
                Accepting{" "}
                <span className={count > 1 ? "d-n" : ""}>this booking</span>
                <span className={count === 1 || count === 0 ? "d-n" : ""}>
                  these bookings
                </span>{" "}
                will mark services as 'Accepted'.
              </h4>
              {/* <h5>Booking is successfully accepted
                                and added to calendar</h5> */}
              <h5> </h5>
              <button
                className="buttoncancel acceptbutton"
                onClick={() => {
                  acceptBookinglineItems();
                }}
              >
                Accept
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <FooterMain />
    </>
  );
};

export default BookDetail;
