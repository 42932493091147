import React, { useState, useEffect } from "react";
import "./booking.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import TableBooking from "./bookingtable";
import { getBookings } from "../../api/apiListingService";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

const BookList = () => {
  const [bookings, setBookingList] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [pageStatus, setStatus] = useState<any>("all");
  const [pageCount, setPageCount] = useState(10);

  const getBookingsData = async (status: any, pageList: any) => {
    try {
      //setBookingList([]);
      setStatus(status);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        const resp = await getBookings(status, pageList, data?.access_token);
        if (resp.bookings.length > 0) {
          resp.bookings = [...resp.bookings].sort((a, b) => b.id - a.id);
          resp.bookings.map((b: any) => (b.checked = false));
          resp.bookings.name = data?.name;
          resp.bookings.userType = data?.userType;
          resp.bookings.status = status;
          setPageCount(resp.totalPages);
          setBookingList(resp);
        } else {
          toast.warn("Bookings Not Found", {
            autoClose: 5000,
          });
          resp.bookings.userType = data?.userType;
          resp.bookings.status = status;
          setBookingList(resp);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBookingsData("all", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBookingData = () => {
    getBookingsData(pageStatus, page + 1);
  };

  const handlePageChange = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
    getBookingsData(pageStatus, e.selected + 1);
  };

  return (
    <>
      <NavbarMain />
      <FilterNavbarMain />
      <section className="bookingmain bookingcheckbox">
        <div className="container-main-self">
          <div className="texthere">
            <h3>Booking List</h3>
          </div>
          <div className="lllpppllpp">
            <ul className="nav nav-tabs " id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Booking-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Booking"
                  type="button"
                  role="tab"
                  aria-controls="Booking"
                  aria-selected="true"
                  onClick={() => {
                    setPage(0);
                    getBookingsData("all", 1);
                  }}
                >
                  All Booking
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Pending-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Pending"
                  type="button"
                  role="tab"
                  aria-controls="Pending"
                  aria-selected="false"
                  onClick={() => {
                    setPage(0);
                    getBookingsData("pending", 1);
                  }}
                >
                  Pending
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Accepted-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Accepted"
                  type="button"
                  role="tab"
                  aria-controls="Accepted"
                  aria-selected="false"
                  onClick={() => {
                    setPage(0);
                    getBookingsData("accepted", 1);
                  }}
                >
                  Accepted
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Cancelled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Cancelled"
                  type="button"
                  role="tab"
                  aria-controls="Cancelled"
                  aria-selected="false"
                  onClick={() => {
                    setPage(0);
                    getBookingsData("cancelled", 1);
                  }}
                >
                  Cancelled
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Completed-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Completed"
                  type="button"
                  role="tab"
                  aria-controls="Completed"
                  aria-selected="false"
                  onClick={() => {
                    setPage(0);
                    getBookingsData("completed", 1);
                  }}
                >
                  Completed
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content tabsbookingliust" id="myTabContent">
            <div
              className="tab-pane fade show active p-r"
              id="Booking"
              role="tabpanel"
              aria-labelledby="Booking-tab"
            >
              <TableBooking
                bookings={bookings.bookings}
                fetchBookingData={fetchBookingData}
              />
            </div>
            <div
              className="tab-pane fade p-r"
              id="Pending"
              role="tabpanel"
              aria-labelledby="Pending-tab"
            >
              <TableBooking
                bookings={bookings.bookings}
                fetchBookingData={fetchBookingData}
              />
            </div>
            <div
              className="tab-pane fade p-r"
              id="Accepted"
              role="tabpanel"
              aria-labelledby="Accepted-tab"
            >
              <TableBooking
                bookings={bookings.bookings}
                fetchBookingData={fetchBookingData}
              />
            </div>
            <div
              className="tab-pane fade p-r"
              id="Cancelled"
              role="tabpanel"
              aria-labelledby="Cancelled-tab"
            >
              <TableBooking
                bookings={bookings.bookings}
                fetchBookingData={fetchBookingData}
              />
            </div>
            <div
              className="tab-pane fade p-r"
              id="Completed"
              role="tabpanel"
              aria-labelledby="Completed-tab"
            >
              <TableBooking
                bookings={bookings.bookings}
                fetchBookingData={fetchBookingData}
              />
            </div>
          </div>
          {bookings.totalPages !== 0 ? (
            <ReactPaginate
              previousLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M10 13L5 8L10 3"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              nextLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M5 13L10 8L5 3"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={page}
            ></ReactPaginate>
          ) : (
            <></>
          )}
        </div>
      </section>
      <FooterMain />
    </>
  );
};

export default BookList;
