// apiListingService.ts
import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL,
});

// API requests for authenticate user
export const authenticateUserEmail = async (data: any) => {
  try {
    const response = await api.post("auth/login", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for verify user
export const verifyUserEmail = async (data: any) => {
  try {
    const response = await api.post("auth/verify", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for check user
export const checkUserEmail = async (data: any) => {
  try {
    const response = await api.post("auth/user", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for register user
export const registerUser = async (data: any) => {
  try {
    const response = await api.post("auth/register-customer", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for Validate gift card number
export const validateGiftCard = async (giftCardNumber: any) => {
  try {
    const response = await api.get(`merchants/giftcard/${giftCardNumber}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for fetching user profile
export const fetchUserProfile = async (accessToken: string) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get("auth/profile", reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for updating user profile
export const updateUserProfile = async (accessToken: string, data: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.put("auth/profile", data, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for setting user password
export const setUserPasswordReq = async (accessToken: string, data: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.post("auth/password", data, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};
