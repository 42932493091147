import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const CardPaymentForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const handlePaymentSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    const clientSecret = props?.response?.client_secret;

    let cardE: any = elements?.getElement(CardElement);
    // Confirm the card payment on the client side
    const result = await stripe?.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardE || undefined,
      },
    });

    if (result?.error) {
      toast.warn(result.error.message, {
        autoClose: 5000,
      });
    } else if (result?.paymentIntent?.status === "succeeded") {
      // toast.success("Booking Created Successfully & Payment succeeded", {
      //   autoClose: 5000,
      // });
      // setCartItemsList([]);
      // history.push("/");
      props?.openBookingModal && props?.openBookingModal();
    }
    setLoading(false);
  };

  return (
    <div className="payment-form">
      <form className="f-width" onSubmit={handlePaymentSubmit}>
        <label>
          Card details
          <CardElement
            className="card-stripe"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                  padding: "20px 0px",
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </label>
        <button
          type="submit"
          className="stripe-btn"
          disabled={!stripe || loading}
        >
          {loading ? "Processing..." : "Pay Now"}
        </button>
      </form>
    </div>
  );
};

export default CardPaymentForm;
