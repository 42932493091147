import { Box } from "@mui/material";

const MobileMerchantPage = () => {
  const clickfunctionapple = () => {
    window.location.href =
      "https://apps.apple.com/ua/app/book-better-merchants/id6476569647";
  };

  const clickfunctionandriod = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.bookbettermerchant.ioo&hl=en&pli=1";
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // gap: "20px",
      }}
    >
      <Box
        component="img"
        src="/assets/dummy/mobile-merchant-bg.png"
        alt="BookBetter Merchant BG"
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <h3
          style={{
            color: "#CB6452",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Download Our Mobile
          <br />
          App for a Better Experience
        </h3>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <button onClick={clickfunctionandriod} style={{ padding: 0 }}>
            <img src="\assets\dummy\merchant_google_play.svg" alt="img" />
          </button>
          <button onClick={clickfunctionapple} style={{ padding: 0 }}>
            <img src="\assets\dummy\merchant_app_store.svg" alt="img" />
          </button>
        </Box>
      </Box>
      <Box
        component="img"
        loading="lazy"
        src="/assets/dummy/merchant_mobile_img.svg"
        sx={{
          width: "100%",
          height: "auto",

          "@media (max-width:330px)": {
            width: "293.365px",
            height: "311.824px",
          },
        }}
      />
    </Box>
  );
};

export default MobileMerchantPage;
