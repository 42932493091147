import { atom } from 'recoil';
import { persistAtom, persistAtomLocalStorage } from '../recoil-persist';


export const tourGuideState = atom({
  key: 'showTourGuide',
  default: true,
  effects_UNSTABLE: [persistAtomLocalStorage],
});

export const tourGuideStatusState = atom({
  key: 'tourGuideStatus',
  default: "",
  effects_UNSTABLE: [persistAtomLocalStorage],
});

export const tourGuideStepState = atom({
  key: 'tourStep',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});