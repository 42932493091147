import { atom } from 'recoil';
import { persistAtom } from '../recoil-persist';

export const cartState = atom({
  key: 'cartItemsList',
  default: [], // Initial cart items
  effects_UNSTABLE: [persistAtom],
});

export const bookingState = atom({
  key: 'bookingId',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
