import React from 'react'
import './storeall.scss';
import NavbarMain from '../../Components/NavbarMain';
import FilterNavbarMain from '../../Components/FilterNavbar';
import FooterMain from '../../Components/Footer';
// import Storecard from '../../Components/StoreCardHome';
import { Link } from 'react-router-dom';
const StoreAll = () => {
    return (
        <>
            <NavbarMain />
            <FilterNavbarMain />
            <section className='storemain'>
                <div className='container-main-self'>
                    <div className='texthere'>
                        <h3>Store List</h3>
                    </div>
                    <div className='row p-0'>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <Link to="/storedetail" className='kkkkkkkkk'>
                                <div className='outer_main'>
                                    <div className='leftsideee'>
                                        <img src="\assets\allstore\img1.png" alt="cnctwltimg" className="storeimageleft" />
                                    </div>
                                    <div className='rightsideeee'>
                                        <div className='outermain'>
                                            <div className='rightside'>
                                                <div className='outermainright'>
                                                    <div className='beatury-outer'>
                                                        <h4>Beauty Shop</h4>
                                                        <h5>Open Now</h5>
                                                    </div>
                                                    <div className='upperright'>
                                                        <div className="adasdasa">
                                                            <div className="ratings">
                                                                <i className="fa fa-star rating-color"></i>
                                                            </div>
                                                        </div>
                                                        <div className='text'>
                                                            <h4>4.7</h4>
                                                            <h5>(4.2k)</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>Sophisticated Salon</h3>
                                                <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img2.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img3.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img4.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img5.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img1.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img1.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img2.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img3.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img4.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img5.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='outer_main'>
                                <div className='leftsideee'>
                                    <img src="\assets\allstore\img1.png" alt="cnctwltimg" className="storeimageleft" />
                                </div>
                                <div className='rightsideeee'>
                                    <div className='outermain'>
                                        <div className='rightside'>
                                            <div className='outermainright'>
                                                <div className='beatury-outer'>
                                                    <h4>Beauty Shop</h4>
                                                    <h5>Open Now</h5>
                                                </div>
                                                <div className='upperright'>
                                                    <div className="adasdasa">
                                                        <div className="ratings">
                                                            <i className="fa fa-star rating-color"></i>
                                                        </div>
                                                    </div>
                                                    <div className='text'>
                                                        <h4>4.7</h4>
                                                        <h5>(4.2k)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Sophisticated Salon</h3>
                                            <p>2807 Sherwood Circle, Kamlamda, Canada 3.....</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterMain />
        </>
    )
}

export default StoreAll