import React from "react";
// import Footer from "../landing/footer/Footer";
// import Navbar from "../landing/header/Navbar";
import "./terms.scss";
import { Link } from "react-router-dom";
// import NavbarMain from "../../Components/NavbarMain";
// import FooterMain from "../../Components/Footer";

const Terms = () => {
  return (
    <>
      <div className="main-presspage">
        {/* <NavbarMain /> */}
        <section className="main-cloud">
          <div className="container-main-self">
            <div className="row">
              <div className="col-xl-12 col-12 m-auto p-0">
                <div className="row">
                  <div className="col-xl-12 col-12 m-auto p-0">
                    <div className="banner_nav">
                      <div className="row">
                        <div className="col-xl-12 col-12 m-auto p-0">
                          <Link to="/" className="sdsddsx">
                            <button type="button" className="btnnav">
                              <p className="btn-tag">Back</p>
                            </button>
                          </Link>
                          <h1 className="mainheading">Book Better Privacy & Policy</h1>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-12 m-auto p-0">
                        <div className="secpara">
                          <h6 className="paraheading">Privacy Policy for Book Better</h6>
                          <p className="secparaa">
                            At Book Better, we value your privacy and are committed to protecting your
                            personal information. This Privacy Policy explains how we collect, use, and
                            disclose your information when you use our website and related services. By
                            accessing or using the Book Better website, you agree to the terms of this
                            Privacy Policy.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">1. Information We Collect</h6>
                          <h6 className="thrrddppaarra">
                            1.1 Information You Provide:{" "}
                          </h6>
                          <p className="secparaa">
                            When you sign up for an account on Book Better as a customer or merchant, we
                            collect personal information such as your name, email address, contact number,
                            and other details necessary for providing our services. Merchants may provide
                            additional business-related information, including store details and services
                            offered.
                          </p>
                          <h6 className="thrrddppaarra">
                            1.2 Booking Information:{" "}
                          </h6>
                          <p className="secparaa">

                            When you book an appointment as a customer, we collect information related to
                            your appointments, including the date, time, services requested, and any
                            specific preferences or requirements.
                          </p>
                          <h6 className="thrrddppaarra">
                            1.3 Payment Information: {" "}
                          </h6>
                          <p className="secparaa">
                            If you choose to make payments for bookings on Book
                            Better, we collect and store payment details, such as credit card information or
                            other payment methods, to facilitate transactions securely. We do not store
                            complete payment information on our servers but use secure third-party payment
                            processors to handle these transactions.
                          </p>
                          <h6 className="thrrddppaarra">
                            1.4 Google Calendar Integration: {" "}
                          </h6>
                          <p className="secparaa">
                            Book Better offers a convenient feature that
                            allows you to sync your appointments with Google Calendar, enhancing your
                            appointment management experience. To enable this feature, you need to authorize
                            your Google account on Google Calendar, granting access to synchronize your
                            appointments. By doing so, you understand and acknowledge that Google Calendar
                            will collect and store information, including event details and attendee lists,
                            solely for the purpose of synchronizing your appointments. For additional
                            information, please visit <a
                              href='https://developers.google.com/terms/api-services-user-data-policy'>Google
                              API Services User Data Policy.
                            </a>
                          </p>
                          <h6 className="thrrddppaarra">
                            1.5 Log Data and Cookies:{" "}
                          </h6>
                          <p className="secparaa">
                            Like many websites, we collect certain information
                            automatically when you visit our website. This may include your IP address,
                            browser type, device information, pages visited, and other usage data. We may
                            also use cookies and similar tracking technologies to enhance your experience
                            and provide personalized features. You can modify your browser settings to
                            manage cookie preferences, but please note that disabling cookies may affect
                            certain functionality of the website.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">2.Use of Informatio</h6>
                          <h6 className="thrrddppaarra">
                            2.1 Provide and Improve Services:{" "}
                          </h6>
                          <p className="secparaa">
                            We use the information collected to provide
                            and improve our services, including managing appointments, facilitating
                            communication between customers and merchants, and enhancing the overall user
                            experience.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.2 Communication: {" "}
                          </h6>
                          <p className="secparaa">
                            We may use your email address or phone number to send you
                            service-related updates, confirmations, and notifications about your bookings,
                            as well as to respond to your inquiries and provide customer support.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.3 Google Calendar Integration: {" "}
                          </h6>
                          <p className="secparaa">
                            If you choose to integrate Book Better with your
                            Google Calendar, we use the collected information to synchronize your
                            appointments and bookings between Book Better and your Google Calendar. This
                            integration allows you to conveniently manage your schedule and receive
                            reminders for your appointments.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.4 Marketing and Promotions: {" "}
                          </h6>
                          <p className="secparaa">
                            With your consent, we may send you promotional
                            materials, special offers, and updates about Book Better and our partners. You
                            can opt-out of receiving such communications at any time.
                          </p>
                          <h6 className="thrrddppaarra">
                            2.5 Aggregated Data: {" "}
                          </h6>
                          <p className="secparaa">
                            We may anonymize and aggregate data for statistical analysis
                            and reporting purposes. This aggregated data does not personally identify you
                            and may be used for marketing, research, or other business purposes.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">3. Sharing of Information</h6>
                          <h6 className="thrrddppaarra">
                            3.1 With Merchants:{" "}
                          </h6>
                          <p className="secparaa">
                            As a customer, when you book an appointment, the relevant
                            information will be shared with the respective merchant to facilitate the
                            appointment and service delivery. Merchants can access and manage their
                            customers&#8217; booking information and communicate with customers through our
                            platform.
                          </p>
                          <h6 className="thrrddppaarra">
                            3.2 Service Providers: {" "}
                          </h6>
                          <p className="secparaa">
                            We may engage trusted third-party service providers to
                            assist us in providing and improving our services. These service providers may
                            have access to your information but are contractually obligated to maintain its
                            confidentiality and use it solely for the purpose of providing the requested
                            services.
                          </p>
                          <h6 className="thrrddppaarra">
                            3.3 Google Calendar Integration: {" "}
                          </h6>
                          <p className="secparaa">
                            In order to provide the Google Calendar
                            integration feature, we may share the necessary information from your Book
                            Better account with Google Calendar. This includes appointment details, such as
                            event titles, dates, times, descriptions, and attendee lists. This information
                            is shared securely and solely for the purpose of synchronizing your appointments
                            between Book Better and your Google Calendar. We do not share any other personal
                            or sensitive information with Google Calendar.
                          </p>
                          <h6 className="thrrddppaarra">
                            3.4 Legal Requirements: {" "}
                          </h6>
                          <p className="secparaa">
                            We may disclose your information to comply with
                            applicable laws, regulations, legal processes, or enforceable governmental
                            requests. We may also share information when necessary to protect our rights,
                            privacy, safety, or property, as well as that of our users, merchants, or the
                            public.
                          </p>
                          <h6 className="thrrddppaarra">
                            3.5 Business Transfers: {" "}
                          </h6>
                          <p className="secparaa">
                            In the event of a merger, acquisition, or sale of all or
                            a portion of our assets, your information may be transferred as part of the
                            transaction. We will ensure that any successor entity or acquirer agrees to
                            comply with the terms of this Privacy Policy.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">4. Security of Information</h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            We employ reasonable security measures to protect your personal information from
                            unauthorized access, disclosure, alteration, or destruction. However, no method
                            of transmission over the internet or electronic storage is completely secure,
                            and we cannot guarantee absolute security.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">5. Your Choices and Rights</h6>
                          <h6 className="thrrddppaarra">
                            5.1 Account Information:{" "}
                          </h6>
                          <p className="secparaa">
                            As a registered user, you have the ability to review,
                            update, and correct your account information by accessing your profile settings.
                          </p>
                          <h6 className="thrrddppaarra">
                            5.2 Communication Preferences:{" "}
                          </h6>
                          <p className="secparaa">
                            You can manage your communication preferences by
                            adjusting the settings in your account or by following the unsubscribe
                            instructions provided in the promotional emails you receive.
                          </p>
                          <h6 className="thrrddppaarra">
                            5.3 Accessing and Deleting Data:{" "}
                          </h6>
                          <p className="secparaa">
                            You have the right to access the personal
                            information we hold about you. If you wish to exercise this right or request the
                            deletion of your data, please contact us using the contact details provided at
                            the end of this Privacy Policy.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">6. Changes to this Privacy Policy</h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            We may update this Privacy Policy from time to time, to reflect changes in our
                            practices or applicable laws. The revised Privacy Policy will be effective from
                            the date it is published on our website. We encourage you to review this Privacy
                            Policy periodically for any updates.
                          </p>
                        </div>
                        <div className="secpara">
                          <h6 className="paraheading">7.Contact Us</h6>
                          <p className="ppppppaaaaaaaarrrraaaaaaa">
                            If you have any questions, concerns, or requests regarding this Privacy Policy or
                            our data practices, please contact us at amber.naqvi@nimbusconsulting.tech. We
                            will respond to your inquiries in a timely manner.
                            Thank you for trusting Book Better with your personal information.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <FooterMain /> */}
    </>
  );
};

export default Terms;
