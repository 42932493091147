// apiListingService.ts
import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL,
});

// API requests for get services
export const getServiceData = async (date: any, accessToken: string) => {
  try {
    let reqHeaders: any = {
      headers: {
        platform: `web`,
        // Other headers if needed
      },
    };
    if (accessToken) {
      reqHeaders.headers.Authorization = `Bearer ${accessToken}`;
    }
    const response = await api.post("dashboard", date, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get services
export const getAllCategories = async () => {
  try {
    const response = await api.get("services/categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get speicialists
export const getSpecialistsData = async (reqBody: any) => {
  try {
    let reqHeaders = {
      headers: {
        platform: `web`,
        // Other headers if needed
      },
    };
    const response = await api.post(
      "dashboard/specialists",
      reqBody,
      reqHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get speicialists details and available slots
export const getSpecialistsDetails = async (
  id: any,
  date: string,
  line_id?: number
) => {
  let URI = `specialists?ssEmpId=${id}&date=${date}`;
  if (line_id) {
    URI += `&line_id=${line_id}`;
  }
  try {
    const response = await api.get(URI);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get merchant details
export const getMerchantDetails = async (
  merchantName: any,
  date: any,
  accessToken: string
) => {
  try {
    const reqBody = {
      date: date,
    };
    let reqHeaders: any = {
      headers: {
        platform: `web`,
      },
    };
    if (accessToken) {
      reqHeaders.headers.Authorization = `Bearer ${accessToken}`;
    }
    const response = await api.post(
      `/merchants/${merchantName}`,
      reqBody,
      reqHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for create booking
export const createBooking = async (req: any, accessToken: string) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        platform: `web`,
        // Other headers if needed
      },
    };
    const response = await api.post(`/bookings`, req, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API request for cancel booking request
export const cancelBooking = async (bookingId: any, accessToken: string) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        platform: `web`,
        // Other headers if needed
      },
    };
    const response = await api.delete(`/bookings/${bookingId}`, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get booking
export const getBookings = async (
  status: any,
  page: string,
  accessToken: any
) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get(
      `/bookings?status=${status}&page=${page}`,
      reqHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get booking details
export const getBookingsDetails = async (bookingId: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get(`/bookings/${bookingId}`, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get gift cards
export const getGiftCards = async (
  status: any,
  page: string,
  accessToken: any
) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get(
      `/merchants/giftcard/?status=${status}&page=${page}`,
      reqHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get all specialists
export const getAllSpecialists = async (accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get(`/specialists/calendar`, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get all services
export const getAllServices = async (accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get(`/merchants/services`, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get calendar data
export const getCalendarData = async (body: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.post(`/bookings/calendar`, body, reqHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for cronofy
export const cronofyRequest = async (accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.get(`/cronofy/auth`, reqHeaders);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Booking Accept request
export const acceptMutipleBookings = async (body: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.patch(
      `/bookings/accept/multiple`,
      body,
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Booking cancel request
export const cancelMutipleBookings = async (body: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.patch(
      `/bookings/cancel/multiple`,
      body,
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Booking cancel line items request
export const cancelMutipleLineItems = async (body: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.patch(
      `/bookings/detail/multiple/cancel`,
      body,
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Booking accept line items request
export const acceptMutipleLineItems = async (body: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.patch(
      `/bookings/detail/multiple/accept`,
      body,
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Booking accept single line items request
export const acceptSingleLineItems = async (
  lineId: any,
  bId: any,
  accessToken: any
) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.patch(
      `/bookings/detail/accept?bookingId=${bId}&bookingDetailId=${lineId}`,
      {},
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Booking accept single line items request
export const cancelSingleLineItems = async (
  lineId: any,
  bId: any,
  accessToken: any
) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.patch(
      `/bookings/detail/cancel?bookingId=${bId}&bookingDetailId=${lineId}`,
      {},
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Reschedule a service
export const rescheduleService = async (body: any, accessToken: any) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.put(`/bookings`, body, reqHeaders);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Add additional note
export const addAdditionalNote = async (
  bookingId: string,
  body: any,
  accessToken: any
) => {
  try {
    let reqHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        isMerchantApplication: false,
      },
    };
    const response = await api.put(
      `bookings/notes/${bookingId}`,
      body,
      reqHeaders
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// API requests for get speicialists
export const getGroupedSpecialistList = async (
  serviceId: number,
  reqBody: any
) => {
  try {
    let reqHeaders = {
      headers: {
        platform: `web`,
        // Other headers if needed
      },
    };
    const response = await api.post(
      `dashboard/group/${serviceId}`,
      reqBody,
      reqHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API requests for get speicialists
export const getGroupedSpecialistDetails = async (
  serviceId: number,
  employeeId: number,
  reqBody: any
) => {
  try {
    let reqHeaders = {
      headers: {
        platform: `web`,
        // Other headers if needed
      },
    };
    const response = await api.post(
      `bookings/group-service?serviceId=${serviceId}&employeeId=${employeeId}`,
      reqBody,
      reqHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
