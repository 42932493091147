import React from 'react'
import './account.scss';
const Verification = () => {
    return (
        <section className="Verification samelogin">
        
        </section>
    )
}

export default Verification