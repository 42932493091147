import React from "react";
import "./storedetail.scss";
// import NavbarMain from '../../Components/NavbarMain';
// import FilterNavbarMain from '../../Components/FilterNavbar';
// import FooterMain from '../../Components/Footer';
// import { Link } from 'react-router-dom';
// import Gallery from './Gallery';
// import StoreDetailSmoke from './StoredetailSmoke';
// import ServiceSlider from './ServicesSlider';
// import { getMerchantDetails } from '../../api/apiListingService';
// import SpeciallistStore from './Specialliststore';

const AboutStore = () => {
  return (
    <>
      <div className="storedetailpagefor">
        {/* <NavbarMain />
            <FilterNavbarMain /> */}
        <section className="StoreDetail">
          <div className="container-main-self">
            <div className="aboutsmoke">
              <div className="main-upper">
                <h3>About SmokeShow Studios</h3>
              </div>
              <div className="outermainabout">
                <div className="left-side-about">
                  <img
                    src="\assets\storeimage\logo.jpg"
                    alt="cnctwltimg"
                    className="storeimageleft"
                  />
                  <div className="text">
                    <div className="kokokokoko">
                      <h5>SmokeShow Studios</h5>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/6G2V8c44yCuZsqKq8?g_st=ic"
                      >
                        <img
                          src="\assets\imag\clarity_map-solid.svg"
                          alt="cnctwltimg"
                          className="assdasdasasdasd"
                        />
                      </a>
                    </div>
                    <h6>15026 Glazier Avenue, Apple Valley, MN, USA</h6>
                  </div>
                </div>
                <div className="right-side-about">
                  {/* <Link to={{ pathname: `https://${merchantDetails.merchantDetails?.merchant_website}` }} target="_blank">{merchantDetails.merchantDetails?.merchant_website}</Link> */}
                </div>
              </div>
              <p>
                SmokeShow studios is a self-care destination. It is a concept
                developed by our founder Sarah Crawford. SmokeShow Studios
                brings a contemporary approach to looking – and feeling – your
                best. You can work up a dynamite sweat, get a blowout, and slap
                on a fresh set of lashes – all in one place. SmokeShow’s
                experienced team are ready to get to know you and help you on
                your beauty journey.
              </p>
              <p className="mt-0">
                SmokeShow offers everything from day-of, off site wedding hair
                and makeup to routine personal training sessions to giving you a
                pre-tinder date 90s blowout. Whatever you need to feel like a
                smoke show, we got you.
              </p>
              <p className="mt-2">
                SmokeShow offers everything from day-of, off site wedding hair
                and makeup to routine personal training sessions to giving you a
                pre-tinder date 90s blowout.
              </p>
              <p className="mt-2">
                Whatever you need to feel like a smoke show, we got you.
              </p>
              {/* <div className='outermainnewone'>
                            <div className='clockss'>
                                <img src="\assets\prof\new11\uil_calender.svg" alt="cnctwltimg" className="left" />
                                <h6>Joined in March 2016</h6>
                            </div>
                            <div className='clockss'>
                                <img src="\assets\prof\new11\tabler_message-2.svg" alt="cnctwltimg" className="left" />
                                <h6>Response rate - 100</h6>
                            </div>
                            <div className='clockss'>
                                <img src="\assets\prof\new11\mdi_clock-outline.svg" alt="cnctwltimg" className="left" />
                                <h6>Fast response - within a few hours</h6>
                            </div>
                        </div> */}
            </div>
          </div>
        </section>
        {/* <FooterMain /> */}
      </div>
    </>
  );
};

export default AboutStore;
