import { toast } from "react-toastify";

export const sumAmount = (cartData: any) => {
  let amount = 0;
  cartData.map((amount: any) => (amount += amount.currentprice));
  return amount;
};

export const formatAmount = (amount: any) => {
  // Check if the input is a valid number
  if (isNaN(amount)) {
    return "";
  }

  // Round the number to two decimal places
  const roundedAmount = Math.round(amount * 100) / 100;

  // Convert the number to a string with two decimal digits after the dot
  const formattedAmount = roundedAmount.toFixed(2);

  return formattedAmount;
};

export const copyToClipboard = async (textToCopy: any) => {
  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Copied to clipboard!");
    } catch (err) {
      toast.error("Browser is not supported!");
    }
  } else {
    document.execCommand("copy", true, textToCopy);
    toast.success("Copied to clipboard!");
  }
};

function capitalizeFirstLetter(sentence: string) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export const capitalizeFirstLetterInSentence = (paragraph: string) => {
  if (!paragraph) {
    return "";
  }
  const sentences = paragraph.split(" ");
  const capitalizedSentences = sentences.map((sentence) =>
    capitalizeFirstLetter(sentence)
  );
  return capitalizedSentences.join(" ");
};

export const parseNotes = (notesString: string) => {
  if (!notesString) {
    return "";
  }

  // Split the notes string into individual notes
  const notesArray = notesString.split("\n");

  // Map through each note to extract sender and note content
  const parsedNotes = notesArray.map((note) => {
    const [sender, content] = note.split(":");
    return {
      sender: sender ? sender.trim() : "",
      content: content ? content.trim() : "",
    };
  });

  return parsedNotes;
};
