import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./storecard.scss";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";
import {
  getGroupedSpecialistList,
  getSpecialistsData,
} from "../api/apiListingService";
import {
  formatTime,
  addServiceTime,
  checkTimeOverlap,
  formatTimeslot,
  adjustStartTime,
  adjustEndTime,
} from "../utils/dateFomater";
import { toast } from "react-toastify";
import { cartState } from "../recoil/atoms/cartAtom";
import { useRecoilState } from "recoil";
import { AddToCartModal } from "./Dialog/AddToCart";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import { formatAmount } from "../utils/generalUtils";
import {
  tourGuideState,
  tourGuideStatusState,
  tourGuideStepState,
} from "../recoil/atoms/tourAtom";
import SlotReservationModal from "./Dialog/SlotReservation";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4.5,
  slidesToScroll: 2,
  ltr: true,
  responsive: [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 361,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4.5,
      },
    },
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 4.5,
      },
    },
  ],
};

const Storecard = (props: any) => {
  const [currentService, setCurrentService] = useState<any>([]);
  const [, setIsShown] = useState<boolean>(false);
  const [specialists, setSpecialistData] = useState<any>([]);
  const [groupServiceSpecialists, setGroupServiceSpecialist] = useState<any>(
    []
  );
  const [currentSpecialist, setCurrentSpecialist] = useState<any>({});
  const [shopId, setShopId] = useState<any>("");
  const [cartItemsList, setCartItemsList] = useRecoilState(cartState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [cartItem, setCartItem] = useState<any>(null);
  const [showTourGuide, setShowTourGuide] = useRecoilState(tourGuideState);
  const [, setTourStep] = useRecoilState(tourGuideStepState);
  const [tourGuideStatus] = useRecoilState(tourGuideStatusState);
  const [reservationData, setReservationData] = useState<any>(null);
  const [showReservationModal, setShowReservationModal] =
    useState<boolean>(false);
  const history = useHistory();

  const fetchServiceSpecialists = async (
    mId: number,
    sId: number,
    sTime: string,
    duration: number,
    serviceName: string
  ) => {
    try {
      const eTime: any = addServiceTime(sTime, duration);
      setSpecialistData([]);
      setGroupServiceSpecialist([]);
      const body = {
        date: props.services?.date,
        start: formatTimeslot(props.services?.date, sTime),
        end: formatTimeslot(props.services?.date, eTime),
        serviceId: sId,
        merchantId: mId,
      };
      setCurrentSpecialist(body);
      // Get services data from api
      const resp = await getSpecialistsData(body);
      if (!resp?.result?.length) {
        toast.error(
          `No specialists are available for the service '${serviceName}' on ${moment(
            props?.services?.date
          ).format("MMM DD, YYYY")} `,
          {
            autoClose: 5000,
          }
        );
      } else {
        const prePrepDuration = resp?.prePrepDuration;
        const postPrepDuration = resp?.postPrepDuration;
        // Check availability
        resp?.result.forEach((el: any) => {
          el.cartStatus = el?.availibility
            ? `Reserve for $${formatAmount(el?.price)}`
            : "Not Available";
          let spCartServices = cartItemsList.filter(
            (arr: any, index: number) =>
              parseInt(arr.employeeId) === parseInt(el.employeeId)
          );
          spCartServices.forEach((element: any) => {
            if (element?.cartReserveStatus === "incart") {
              const newStartTime = adjustStartTime(sTime, prePrepDuration);
              // console.log("NEW START TIME: ", newStartTime);

              const newEndTime: any = addServiceTime(
                sTime,
                duration,
                postPrepDuration
              );
              // console.log("NEW END TIME: ", newEndTime);

              const newExistingStartTime = adjustStartTime(
                element?.start,
                element?.prePrepDuration
              );
              const newExistingEndTime = adjustEndTime(
                element?.end,
                element?.postPrepDuration
              );

              // let endTime: any = addServiceTime(sTime, duration);
              // console.log("END TIME: ", endTime);

              let checkOverlap = checkTimeOverlap(
                newExistingStartTime,
                newExistingEndTime,
                formatTimeslot(props.services?.date, newStartTime),
                formatTimeslot(props.services?.date, newEndTime)
              );
              // console.log("CHECK OVERLAP", checkOverlap);
              if (!checkOverlap) {
                el.availibility = checkOverlap;
                el.cartStatus = checkOverlap
                  ? `Reserve for $${formatAmount(el?.price)}`
                  : "Reserved";
              }
            }
          });
        });
        setSpecialistData(resp.result);
        if (
          !showTourGuide &&
          tourGuideStatus !== "skipped" &&
          tourGuideStatus !== "paused" &&
          resp?.result?.length > 0
        ) {
          setTourStep(3);
          setTimeout(() => {
            setShowTourGuide(true);
          }, 100);
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(
          `No specialists exist for the service '${serviceName}' on ${moment(
            props?.services?.date
          ).format("MMM DD, YYYY")}`,
          {
            autoClose: 5000,
          }
        );
      }
      console.error("Error fetching data:", error);
    }
  };

  const fetchGroupServiceSpecialists = async (
    sId: number,
    serviceName: string
  ) => {
    try {
      setSpecialistData([]);
      setGroupServiceSpecialist([]);
      const body = {
        startTime: moment(props.services?.date)
          .utc()
          .startOf("day")
          .toISOString(),
        endTime: moment(props.services?.date)
          .utc()
          .add(1, "day")
          .startOf("day")
          .toISOString(),
      };
      // Get services data from api
      const resp = await getGroupedSpecialistList(sId, body);
      if (!resp?.res?.length) {
        toast.error(
          `No specialists are available for the service '${serviceName}' on ${moment(
            props?.services?.date
          ).format("MMM DD, YYYY")} `,
          {
            autoClose: 5000,
          }
        );
      } else {
        setGroupServiceSpecialist(resp?.res);
      }
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(
          `No specialists exist for the service '${serviceName}' on ${moment(
            props?.services?.date
          ).format("MMM DD, YYYY")}`,
          {
            autoClose: 5000,
          }
        );
      }
      console.error("Error fetching data:", error);
    }
  };

  // console.log("SPECIALIST DATA", specialists);

  const handleReservationModal = () => setShowReservationModal(false);

  const handleAddToCart = () => {
    setShowReservationModal(false);
    addItemsToBasket(
      reservationData?.shop,
      reservationData?.sp,
      reservationData?.bookingDate,
      reservationData?.cartItemStatus
    );
  };

  const addItemsToBasket = (
    shop: any,
    sp: any,
    bookingDate: any,
    cartItemStatus: any
  ) => {
    const tip = { tip: 0 };
    const bDate = { bookingDate: new Date(bookingDate) };
    const cartReserveStatus = { cartReserveStatus: cartItemStatus };
    let priceObj = {
      currentprice: "",
    };
    if (sp?.price) {
      priceObj.currentprice = formatAmount(sp?.price);
    }
    const mergedObject = Object.assign(
      {},
      shop,
      sp,
      currentService,
      currentSpecialist,
      tip,
      bDate,
      cartReserveStatus,
      priceObj
    );
    if (
      cartItemsList.length === 0 ||
      cartItemsList.every(
        (item: any) => item.merchantId === currentSpecialist.merchantId
      )
    ) {
      setCartItemsList((prv: any) => {
        return [...prv, mergedObject];
      });
      setSpecialistData([]);
      if (cartItemStatus === "incart") {
        toast.success("Service added to the basket", {
          autoClose: 5000,
        });
      }
    } else {
      setCartItem(mergedObject);
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const addGiftCard = (merchantId: any, giftCard: any) => {
    giftCard = {
      ...giftCard,
      merchantId: merchantId,
    };
    if (
      cartItemsList.length === 0 ||
      cartItemsList.every((item: any) => item.merchantId === merchantId)
    ) {
      setCartItemsList((prv: any) => {
        return [...prv, giftCard];
      });

      toast.success("Gift Card added to the basket", {
        autoClose: 5000,
      });
    } else {
      setCartItem(giftCard);
      setShowModal(true);
    }
  };

  const handleResetAndNewItems = () => {
    if (cartItem) {
      if (
        cartItem?.serviceType === "Giftcard" ||
        cartItem?.servicetype === "Giftcard"
      ) {
        setCartItemsList([cartItem]);
        handleClose();
        toast.success("Gift Card added to the basket", {
          autoClose: 5000,
        });
      } else {
        setCartItemsList([cartItem]);
        setSpecialistData([]);
        handleClose();
        toast.success("Service added to the basket", {
          autoClose: 5000,
        });
      }
    }
  };

  const divRef = useRef<HTMLDivElement>(null);
  const [, setIsClickedOutside] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const overlayElement = document.body?.getElementsByClassName(
        "react-joyride__overlay"
      )?.[0];
      if (!overlayElement) {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
          setIsClickedOutside(true);
          setIsShown(false);
          setSpecialistData([]);
        } else {
          setIsClickedOutside(false);
          setSpecialistData([]);
        }
      }
    };

    const handleDocumentClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("SERVICES", props.services);

  // console.log("SPECIALISTS: ", specialists);

  return (
    <>
      <div>
        {props?.services?.length > 0
          ? props?.services?.map((shop: any, index: number) => (
              <section key={index} className={`FrontMain_Stores slideshareaa`}>
                <div className={`container-main-self store-${index}`}>
                  <div className="main_upper_bar">
                    <div className="leftside">
                      <div className="anme">
                        <Link
                          to={`/merchants/${shop?.endpoint_url}`}
                          className="t-d-n"
                        >
                          <h4>{shop?.merchant_name}</h4>
                        </Link>
                        <img
                          src="\assets\newm\mdi_information-outline.svg"
                          alt="cnctwltimg"
                          className="cnctwltimg"
                        />
                      </div>
                      <div className="beatury-outer">
                        <h4>{shop?.specialization}</h4>
                        {/* {shop.isOpen ? (
                                        <h5>Open</h5>
                                    ) : (
                                        <span> </span>
                                    )} */}
                      </div>
                    </div>
                    <div className="rightside">
                      <Link to={`/merchants/${shop?.endpoint_url}`}>
                        View All
                      </Link>
                    </div>
                  </div>
                  <div className="slidershareoutsideclick" ref={divRef}>
                    <div className="sliderhere">
                      <Slider {...settings}>
                        {shop?.services?.map((service: any, index: number) => (
                          <div className="outer" key={index}>
                            <div className="cardmain">
                              <div className="uppercard">
                                <img
                                  src={
                                    service?.coverphoto
                                      ? service?.coverphoto
                                      : "/assets/storeimage/placeholder-img.png"
                                  }
                                  alt="cnctwltimg"
                                  className="cnctwltimg"
                                />
                                <div className="rectangle_shape">
                                  <h4>{service?.servicename}</h4>
                                </div>
                              </div>
                              <div className="lowercard">
                                <div className="upperheading">
                                  <div className="rirr">
                                    {service?.servicetype === "Giftcard" ? (
                                      <h5>Gift Card Value</h5>
                                    ) : (
                                      <h5>{service?.serviceDuration} mins</h5>
                                    )}
                                  </div>
                                  <div
                                    className="upperheadings"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    {service?.servicetype !== "Giftcard" && (
                                      <span>Starts at</span>
                                    )}
                                    <h3 style={{ lineHeight: "normal" }}>
                                      ${service?.currentprice}
                                    </h3>
                                  </div>
                                </div>
                                {service?.servicetype === "Giftcard" ? (
                                  <div className={`slotshere slot-${index}`}>
                                    <div className="addtocart">
                                      <button
                                        onClick={() => {
                                          addGiftCard(shop?.id, service);
                                        }}
                                      >
                                        Add to basket
                                      </button>
                                    </div>
                                  </div>
                                ) : service?.servicetype === "Group" ? (
                                  <div className={`slotshere slot-${index}`}>
                                    <div className="addtocart">
                                      <button
                                        onClick={() => {
                                          setShopId(shop?.id);
                                          fetchGroupServiceSpecialists(
                                            service?.id,
                                            service?.servicename
                                          );
                                        }}
                                      >
                                        View Group Session Details
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <Box
                                    className={`slotshere slot-${index}`}
                                    justifyContent={
                                      shop?.slots?.length > 2
                                        ? "space-between"
                                        : "flex-start!important"
                                    }
                                  >
                                    {shop?.slots?.map(
                                      (sl: any, index: number) => (
                                        <div
                                          key={index}
                                          onClick={() => {
                                            setIsShown(true);
                                            setShopId(shop?.id);
                                            fetchServiceSpecialists(
                                              shop?.id,
                                              service?.id,
                                              sl,
                                              service?.serviceDuration,
                                              service?.servicename
                                            );
                                            setCurrentService(service);
                                          }}
                                          className="design"
                                        >
                                          <h5>{formatTime(sl)}</h5>
                                        </div>
                                      )
                                    )}
                                  </Box>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                    <div
                      className={
                        shopId === shop?.id && specialists?.length > 0
                          ? "caresousaldownbaloon show"
                          : "caresousaldownbaloon close"
                      }
                    >
                      {specialists?.map((sp: any, index: number) => (
                        <div
                          className={`innerprofile specialist-${index}`}
                          key={index}
                          style={{ width: "auto" }}
                        >
                          <div className="upperprofile">
                            <Link
                              to={{
                                pathname: `/speciallist/detail/${sp?.ssEmpId}`,
                                state: {
                                  params: {
                                    merchantId: shop?.id,
                                  },
                                },
                              }}
                            >
                              <img
                                src="\assets\allstore\Group 30735.svg"
                                alt="cnctwltimg"
                                className="calenderimg"
                              />
                            </Link>
                            <img
                              src={
                                sp?.coverPhoto
                                  ? sp?.coverPhoto
                                  : "/assets/storeimage/placeholder-img.png"
                              }
                              alt="cnctwltimg"
                              className="profileimg"
                            />
                            {/* <div className='rightsidem'>
                                    <div className='upperright'>
                                        <div className="adasdasa">
                                            <div className="ratings">
                                                <i className="fa fa-star rating-color"></i>
                                            </div>
                                        </div>
                                        <div className='text'>
                                            <h4>4.7</h4>
                                        </div>
                                    </div>
                                </div> */}
                          </div>
                          <div className="lowerside">
                            <div
                              className="maintool"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <h4>{sp?.nickName}</h4>
                              <span className="tooltiptext">
                                {sp?.nickName}
                              </span>
                            </div>

                            <div className="outmainh5">
                              <h5>Location</h5>
                              <div className="mainouterlocation">
                                <img
                                  src="\assets\allstore\carbon_location.svg"
                                  alt="cnctwltimg"
                                  className="calenderimg"
                                />
                                <div className="text">
                                  <p>{sp?.location}</p>
                                  <h6>{shop?.merchant_name}</h6>
                                </div>
                              </div>
                            </div>
                            {/* <div>
                              <span className="badge bg-secondary">
                                {sp?.price
                                  ? `$ ${formatAmount(sp?.price)}`
                                  : ""}
                              </span>
                            </div> */}
                            <button
                              // className={!sp?.availibility ? "incart" : ""}
                              className={`reserveBtn ${
                                sp?.cartStatus === "Reserved"
                                  ? "incart"
                                  : sp?.cartStatus === "Not Available"
                                  ? "unavailable"
                                  : ""
                              }
                              `}
                              style={{ width: "138px" }}
                              onClick={() => {
                                setReservationData({
                                  shop,
                                  sp,
                                  bookingDate: props.services?.date,
                                  cartItemStatus: "incart",
                                });
                                setShowReservationModal(true);
                              }}
                            >
                              {/* {!sp.availibility
                                ? "Reserved"
                                : `Reserve for $${formatAmount(sp?.price)}`} */}
                              {sp?.cartStatus}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      className={
                        shopId === shop?.id &&
                        groupServiceSpecialists?.length > 0
                          ? "caresousaldownbaloon show"
                          : "caresousaldownbaloon close"
                      }
                    >
                      {groupServiceSpecialists?.map(
                        (sp: any, index: number) => (
                          <div
                            className={`innerprofile specialist-${index}`}
                            key={index}
                            style={{ width: "auto" }}
                          >
                            <div className="upperprofile">
                              <img
                                src={
                                  sp?.displayPicture
                                    ? sp?.displayPicture
                                    : "/assets/storeimage/placeholder-img.png"
                                }
                                alt="cnctwltimg"
                                className="profileimg"
                              />
                            </div>
                            <div className="lowerside">
                              <div
                                className="maintool"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <h4>{sp?.employeeName}</h4>
                                <span className="tooltiptext">
                                  {sp?.employeeName}
                                </span>
                              </div>
                              <button
                                className={`reserveBtn`}
                                style={{ width: "138px" }}
                                onClick={() => {
                                  history.push({
                                    pathname: `/groupsession/specialist/${sp?.employeeId}`,
                                    state: {
                                      params: {
                                        serviceId: sp?.serviceId,
                                        merchantId: shop?.id,
                                      },
                                    },
                                  });
                                }}
                              >
                                View Availability
                              </button>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </section>
            ))
          : null}
      </div>
      <AddToCartModal
        showModal={showModal}
        handleClose={handleClose}
        handleResetAndNewItems={handleResetAndNewItems}
      />
      {showReservationModal && reservationData && (
        <SlotReservationModal
          showModal={showReservationModal}
          handleClose={handleReservationModal}
          serviceName={currentService?.servicename}
          specialistName={reservationData?.sp?.nickName}
          timezone={reservationData?.sp?.timezone}
          startDate={currentSpecialist?.start}
          endDate={currentSpecialist?.end}
          bookingDate={reservationData?.bookingDate}
          handleAddToCart={handleAddToCart}
        />
      )}
    </>
  );
};

export default Storecard;
