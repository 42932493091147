import { useState } from "react";
import { Link } from "react-router-dom";
import "./giftcard.scss";
import { Modal } from "react-bootstrap";
import { copyToClipboard, formatAmount } from "../../utils/generalUtils";
import { differenceInDays, parseISO } from "date-fns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box } from "@mui/material";

const GiftCardtable = (props: any) => {
  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);

  return (
    <>
      {props?.loading === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Booking ID</th>
              <th>Price</th>
              <th>Status</th>
              <th>Gift Code</th>
              <th>Expiry</th>
            </tr>
          </thead>
          <tbody>
            {props?.giftCardsData?.map((giftCardItem: any, index: number) => {
              let daysDifference = "";
              const expiryDate =
                (giftCardItem?.expiry && parseISO(giftCardItem?.expiry)) || "";
              if (expiryDate) {
                daysDifference = differenceInDays(
                  expiryDate,
                  new Date()
                ).toString();
              }
              return (
                <tr key={index}>
                  <td>{giftCardItem?.customer ?? "N/A"}</td>
                  <td>{giftCardItem?.bookingId ?? "N/A"}</td>
                  <td>${formatAmount(giftCardItem?.value)}</td>
                  <td>{giftCardItem?.cardStatus}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          copyToClipboard(giftCardItem?.cardNumber);
                        }}
                      >
                        <ContentCopyIcon
                          sx={{
                            width: 20,
                            height: 20,
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: "2px", lineHeight: "normal" }}>
                        {giftCardItem?.cardNumber}
                      </Box>
                    </div>
                  </td>
                  <td>
                    {daysDifference
                      ? `${daysDifference} ${
                          parseInt(daysDifference) > 1 ? "days" : "day"
                        }`
                      : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        className="detailmodal detailbooking bookingdetailcancelbutton jhhbhjghjghjghgjg"
        show={show23}
        onHide={handleClose23}
        centered
      >
        <Modal.Body>
          <div className="arrowimg">
            <h6 className="asdasdasdasdasdcc">Add Filters</h6>
            {/* <h4>Canceling <span className={count > 1 ? 'd-n':''}>this booking</span><span className={count === 1 || count === 0 ? 'd-n':''}>these bookings</span> will cancel all its services and initiate a refund.</h4> */}
            {/* <h5>Your time is exceeded money will not refunded</h5> */}
            <div className="outerinnerfeild">
              <div className="dropdown">
                <Link
                  className="asjbhashjbascjbskjacb dropdown-toggle"
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search By Customer Name
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="#">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="outerinnerfeild">
              <div className="dropdown">
                <Link
                  className="asjbhashjbascjbskjacb dropdown-toggle"
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search by status
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="#">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="outerinnerfeild">
              <div className="dropdown">
                <Link
                  className="asjbhashjbascjbskjacb dropdown-toggle"
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search by Service
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="#">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="outerinnerfeild">
              <div className="dropdown">
                <Link
                  className="asjbhashjbascjbskjacb dropdown-toggle"
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search by Price
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="#">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GiftCardtable;
