import "../booking.scss";
import { useState, useEffect } from "react";
import { useRef } from "react";
import {
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
} from "@syncfusion/ej2-react-schedule";
import { extend } from "@syncfusion/ej2-base";
import Accordion from "react-bootstrap/Accordion";
// import { Query, Predicate } from '@syncfusion/ej2-data';
// import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
// import PropertyPane from './property-pane';
import NavbarMain from "../../../Components/NavbarMain";
import FilterNavbarMain from "../../../Components/FilterNavbar";
import FooterMain from "../../../Components/Footer";
import { useHistory } from "react-router-dom";
import {
  getAllSpecialists,
  getAllServices,
  getCalendarData,
  acceptSingleLineItems,
} from "../../../api/apiListingService";
import { format } from "date-fns";
import { useRecoilState } from "recoil";
import { userState } from "../../../recoil/atoms/userAtom";
import { Modal } from "react-bootstrap";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

const CalenderView = () => {
  let scheduleObj = useRef<any>(null);
  const history = useHistory();
  const [specialists, setSpecialists] = useState<any>([]);
  const [servicesData, setServices] = useState<any>([]);
  const [calendarData, setCalendarData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [eDate, setEDate] = useState<any>(new Date());
  const [spIds, setSpId] = useState<any>();
  const [sIds, setSId] = useState<any>();
  const [currentUser] = useRecoilState(userState);
  const [currentLineItem, setCurrentLineItem] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const ListView = () => {
    history.push("/booking");
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const getSpecialists = async () => {
    try {
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        const resp = await getAllSpecialists(data?.access_token);
        resp?.specialistDetails.forEach((element: any) => {
          element.selected = true;
        });
        let spId = resp.specialistDetails.map((a: any) => a.employeeId);
        setSpId(spId);
        setSpecialists(resp.specialistDetails);
        getServices(spId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getServices = async (sId: any) => {
    try {
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        const resp = await getAllServices(data?.access_token);
        resp?.services.forEach((element: any) => {
          element.selected = true;
        });
        let msId = resp?.services.map((a: any) => a.merchantServiceId);
        setSId(msId);
        setServices(resp.services);
        //setStartDate(format(new Date().getTime() - 7 * 24 * 60 * 60 * 1000, 'yyyy-MM-dd'));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCalendarDto = async (
    sDate: any,
    eDate: any,
    sp: any,
    services: any
  ) => {
    try {
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let reqBody = {
          startDate: sDate,
          endDate: eDate,
          specialists: sp,
          services: services,
        };
        if (services?.length > 0 && sp?.length > 0) {
          setCalendarData([]);
          const resp = await getCalendarData(reqBody, data?.access_token);
          let scheduleData: any = [];
          resp?.result.forEach((element: any) => {
            let data = {
              Id: element?.line_id,
              Subject:
                element.serviceName +
                " with " +
                element.employeeName +
                "\nCustomer: " +
                element.customer,
              StartTime: new Date(element.startTime),
              EndTime: new Date(element.endTime),
              OwnerId: element?.line_id,
              EmployeeName: "Status:",
              Category: element?.category,
              bookingId: element?.bookingId,
              appointmentStatus: element?.status
                ? element?.status?.charAt(0).toUpperCase() +
                  element?.status.slice(1)
                : "",
              Status:
                element?.status === "completed" ||
                element?.status === "accepted"
                  ? "#34A853"
                  : "#EA9E58",
            };
            scheduleData.push(data);
          });
          setCalendarData(scheduleData);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onEventRendered = (e: any) => {
    // console.log("ON EVENT RENDERED", e);
    const currentViewDates = scheduleObj.current.getCurrentViewDates();
    // console.log("CURRENT VIEW DATES", currentViewDates);
    setStartDate(format(currentViewDates.at(0), "yyyy-MM-dd"));
    setEDate(
      format(currentViewDates.at(currentViewDates.length - 1), "yyyy-MM-dd")
    );
  };

  const onDataRendered = (e: any) => {
    if (e?.data?.Category === "Reserved") {
      // Apply custom styles
      e.element.style.backgroundColor = "#eee"; // Background color
      e.element.style.color = "#000"; // Text color
    }
  };

  const getBookingByEvent = () => {
    getCalendarDto(startDate, eDate, spIds, sIds);
  };
  const handleCheckboxChange = (e: any, employeeId: any) => {
    if (currentUser?.userType === "specialist") {
      toast.error("Please select atleast one specialist");
      e.target.checked = true;
    } else {
      let sp: any = spIds;
      if (e.target.checked) {
        sp.push(employeeId);
        setSpId(sp);
      } else {
        sp = sp.filter((arr: any) => arr !== employeeId);
        setSpId(sp);
      }
      getCalendarDto(startDate, eDate, sp, sIds);
    }
  };
  const handleServiceCheckbox = (e: any, merchantServiceId: any) => {
    let s: any = sIds;
    if (e.target.checked) {
      s.push(merchantServiceId);
      setSId(s);
    } else {
      s = s.filter((arr: any) => arr !== merchantServiceId);
      setSId(s);
    }
    getCalendarDto(startDate, eDate, spIds, s);
  };
  useEffect(() => {
    getSpecialists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBookingByEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, servicesData, specialists]);
  // console.log("paras",params)

  const onPopupOpen = (args: any) => {
    // console.log("ARGUMENTS", args);
    if (args?.type === "QuickInfo" && args?.data?.OwnerId) {
      const quickPopupElement =
        scheduleObj?.current?.quickPopup?.quickPopup?.element;
      if (quickPopupElement) {
        const status = `
        <div>
          <div class="appointment-status" style="color: #fff; border-radius: 12px; padding: 4px 16px; background: ${args?.data?.Status}">${args?.data?.appointmentStatus}</div>
        </div>
      `;
        quickPopupElement
          .querySelector(".e-resource")
          .insertAdjacentHTML("beforeend", status);
      }
      const acceptNowBtn = quickPopupElement.querySelector(".e-event-edit");
      if (acceptNowBtn) {
        if (
          args?.data?.appointmentStatus === "Pending" &&
          currentUser?.userType !== "customer"
        ) {
          acceptNowBtn.innerHTML = "Accept Now";
          acceptNowBtn.style.background = "#F3F1F8";
          acceptNowBtn.addEventListener("click", (e: any) => {
            e.preventDefault();
            setCurrentLineItem(args?.data);
            setShowModal(true);
          });
        } else {
          acceptNowBtn.style.display = "none";
        }
        acceptNowBtn.setAttribute("title", "Accept Now");
      }
      const viewBookingBtn = quickPopupElement.querySelector(".e-event-delete");
      viewBookingBtn.innerHTML = "Booking Details";
      viewBookingBtn.style.background = "#F3F1F8";
      viewBookingBtn.setAttribute("title", "View Booking Details");
      viewBookingBtn.addEventListener("click", (e: any) => {
        e.preventDefault();
        history.push(`/bookingdetail/${args?.data?.bookingId}`);
      });
      // console.log("QUICK POPUP ELEMENT", quickPopupElement);
      // console.log("ARGUMENTS DATA", args.data);
    } else {
      args.cancel = true;
    }
  };

  const acceptSingleLineItem = async () => {
    try {
      if (
        currentUser &&
        currentUser?.userType !== "customer" &&
        currentLineItem
      ) {
        await acceptSingleLineItems(
          parseInt(currentLineItem?.Id),
          parseInt(currentLineItem?.bookingId),
          currentUser?.access_token
        );
        toast.success("Service accepted successfully!", {
          autoClose: 5000,
        });
        getBookingByEvent();
        handleClose();
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <NavbarMain />
      <FilterNavbarMain />
      <section className="bookingmain">
        <div className="container-main-self">
          <div className="texthere yyyuuyy">
            <h3>Calendar</h3>
            <div className="rightside">
              <button className="oooo" onClick={ListView}>
                <img
                  src="\assets\prof\mdi_calendar.svg"
                  alt="cnctwltimg"
                  className=" cnctwltimgsfsdfdsfs"
                />
                <span>Bookings</span>
              </button>
              {/* <button className='pppp'>
                                <img src="\assets\allstore\Group 20730.svg" alt="cnctwltimg" className="storeimageleft" />
                            </button> */}
            </div>
          </div>
          <div className="schedule-control-section">
            <div className="mainoutermaincalender">
              {currentUser?.userType !== "customer" && (
                <div className="property-section">
                  <div className="maindivss">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item className="speciallist" eventKey="0">
                        <Accordion.Header>Specialists</Accordion.Header>
                        <Accordion.Body
                          className="service-cont"
                          style={{ overflow: "auto" }}
                        >
                          <div className="leftsidespecial">
                            <div className="dowcheck">
                              {specialists?.map((sp: any, index: any) => (
                                <div
                                  key={index}
                                  className="form-check"
                                  style={{ paddingLeft: 0, gap: "15px" }}
                                >
                                  <Box display="flex" alignItems="center">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      style={{
                                        minWidth: "16px",
                                        minHeight: "16px",
                                        width: "16px",
                                        height: "16px",
                                        margin: 0,
                                        float: "unset",
                                      }}
                                      defaultChecked={true}
                                      onChange={(e: any) =>
                                        handleCheckboxChange(e, sp.employeeId)
                                      }
                                      id="flexCheckDefault1"
                                    />
                                  </Box>
                                  <img
                                    src={sp?.coverPhoto}
                                    alt="cnctwltimg"
                                    className="sp-image"
                                    style={{ margin: 0 }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault1"
                                    style={{ margin: 0 }}
                                  >
                                    {sp?.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Services</Accordion.Header>
                        <Accordion.Body
                          className="service-cont"
                          style={{ overflow: "auto" }}
                        >
                          <div className="rightsideservice">
                            <div className="dowcheck">
                              {servicesData?.map(
                                (service: any, index: number) => (
                                  <div
                                    key={index}
                                    className="form-check"
                                    style={{ paddingLeft: 0, gap: "15px" }}
                                  >
                                    <Box display="flex" alignItems="center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        style={{
                                          minWidth: "16px",
                                          minHeight: "16px",
                                          width: "16px",
                                          height: "16px",
                                          margin: 0,
                                          float: "unset",
                                        }}
                                        defaultChecked={true}
                                        onChange={(e: any) =>
                                          handleServiceCheckbox(
                                            e,
                                            service.merchantServiceId
                                          )
                                        }
                                        id="flexCheckDefault1"
                                      />
                                    </Box>
                                    {/* <img src="\assets\allstore\elips11.svg" alt="cnctwltimg" className="storeimageleft" /> */}
                                    <label
                                      className="form-check-label m-l-15"
                                      htmlFor="flexCheckDefault1"
                                      style={{ margin: 0 }}
                                    >
                                      {service?.serviceName}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              )}
              <div className="control-section">
                <div className="control-wrapper calendar-view">
                  <ScheduleComponent
                    cssClass="resource"
                    width="100%"
                    height="650px"
                    selectedDate={startDate}
                    ref={scheduleObj}
                    dataBinding={onEventRendered}
                    eventSettings={{
                      dataSource: extend([], calendarData, undefined, true),
                    }}
                    eventRendered={onDataRendered}
                    popupOpen={onPopupOpen}
                  >
                    <ResourcesDirective>
                      <ResourceDirective
                        field="Id"
                        title="Owners"
                        name="Owners"
                        allowMultiple={true}
                        dataSource={calendarData}
                        textField="EmployeeName"
                        idField="Id"
                        colorField="Status"
                      />
                    </ResourcesDirective>
                    <Inject
                      services={[Day, Week, WorkWeek, Month, Agenda, Resize]}
                    />
                  </ScheduleComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterMain />
      <Modal
        className="detailmodal detailbooking bookingdetailcancelbutton"
        show={showModal}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={4}
          >
            <Box>
              <Box
                component="p"
                sx={{
                  pt: 2,
                  color: "#2E2B2B",
                  fontFamily: "Lexend",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                Are you sure you want to accept service with booking BK-
                {parseInt(currentLineItem?.bookingId)}
              </Box>
            </Box>
            <Box display="flex" gap={2} className="arrowimg">
              <button className="buttoncancel outlined" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="buttoncancel acceptbutton"
                onClick={() => {
                  acceptSingleLineItem();
                }}
              >
                Accept Service
              </button>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CalenderView;
