import { useState, useEffect } from "react";
import "./basket.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import { Link } from "react-router-dom";
import { Modal, Offcanvas } from "react-bootstrap";
import { cancelBooking, createBooking } from "../../api/apiListingService";
import {
  authenticateUserEmail,
  verifyUserEmail,
  checkUserEmail,
  registerUser,
  validateGiftCard,
  updateUserProfile,
  setUserPasswordReq,
  fetchUserProfile,
} from "../../api/apiAuthentications";
import Form from "react-bootstrap/Form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardPaymentForm from "./cardPaymentForm";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { formatAmount } from "../../utils/generalUtils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider as HookForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useRecoilState } from "recoil";
import { bookingState, cartState } from "../../recoil/atoms/cartAtom";
import moment from "moment-timezone";
import { userState } from "../../recoil/atoms/userAtom";
import { Box } from "@mui/material";
import BookingCreationModal from "../../Components/Dialog/BookingCreation";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const customerEmailSchema = yup.object().shape({
  userEmail: yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email"),
});

const phoneNumberSchema = yup
  .string()
  .required("Phone number is required")
  .test(
    "isValidPhoneNumber",
    "Invalid phone number",
    (value) => !value || isValidPhoneNumber(value)
  );

const customerOtpSchema = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter a valid OTP")
    .matches(/^\d{6}$/, "Otp must be a 6-digit number"),
});

const customerSignupSchema = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter a valid OTP")
    .matches(/^\d{6}$/, "Otp must be a 6-digit number"),
  first_name: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(15, "First name must be at most 15 characters"),
  last_name: yup.lazy((value) =>
    value
      ? yup
          .string()
          .min(2, "Last name must be at least 2 characters")
          .max(15, "Last name must be at most 15 characters")
      : yup.string()
  ),
  phone_number: phoneNumberSchema,
});

const merchantPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters"),
});

const setPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const customerProfileSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(15, "First name must be at most 15 characters"),
  last_name: yup.lazy((value) =>
    value
      ? yup
          .string()
          .min(2, "Last name must be at least 2 characters")
          .max(15, "Last name must be at most 15 characters")
      : yup.string()
  ),
  phone_number: phoneNumberSchema,
});

const Basket = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [cartItemsList, setCartItemsList] = useRecoilState(cartState);
  const [show2, setShow2] = useState(false);
  const [sumAmount, setAmount] = useState<any>(0);
  const [paymentType, setPaymentType] = useState("");
  const [paymentTypeSelected, setPaymentTypeSelected] = useState("");
  const [hideshow, sethideshow] = useState<any>(false);
  const [hideshow2, sethideshow2] = useState<any>(false);
  const [tip, setTip] = useState<any>(0);
  const [response, setResp] = useState<any>();
  // Login methods for checkout page
  const [cUser, setUserdata] = useState<any>([]);
  const [checkUser, setCheckUserdata] = useState<any>("");
  const [muser, setMUserdata] = useState<any>([]);
  const [userData, setUser] = useState<any>({});
  const [userType, setUserType] = useState<any>();
  const [userId, setUserId] = useState<number | null>(null);
  const [userNotes, setUserNotes] = useState<any>();
  const [customerName, setCustomerName] = useState<any>("");
  const [termsCondition, setTermCondition] = useState<any>(false);
  const [bookingRequest, setBookingRequest] = useState<any>(false);
  const [discount, setDiscount] = useState<any>(0);
  const [giftCardCheck, setGiftCardCheck] = useState<any>(false);
  const [giftCardData, setGiftCardData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [giftCardNumber, setGiftCardNumber] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [toggleArray, setToggleArray] = useState(
    cartItemsList.map((item: any) => !!item?.tip)
  );
  const [bookingId, setBookingId] = useRecoilState(bookingState);
  const [localBookingId, setLocalBookingId] = useState("");
  const [showBookingModal, setShowBookingModal] = useState<boolean>(false);
  const [isPasswordSet, setIsPasswordSet] = useState<boolean>(
    currentUser?.isPasswordSet || false
  );
  const [isProfileComplete, setIsProfileComplete] = useState<boolean>(
    currentUser?.isProfileComplete || false
  );
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showNewPassModal, setShowNewPassModal] = useState<boolean>(false);
  const [showExistPassModal, setShowExistPassModal] = useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const history = useHistory();

  const handleChange = (e: any) => {
    setPaymentType(e.target.value);
  };

  const handleClose2 = () => {
    setShow2(false);
    resetCart();
  };

  const handleClose = (shouldCancel: boolean = true) => {
    if (paymentType === "card" && bookingId && shouldCancel) {
      cancelBookingReq();
    }
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const customerEmailMethods = useForm({
    resolver: yupResolver(customerEmailSchema),
  });

  const {
    register: customerEmailRegister,
    handleSubmit: handleCustomerEmailSubmit,
    formState: { errors },
    setValue: setCustomerEmailValue,
    // reset: customerEmailReset,
    watch: watchCustomerEmail,
  } = customerEmailMethods;

  const onCustomerAuthentication = () => {
    authenticateUser();
  };

  const customerOtpMethods = useForm({
    resolver: yupResolver(customerOtpSchema),
  });
  const {
    register: customerOtpRegister,
    handleSubmit: handleCustomerOtpSubmit,
    formState: { errors: customerOtpErrors },
    reset: customerOtpReset,
  } = customerOtpMethods;

  const onCustomerOtpSubmit = (data: any) => {
    verifyOtp(data);
  };

  const handleClose4 = () => {
    setShow4(false);
    customerOtpReset();
  };

  const customerSignupMethods = useForm({
    resolver: yupResolver(customerSignupSchema),
  });
  const {
    register: customerSignupRegister,
    handleSubmit: handleCustomerSignupSubmit,
    formState: { errors: customerSignupErrors },
    reset: customerSignupReset,
    control,
  } = customerSignupMethods;

  const onCustomerSignupSubmit = (data: any) => {
    verifyOtp(data, true);
  };

  const handleClose5 = () => {
    setShow5(false);
    customerSignupReset();
  };

  const merchantPasswordMethods = useForm({
    resolver: yupResolver(merchantPasswordSchema),
  });
  const {
    register: merchantPasswordRegister,
    handleSubmit: handleMerchantPasswordpSubmit,
    formState: { errors: merchantPasswordErrors },
    reset: merchantPasswordReset,
  } = merchantPasswordMethods;

  const onMerchantPasswordSubmit = (data: any) => {
    if (isPasswordSet && userType === "customer") {
      loginUserWithPassword(data?.password);
    } else {
      verifyMerchant(data?.password);
    }
  };

  const handleClose3 = () => {
    setShow3(false);
    merchantPasswordReset();
  };

  const setPasswordMethods = useForm({
    resolver: yupResolver(setPasswordSchema),
  });
  const {
    register: setPasswordRegister,
    handleSubmit: handleSetPasswordpSubmit,
    formState: { errors: setPasswordErrors },
  } = setPasswordMethods;

  const onSetPasswordSubmit = (data: any) => {
    setUserPassword(data);
  };

  // Customer profile methods
  const customerProfileMethods = useForm({
    resolver: yupResolver(customerProfileSchema),
  });
  const {
    register: customerProfileRegister,
    handleSubmit: handleCustomerProfileSubmit,
    formState: { errors: customerProfileErrors },
    reset: customerProfileReset,
    control: customerProfileControl,
  } = customerProfileMethods;

  const onCustomerProfileSubmit = (data: any) => {
    updateUserProfileReq(data);
  };

  const handleCloseProfileModal = () => {
    customerProfileReset();
    setShowProfileModal(false);
  };

  const updateUserProfileReq = async (data: any) => {
    try {
      // Get services data from api
      const resp = await updateUserProfile(currentUser?.access_token, data);
      if (resp && resp?.message) {
        let profileData = {
          ...currentUser,
          isProfileComplete: true,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
          name: `${data?.first_name} ${data?.last_name}`,
        };
        setCurrentUser(profileData);
        toast.success(resp?.message);
        if (!isPasswordSet) {
          handleCloseProfileModal();
          handleClose4();
          setShowNewPassModal(true);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const onPaymentProceed = () => {
    handleShow();
  };

  const resetCart = () => {
    if (
      response?.statusCode !== 400 &&
      response?.statusCode !== 409 &&
      response?.statusCode !== 404
    ) {
      setTip(0);
      setAmount(0);
      setDiscount(0);
      setGiftCardNumber("");
      setCartItemsList([]);
    }
  };

  const RemoveItemFromCart = (ind: any) => {
    const updatedArray = cartItemsList.filter(
      (arr: any, index: number) => index !== ind
    );
    setCartItemsList(updatedArray);
    if (updatedArray.length === 0) {
      setTip(0);
      setAmount(0);
      removeGiftCard();
    }
  };

  const sumTipCartItems = (data: any) => {
    let tip = 0;
    data.forEach((element: any) => {
      // Use parseFloat to convert tip to a number before adding to total /
      const tipAmount = parseFloat(element?.tip);
      if (!isNaN(tipAmount)) {
        tip += tipAmount;
      }
    });

    // Format the total tip amount
    const formattedTip = formatAmount(tip);

    const subTotalAmount = parseFloat(sumAmount);
    const discountedValue = parseFloat(discount);
    if (!isNaN(totalAmount) && !isNaN(discountedValue)) {
      const totalAmount = subTotalAmount + tip;
      if (totalAmount < discountedValue) {
        removeGiftCard();
        toast.error("Service charges should be greater than gift amount!", {
          autoClose: 5000,
        });
      }
    }

    // Update the state with the formatted tip
    setTip(formattedTip);
  };

  const checkGiftCardItem = () => {
    const giftCards = cartItemsList?.filter(
      (item: any) =>
        item?.servicetype === "Giftcard" || item?.serviceType === "Giftcard"
    );

    if (giftCards.length > 0) {
      setGiftCardCheck(true);
    } else {
      setGiftCardCheck(false);
    }

    let giftCardPayload: any = [];
    giftCards.forEach((element: any) => {
      const existingItemIndex = giftCardPayload.findIndex(
        (item: any) => item?.merchantServiceId === element?.id
      );

      if (existingItemIndex !== -1) {
        const newList = [...giftCardPayload];
        newList[existingItemIndex] = {
          ...newList[existingItemIndex],
          quantity: newList[existingItemIndex].quantity + 1,
        };
        giftCardPayload = newList;
      } else {
        let data = {
          merchantServiceId:
            element?.servicetype === "Giftcard"
              ? element.id
              : element.serviceId,
          quantity: 1,
        };
        giftCardPayload = [...giftCardPayload, data];
      }
    });
    setGiftCardData(giftCardPayload);
  };

  const cartItems = () => {
    if (cartItemsList.length > 0) {
      let amount = 0;
      setTip(0);
      setAmount(0);
      cartItemsList.forEach((element: any) => {
        const currentPriceAsNumber = parseFloat(element?.currentprice);
        if (!isNaN(currentPriceAsNumber)) {
          amount += element?.seats
            ? element?.seats * currentPriceAsNumber
            : currentPriceAsNumber;
        }
      });
      const formattedAmount = formatAmount(amount);
      setAmount(formattedAmount);
      sumTipCartItems(cartItemsList);
      checkGiftCardItem();
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      let data: any = sessionStorage.getItem("user");
      data = JSON.parse(data);
      const userType = data?.userType;
      if (userType === "customer") {
        if (data?.email) {
          setCustomerEmailValue("userEmail", data?.email, {
            shouldValidate: true,
          });
        }
        setPaymentType("card");
      } else {
        setPaymentType("pay_after_service");
      }
      setUser(data);
      setCheckUserdata(userType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsList, sumAmount]);

  useEffect(() => {
    if (
      currentUser?.access_token &&
      (currentUser?.userType === "merchant" ||
        currentUser?.userType === "specialist")
    ) {
      const isSameMerchant = checkMerchantIds();
      if (!isSameMerchant) {
        resetCart();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.access_token]);

  const checkMerchantIds = () => {
    // Extract merchant IDs from cart items
    const merchantIdsInCart = cartItemsList.map((item: any) => item.merchantId);

    // Check if all merchant IDs from cart exist in current user's merchant_ids
    const allMerchantIdsExist = merchantIdsInCart.every((id: any) =>
      currentUser.merchant_ids.includes(id)
    );

    return allMerchantIdsExist;
  };

  const closeBookingModal = () => setShowBookingModal(false);

  const openBookingModal = () => {
    setBookingId(null);
    handleClose(false);
    setCartItemsList([]);
    setShowBookingModal(true);
  };

  const navigateToHome = () => {
    closeBookingModal();
    history.replace("/");
  };

  // Create booking api call
  const createBookingReq = async () => {
    try {
      setBookingRequest(true);
      let bookings: any = [];
      let groupSessions: any = [];

      cartItemsList.forEach((element: any) => {
        if (
          element?.servicetype !== "Giftcard" &&
          element?.serviceType !== "Giftcard" &&
          element?.serviceType !== "Group"
        ) {
          const data: any = {
            date: element.start,
            ssEmpId: parseInt(element.ssEmpId),
            startTime: element.start,
          };
          const tipAmount = parseFloat(element?.tip);

          if (!isNaN(tipAmount) && tipAmount > 0) {
            data.tip = tipAmount;
          }

          bookings.push(data);
        }
      });

      cartItemsList.forEach((element: any) => {
        if (element?.serviceType === "Group") {
          const data: any = {
            bookingId: element.bookingId,
            seats: parseInt(element.seats),
          };
          const tipAmount = parseFloat(element?.tip);

          if (!isNaN(tipAmount) && tipAmount > 0) {
            data.tip = tipAmount;
          }

          groupSessions.push(data);
        }
      });

      let reqBody: any = {
        customerEmail:
          checkUser === "customer"
            ? userData.email
            : watchCustomerEmail("userEmail"),
        paymentMethod: paymentType.toLowerCase(),
        notes: userNotes,
        name: customerName,
        bookings: bookings,
        groupSessions: groupSessions,
        purchaseGiftCards: giftCardData,
        giftCard: giftCardNumber,
      };
      if (giftCardData.length === 0) {
        delete reqBody.purchaseGiftCards;
      }
      if (discount === 0) {
        delete reqBody.giftCard;
      }
      if (bookings.length === 0) {
        delete reqBody.bookings;
      }
      if (groupSessions.length === 0) {
        delete reqBody.groupSessions;
      }

      // console.log("REQ BODY", reqBody);

      // Get services data from api
      const resp = await createBooking(reqBody, userData?.access_token);
      // toast.success(resp.message, {
      //   autoClose: 5000,
      // });
      setResp(resp);
      setLocalBookingId(resp?.bookingId);
      if (paymentType.toLowerCase() === "card") {
        setBookingId(resp?.bookingId);
        setPaymentTypeSelected(paymentType.toLowerCase());
      } else {
        openBookingModal();
      }
      setBookingRequest(false);
    } catch (error: any) {
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      setResp(error.response?.data);
      setBookingId(null);
      setBookingRequest(false);
    }
  };

  const cancelBookingReq = async () => {
    try {
      await cancelBooking(parseInt(bookingId), userData?.access_token);
      setBookingId(null);
      setPaymentTypeSelected("");
    } catch (error: any) {
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (bookingId && checkUser === "customer") {
      cancelBookingReq();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUser]);

  const applyGiftCard = async (giftCardnumber: any) => {
    try {
      setLoading(true);
      // Check Gift card
      const resp = await validateGiftCard(giftCardnumber);
      if (resp.isValid === true) {
        setGiftCardNumber(giftCardnumber);
        const giftAmount = parseFloat(resp?.value);
        const subTotalAmount = parseFloat(sumAmount) + parseFloat(tip);
        if (
          !isNaN(giftAmount) &&
          !isNaN(subTotalAmount) &&
          subTotalAmount > giftAmount
        ) {
          const formattedGiftAmount = formatAmount(giftAmount);
          setDiscount(formattedGiftAmount);
          toast.success("Gift Card Applied Successfully!", {
            autoClose: 5000,
          });
        } else {
          setDiscount(0);
          toast.error("Service charges should be greater than gift amount!", {
            autoClose: 5000,
          });
        }
      } else {
        toast.error("Gift Card Number is invalid", {
          autoClose: 5000,
        });
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      setDiscount(0);
      setLoading(false);
    }
  };
  const removeGiftCard = () => {
    setDiscount(0);
    setGiftCardNumber("");
  };

  // Check user email api call
  const authenticateUser = async () => {
    try {
      // Get services data from api
      const resp = await checkUserEmail({
        email: watchCustomerEmail("userEmail"),
      });
      setUserType(resp.user_type);
      setUserId(resp?.user_id);
      if (resp.user_type === "customer") {
        setIsPasswordSet(resp?.isPasswordSet);
        setIsProfileComplete(resp?.isProfileComplete);
        setUserdata(resp);
        if (resp?.auth_type === "otp" && resp?.isPasswordSet) {
          setShowAuthModal(true);
        } else if (resp?.auth_type === "otp" && !resp?.isPasswordSet) {
          setShow4(true);
          setMinutes(5);
          setSeconds(0);
        }
      } else if (
        resp.user_type === "merchant" ||
        resp.user_type === "specialist"
      ) {
        setUserType(resp.user_type);
        setShow3(true);
        setMUserdata(resp);
      }
    } catch (error: any) {
      if (error.response?.data?.statusCode === 404) {
        setUserType("customer");
        setShow5(true);
        setMinutes(5);
        setSeconds(0);
        registerCustomer();
      }
      console.error("Error fetching data:", error.message);
    }
  };

  const handleAuthType = (authType: string) => {
    closeAuthModal();
    if (authType === "otp") {
      loginUser();
      setShow4(true);
    } else if (authType === "password") {
      setShowExistPassModal(true);
    }
  };

  const closeAuthModal = () => {
    setShowAuthModal(false);
  };

  // Login user api call
  const loginUser = async () => {
    try {
      // Get services data from api
      const resp = await authenticateUserEmail({
        email: watchCustomerEmail("userEmail"),
      });
      setMinutes(5);
      setSeconds(0);
      setUserdata(resp);
    } catch (error: any) {
      //setResp(error.response.data);
      console.error("Error fetching data:", error.message);
    }
  };

  function isObject(variable: any): variable is object {
    return (
      variable !== null &&
      typeof variable === "object" &&
      !Array.isArray(variable)
    );
  }

  // Verify OTP user api call
  const verifyOtp = async (data: any, newUser: boolean = false) => {
    const body: any = {
      user_id: cUser?.user_id,
      otp: data?.otp,
    };
    if (data?.first_name) {
      body.first_name = data?.first_name;
    }
    if (data?.last_name) {
      body.last_name = data?.last_name;
    }
    if (data?.phone_number) {
      body.phone_number = data?.phone_number;
    }
    let profileData = {};
    try {
      // Get services data from api
      const resp = await verifyUserEmail(body);
      resp.email = watchCustomerEmail("userEmail");
      resp.userType = userType;
      profileData = {
        ...resp,
        userId: userId ? userId : cUser?.user_id ? cUser?.user_id : null,
        isPasswordSet,
        isProfileComplete,
      };
      if (newUser && resp && resp?.access_token) {
        profileData = {
          ...profileData,
          isProfileComplete: true,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
          name: `${data?.first_name} ${data?.last_name}`,
        };
        setCurrentUser(profileData);
        sessionStorage.setItem("user", JSON.stringify(profileData));
        handleCloseProfileModal();
        handleClose4();
        handleClose5();
        setShowNewPassModal(true);
      } else if (resp && resp?.access_token) {
        sessionStorage.setItem("user", JSON.stringify(profileData));
        setCurrentUser(profileData);

        try {
          const userProfileResp = await fetchUserProfile(resp?.access_token);
          if (userProfileResp && isObject(userProfileResp)) {
            profileData = {
              ...profileData,
              ...userProfileResp,
              name: `${(userProfileResp as any)?.first_name ?? ""} ${
                (userProfileResp as any)?.last_name ?? ""
              }`,
            };
            setCurrentUser(profileData);
          }
        } catch (error: any) {
          toast.error(error.response?.data?.message);
        }

        if (isProfileComplete && isPasswordSet) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return;
        }
        if (!isProfileComplete) {
          customerProfileReset({
            first_name: (profileData as any)?.first_name ?? "",
            last_name: (profileData as any)?.last_name ?? "",
            phone_number: (profileData as any)?.phone_number ?? "",
          });
          handleClose4();
          setShowProfileModal(true);
        } else if (!isPasswordSet) {
          handleCloseProfileModal();
          handleClose4();
          setShowNewPassModal(true);
        }
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message);
      console.error("Error fetching data:", error.message);
    }
  };

  // Authenticate User with password api call
  const loginUserWithPassword = async (merchantPassword: string) => {
    let profileData = {};
    try {
      // Get services data from api
      const resp = await authenticateUserEmail({
        email: watchCustomerEmail("userEmail"),
        password: merchantPassword,
      });

      resp.email = watchCustomerEmail("userEmail");
      resp.userType = userType;
      profileData = {
        ...resp,
        userId: userId ? userId : cUser?.user_id ? cUser?.user_id : null,
        isPasswordSet,
        isProfileComplete,
      };
      sessionStorage.setItem("user", JSON.stringify(profileData));
      setCurrentUser(profileData);

      try {
        const userProfileResp = await fetchUserProfile(resp?.access_token);
        if (userProfileResp && isObject(userProfileResp)) {
          profileData = {
            ...profileData,
            ...userProfileResp,
            name: `${(userProfileResp as any)?.first_name ?? ""} ${
              (userProfileResp as any)?.last_name ?? ""
            }`,
          };
          sessionStorage.setItem("user", JSON.stringify(profileData));
          setCurrentUser(profileData);
        }
      } catch (error: any) {
        toast.error(error.response?.data?.message);
      }

      if (isProfileComplete && isPasswordSet) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
        return;
      }
      if (!isProfileComplete) {
        customerProfileReset({
          first_name: (profileData as any)?.first_name ?? "",
          last_name: (profileData as any)?.last_name ?? "",
          phone_number: (profileData as any)?.phone_number ?? "",
        });
        handleClose4();
        setShowExistPassModal(false);
        setShowProfileModal(true);
      }
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }
  };

  const setUserPassword = async (data: any) => {
    try {
      // Get services data from api
      const resp = await setUserPasswordReq(currentUser?.access_token, data);
      if (resp && resp?.message) {
        toast.success(resp?.message);
        setCurrentUser({
          ...currentUser,
          isPasswordSet: true,
        });
        sessionStorage.setItem(
          "user",
          JSON.stringify({ ...currentUser, isPasswordSet: true })
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Verify Merchat user api call
  const verifyMerchant = async (merchantPassword: string) => {
    try {
      // Get services data from api
      const resp = await authenticateUserEmail({
        email: watchCustomerEmail("userEmail"),
        password: merchantPassword,
      });
      resp.name = muser?.name;
      resp.userType = userType;
      resp.email = watchCustomerEmail("userEmail");
      sessionStorage.setItem("user", JSON.stringify(resp));
      setCurrentUser({ ...resp, userId });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }
  };

  // Register user api call
  const registerCustomer = async () => {
    try {
      // Get services data from api
      const resp = await registerUser({
        email_address: watchCustomerEmail("userEmail"),
      });
      setUserdata(resp);
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };

  const checkTermCondition = (value: any) => {
    setTermCondition(value);
  };

  useEffect(() => {
    if (show4 || show5) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, minutes, show4, show5]);

  let totalAmount = 0;
  const subTotalAmount = parseFloat(sumAmount);
  if (!isNaN(subTotalAmount)) {
    totalAmount = totalAmount + subTotalAmount;
  }
  const tipAmount = parseFloat(tip);
  if (!isNaN(tipAmount) && tipAmount > 0) {
    totalAmount = totalAmount + tipAmount;
  }
  const discountAmount = parseFloat(discount);
  if (totalAmount > discountAmount) {
    totalAmount = totalAmount - discountAmount;
  }
  const totalAmountFormatted = formatAmount(totalAmount);

  return (
    <>
      <div className="basketpagecss">
        <NavbarMain />
        <FilterNavbarMain />
        <div></div>

        <section className="basket">
          <div className="container-main-self">
            <div className="texthere">
              <h3>Basket</h3>
            </div>
            <div className="row p-0">
              <div className="col-lg-7 col-md-7 col-12 jkjkjkjkj">
                <div className="outer_main">
                  <h1>Your Items List</h1>
                  <div className="downrows">
                    <div className="row p-0">
                      <div className="col-lg-6 p-0">
                        <div className="booking">
                          <h6>Bookings</h6>
                        </div>
                      </div>
                      <div className="col-lg-3 p-0">
                        <div className="location">
                          <h6> </h6>
                        </div>
                      </div>
                      <div className="col-lg-2 p-0">
                        <div className="subtotal">
                          <h6>Subtotal</h6>
                        </div>
                      </div>
                      <div className="col-lg-1"></div>
                    </div>
                  </div>

                  {cartItemsList.map((item: any, index: number) => {
                    return (
                      <div key={index} className="cartrow">
                        {item?.servicetype === "Giftcard" ||
                        item?.serviceType === "Giftcard" ? (
                          <>
                            {" "}
                            <div className="row p-0">
                              <div className="col-lg-6 p-0">
                                <div className="booking">
                                  <h2>{item.servicename}</h2>
                                  <h4>Expiry: {item.expiry}</h4>
                                </div>
                              </div>
                              <div className="col-lg-3 p-0">
                                <div className="location"></div>
                              </div>
                              <div className="col-lg-2 p-0">
                                <div className="subtotal">
                                  <h4>${item.currentprice}</h4>
                                </div>
                              </div>
                              <div className="col-lg-1 p-0">
                                <div
                                  className="cross"
                                  onClick={() => RemoveItemFromCart(index)}
                                >
                                  <img
                                    src="\assets\allstore\Group 30285.svg"
                                    alt="cnctwltimg"
                                    className="cnctwltimsdsdsddsg"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row p-0">
                              <div className="col-lg-6 p-0">
                                <div className="booking">
                                  <h2>{item.servicename}</h2>
                                  <h6>
                                    {item?.timezone &&
                                    item?.timezone !== moment.tz.guess(true)
                                      ? moment(item?.start)
                                          .tz(item?.timezone)
                                          .format("MMM DD, YYYY")
                                      : moment(item?.bookingDate).format(
                                          "MMM DD, YYYY"
                                        )}
                                  </h6>
                                  {/* <h4>
                                    {moment(item?.bookingDate).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </h4> */}
                                  <h5>Specialist- {item.nickName} </h5>
                                  <h6>
                                    <span>
                                      {" "}
                                      {moment(item?.start)
                                        .tz(item?.timezone)
                                        .format("hh:mm A")}
                                    </span>{" "}
                                    -{" "}
                                    <span>
                                      {moment(item?.end)
                                        .tz(item?.timezone)
                                        .format("hh:mm A")}
                                    </span>{" "}
                                    {item?.timezone &&
                                    item?.timezone !== moment.tz.guess(true)
                                      ? `(${moment
                                          .tz(item?.timezone)
                                          .format("z")})`
                                      : ""}
                                  </h6>
                                  {item?.seats && (
                                    <h5>No of Seats - {item.seats} </h5>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-3 p-0">
                                <div className="location"></div>
                              </div>
                              <div className="col-lg-2 p-0">
                                <div className="subtotal">
                                  <h4>
                                    $
                                    {item?.seats
                                      ? formatAmount(
                                          item?.currentprice * item?.seats
                                        )
                                      : formatAmount(item?.currentprice)}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-1 p-0">
                                <div
                                  className="cross"
                                  onClick={() => RemoveItemFromCart(index)}
                                >
                                  <img
                                    src="\assets\allstore\Group 30285.svg"
                                    alt="cnctwltimg"
                                    className="cnctwltimsdsdsddsg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mapsection">
                              <img
                                src="\assets\imag\clarity_map-solid.svg"
                                alt="cnctwltimg"
                                className="cnctwltimsdsdsddsg"
                              />
                              <p>{item.location}</p>
                            </div>
                            <div className="downtipadd">
                              <button
                                className={
                                  toggleArray[index] === true ? "nn" : "mm"
                                }
                                onClick={() => {
                                  const updatedToggleArray = [...toggleArray];
                                  updatedToggleArray[index] =
                                    !updatedToggleArray[index];
                                  setToggleArray(updatedToggleArray);
                                }}
                              >
                                Add Tip
                              </button>
                              <input
                                className={
                                  toggleArray[index] === true ? "mm" : "nn"
                                }
                                type="text"
                                pattern="\d*\.?\d*"
                                placeholder="Add Tip"
                                value={item.tip}
                                onChange={(e: any) => {
                                  const enteredTip = e.target.value;
                                  if (/^\d*\.?\d*$/.test(enteredTip)) {
                                    setCartItemsList((prevItems: any) =>
                                      prevItems.map(
                                        (prevItem: any, i: number) =>
                                          i === index
                                            ? { ...prevItem, tip: enteredTip }
                                            : prevItem
                                      )
                                    );
                                    sumTipCartItems(cartItemsList);
                                  } else {
                                    // Set tip to a default value (e.g., 0) for non-numeric input
                                    setCartItemsList((prevItems: any) =>
                                      prevItems.map(
                                        (prevItem: any, i: number) =>
                                          i === index
                                            ? { ...prevItem, tip: "0" }
                                            : prevItem
                                      )
                                    );
                                    sumTipCartItems(cartItemsList);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "ArrowUp") {
                                    // Increment on Up arrow key press
                                    e.preventDefault();
                                    setCartItemsList((prevItems: any) =>
                                      prevItems.map(
                                        (prevItem: any, i: number) =>
                                          i === index
                                            ? {
                                                ...prevItem,
                                                tip: (
                                                  (prevItem?.tip
                                                    ? parseFloat(prevItem?.tip)
                                                    : 0) + 1
                                                ).toString(),
                                              }
                                            : prevItem
                                      )
                                    );
                                    sumTipCartItems(cartItemsList);
                                  } else if (e.key === "ArrowDown") {
                                    // Decrement on Down arrow key press
                                    e.preventDefault();
                                    setCartItemsList((prevItems: any) =>
                                      prevItems.map(
                                        (prevItem: any, i: number) =>
                                          i === index
                                            ? {
                                                ...prevItem,
                                                tip: Math.max(
                                                  0,
                                                  (prevItem?.tip
                                                    ? parseFloat(prevItem?.tip)
                                                    : 0) - 1
                                                ).toString(),
                                              }
                                            : prevItem
                                      )
                                    );
                                    sumTipCartItems(cartItemsList);
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-12 skjdcbsjbvdshbvvds">
                <HookForm {...customerEmailMethods}>
                  <form
                    onSubmit={handleCustomerEmailSubmit(
                      checkUser === "customer" ||
                        checkUser === "merchant" ||
                        checkUser === "specialist"
                        ? onPaymentProceed
                        : onCustomerAuthentication
                    )}
                  >
                    <div className="down_outer_main ">
                      <div className="billarea">
                        <h4>Service Charges</h4>
                        <div className="totalbill">
                          <div className="ssss">
                            <h5>Service charges</h5>
                            <h6>${sumAmount}</h6>
                          </div>
                          <div className="ssss">
                            <h5>Tip Amount</h5>
                            <h6>${tip}</h6>
                          </div>
                          <div className="ssss">
                            <h5>Discount/Gift Card</h5>
                            <h6>${discount}</h6>
                          </div>
                          <div className="sssstotal">
                            <h5>Total</h5>
                            <h6>${totalAmountFormatted}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="emailname">
                        <div className="outerinnerfeild">
                          <input
                            type="text"
                            placeholder="Add Discount/Gift Card"
                            value={giftCardNumber}
                            maxLength={16}
                            onChange={(e: any) =>
                              setGiftCardNumber(e.target.value)
                            }
                          />
                          {discount > 0 ? (
                            <button
                              type="submit"
                              className="stripe-btn bg-red c-w"
                              style={{
                                fontFamily: "Poppins",
                                width: "100px",
                                height: "45px",
                                fontSize: "16px",
                                lineHeight: 1,
                              }}
                              onClick={() => removeGiftCard()}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="stripe-btn c-w"
                              style={{
                                fontFamily: "Poppins",
                                width: "100px",
                                height: "45px",
                                fontSize: "16px",
                                lineHeight: 1,
                              }}
                              disabled={!giftCardNumber || loading}
                              onClick={() => applyGiftCard(giftCardNumber)}
                            >
                              {loading ? "Applying..." : "Apply"}
                            </button>
                          )}
                          {/* <p className='error_message'>You will receive a pin code to verify and complete this booking.</p> */}
                        </div>
                      </div>
                      <div className="emailname">
                        {checkUser === "customer" ? (
                          <> </>
                        ) : (
                          <>
                            <div className="outerinnerfeild">
                              <input
                                {...customerEmailRegister("userEmail")}
                                type="text"
                                placeholder="Customer Email"
                              />
                              {errors?.userEmail && (
                                <span
                                  className="text-danger"
                                  style={{ paddingTop: 5 }}
                                >
                                  {errors.userEmail.message}
                                </span>
                              )}
                            </div>
                            {checkUser !== "customer" ? (
                              <div className="outerinnerfeild namefeild">
                                <input
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  placeholder="Customer Name"
                                  onChange={(e: any) =>
                                    setCustomerName(e.target.value)
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                      <div className="downcard">
                        <label>Additional Notes</label>
                        <textarea
                          placeholder="Enter Additional notes"
                          onChange={(e: any) => setUserNotes(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="termscondition">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={(e: any) =>
                              checkTermCondition(e.target.checked)
                            }
                          />
                        </div>
                        <h6>
                          Accept{" "}
                          <Link to="/terms&conditon">Terms and conditions</Link>{" "}
                          and <Link to="/privacypolicy">Privacy Policy</Link> .
                        </h6>
                      </div>
                      <div className="buttondivhere">
                        {checkUser === "customer" ||
                        checkUser === "merchant" ||
                        checkUser === "specialist" ? (
                          <button
                            type="submit"
                            className={`${
                              cartItemsList.length === 0 || !termsCondition
                                ? "disabled-btn paymentproceed"
                                : "paymentproceed"
                            }`}
                          >
                            Proceed to Checkout
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={`${
                              cartItemsList.length === 0 || !termsCondition
                                ? "disabled-btn paymentproceed"
                                : "paymentproceed"
                            }`}
                            // disabled={watchCustomerEmail("userEmail") === ""}
                          >
                            Confirm
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </HookForm>
              </div>
            </div>
          </div>
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="menu-off-canvas creditcard_off"
          >
            <Offcanvas.Header>
              <Offcanvas.Title>Payment</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="billarea">
                <h4>Service Charges</h4>
                <div className="totalbill">
                  <div className="ssss">
                    <h5>Service charges</h5>
                    <h6>${sumAmount}</h6>
                  </div>
                  <div className="ssss">
                    <h5>Tip Amount</h5>
                    <h6>${tip}</h6>
                  </div>
                  <div className="ssss">
                    <h5>Discount/Gift Card</h5>
                    <h6>${discount}</h6>
                  </div>
                  <div className="sssstotal">
                    <h5>Total</h5>
                    <h6>${totalAmountFormatted}</h6>
                  </div>
                </div>
              </div>
              <p className="servicedetailhere">
                For service details please press back for the basket
              </p>
              <div className="mainoutersidecredit">
                {checkUser === "customer" ? (
                  <div className="outerinnerfeild dropdownslect">
                    <label htmlFor="fname">
                      Select Payment Method<span className="c-red"> *</span>
                    </label>
                    <Form.Select
                      aria-label="Default select example"
                      value={paymentType}
                      onChange={handleChange}
                    >
                      {/* <option>Select Payment Method</option> */}
                      {giftCardCheck === true ? (
                        <>
                          <option
                            onClick={(e: any) => setPaymentType(e.target.text)}
                            value="card"
                          >
                            Card
                          </option>
                        </>
                      ) : (
                        <>
                          <option
                            onClick={(e: any) => setPaymentType(e.target.text)}
                            value="card"
                          >
                            Card
                          </option>
                          {/* <option
                            onClick={(e: any) => setPaymentType(e.target.text)}
                            value="venmo"
                          >
                            Venmo
                          </option> */}
                        </>
                      )}
                    </Form.Select>
                    <img
                      src="https://res.cloudinary.com/drt6vurtt/image/upload/v1700461624/Vector_12_olzt3i.svg"
                      alt="cnctwltimg"
                      className="cnctwltimsdsdsddsg"
                    />
                  </div>
                ) : (
                  <div className="outerinnerfeild dropdownslect">
                    <label htmlFor="fname">
                      Select Payment Method<span className="c-red"> *</span>
                    </label>
                    <Form.Select
                      aria-label="Default select example"
                      value={paymentType}
                      onChange={handleChange}
                    >
                      {/* <option>Select Payment Method</option> */}
                      <option
                        onClick={(e: any) => setPaymentType(e.target.text)}
                        value="pay_after_service"
                      >
                        Pay After Service
                      </option>
                      {/* <option
                        onClick={(e: any) => setPaymentType(e.target.text)}
                        value="venmo"
                      >
                        Venmo
                      </option> */}
                    </Form.Select>
                    <img
                      src="https://res.cloudinary.com/drt6vurtt/image/upload/v1700461624/Vector_12_olzt3i.svg"
                      alt="cnctwltimg"
                      className="cnctwltimsdsdsddsg"
                    />
                  </div>
                )}

                {paymentType === "card" && paymentTypeSelected === "card" ? (
                  <div>
                    <Elements stripe={stripePromise}>
                      <CardPaymentForm
                        response={response}
                        openBookingModal={openBookingModal}
                      />
                    </Elements>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {!bookingRequest ? (
                <button
                  onClick={() => {
                    createBookingReq();
                  }}
                  className={
                    paymentType === "card" && paymentTypeSelected === "card"
                      ? "d-n"
                      : "creditcardbuton"
                  }
                  disabled={paymentType === ""}
                >
                  Proceed Booking
                </button>
              ) : (
                <button className="creditcardbuton disabled-btn">
                  Proceed Booking
                </button>
              )}
            </Offcanvas.Body>
          </Offcanvas>

          <Modal
            className="detailmodal"
            show={show2}
            onHide={handleClose2}
            centered
          >
            <Modal.Body>
              <div className="arrowimg">
                <img
                  src="\assets\allstore\Calander.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>{response?.message}</p>
                {response?.bookingId ? (
                  <h5>Booking ID : {response?.bookingId}</h5>
                ) : (
                  <></>
                )}
                <button onClick={handleClose2}>Continue</button>
              </div>
            </Modal.Body>
          </Modal>

          {/* <LoginModal
            show1={show1}
            handleClose1={handleClose1}
            show3={show3}
            handleClose3={handleClose3}
            show4={show4}
            handleClose4={handleClose4}
          /> */}
        </section>
        <FooterMain />
      </div>

      {/* Login Modals */}
      <Modal className="Login" show={show3} onHide={handleClose3} centered>
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="outermain_form">
              <HookForm {...merchantPasswordMethods}>
                <form
                  onSubmit={handleMerchantPasswordpSubmit(
                    onMerchantPasswordSubmit
                  )}
                >
                  <h2>Registered as merchant</h2>
                  <h4>Welcome Back , {muser?.name}</h4>
                  <div className="outerinnerfeild hsdbhsdbhsdbsh">
                    <label htmlFor="fname">Password</label>
                    <input
                      {...merchantPasswordRegister("password")}
                      type={hideshow === false ? "password" : "text"}
                      placeholder="Enter Password"
                    />
                    {hideshow === true ? (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\show.png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    ) : (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\hide (1).png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    )}
                    {merchantPasswordErrors?.password && (
                      <span className="text-danger" style={{ paddingTop: 5 }}>
                        {merchantPasswordErrors.password.message}
                      </span>
                    )}
                  </div>
                  <button className="button-s" type="submit">
                    Login
                  </button>
                </form>
              </HookForm>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login veriifactionmain"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right Verification samelogin">
            <div className="Container">
              <HookForm {...customerOtpMethods}>
                <form onSubmit={handleCustomerOtpSubmit(onCustomerOtpSubmit)}>
                  <div className="mainformhere">
                    <h2>Please Verify OTP</h2>
                    <h4>Enter your OTP code received on Mail</h4>
                    <h5>{watchCustomerEmail("userEmail")}</h5>
                    <div
                      className="outerinnerfeild"
                      style={{ paddingBottom: 15 }}
                    >
                      <input
                        {...customerOtpRegister("otp")}
                        type="text"
                        placeholder="Enter OTP"
                      />
                      {customerOtpErrors?.otp && (
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerOtpErrors.otp.message}
                        </span>
                      )}
                    </div>

                    <h6>
                      OTP code will expire in -{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h6>
                    <button type="submit" className={"button-s"}>
                      Confirm
                    </button>
                    <h3
                      onClick={() => authenticateUser()}
                      className={
                        seconds > 0 || minutes > 0 ? "disabled-link" : "c-p"
                      }
                      style={{
                        color:
                          seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                    >
                      Resend
                    </h3>
                  </div>
                </form>
              </HookForm>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login veriifactionmain"
        show={show5}
        onHide={handleClose5}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right Verification samelogin">
            <div className="Container">
              <HookForm {...customerSignupMethods}>
                <form
                  onSubmit={handleCustomerSignupSubmit(onCustomerSignupSubmit)}
                >
                  <div className="mainformhere">
                    <h2>Please Verify OTP</h2>
                    <h4>Enter your OTP code received on Mail</h4>
                    <h5>{watchCustomerEmail("userEmail")}</h5>
                    <div
                      className="outerinnerfeild"
                      style={{ paddingBottom: 15 }}
                    >
                      <input
                        {...customerSignupRegister("otp")}
                        type="text"
                        placeholder="Enter OTP"
                      />
                      {customerSignupErrors?.otp && (
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors.otp.message}
                        </span>
                      )}
                    </div>
                    <h6>
                      OTP code will expire in -{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h6>
                    <div className="logindetail">
                      <div className="fklexvalue">
                        <div className="outerinnerfeild">
                          <label htmlFor="first_name">First name</label>
                          <input
                            {...customerSignupRegister("first_name")}
                            type="text"
                            placeholder="First Name"
                          />
                        </div>
                        <div className="outerinnerfeild">
                          <label htmlFor="last_name">Last name</label>
                          <input
                            {...customerSignupRegister("last_name")}
                            type="text"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>

                      <Box
                        component="div"
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                        gap={3}
                      >
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.first_name?.message}
                        </span>
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.last_name?.message}
                        </span>
                      </Box>

                      <div className="outerinnerfeild">
                        <label htmlFor="phone_number">Contact No</label>
                        <Controller
                          name="phone_number"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              defaultCountry="US"
                              id="phone_number"
                              // countrySelectProps={{ unicodeFlags: true }}
                              // international={true}
                            />
                          )}
                        />
                        {customerSignupErrors?.phone_number && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerSignupErrors.phone_number.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <button type="submit" className={"button-s"}>
                      Confirm
                    </button>
                    <h3
                      onClick={() => loginUser()}
                      className={
                        seconds > 0 || minutes > 0 ? "disabled-link" : "c-p"
                      }
                      style={{
                        color:
                          seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                    >
                      Resend
                    </h3>
                  </div>
                </form>
              </HookForm>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login veriifactionmain"
        show={showProfileModal}
        // onHide={handleClose5}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right Verification samelogin">
            <div className="Container">
              <HookForm {...customerProfileMethods}>
                <form
                  onSubmit={handleCustomerProfileSubmit(
                    onCustomerProfileSubmit
                  )}
                >
                  <div className="mainformhere">
                    <h2>Complete your profile</h2>
                    {/* <h5>{userEmail}</h5> */}
                    {/* <h6>
                      OTP code will expire in -{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h6> */}
                    <div className="logindetail">
                      <div
                        className="outerinnerfeild"
                        // style={{ paddingBottom: 15 }}
                      >
                        <label htmlFor="first_name">First name</label>
                        <input
                          {...customerProfileRegister("first_name")}
                          type="text"
                          placeholder="First Name"
                        />
                        {customerProfileErrors?.first_name && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerProfileErrors.first_name.message}
                          </span>
                        )}
                      </div>

                      <div
                        className="outerinnerfeild"
                        // style={{ paddingBottom: 15 }}
                      >
                        <label htmlFor="last_name">Last name</label>
                        <input
                          {...customerProfileRegister("last_name")}
                          type="text"
                          placeholder="Last Name"
                        />
                        {customerProfileErrors?.last_name && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerProfileErrors.last_name.message}
                          </span>
                        )}
                      </div>

                      {/* <Box
                        component="div"
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                        gap={3}
                      >
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.first_name?.message}
                        </span>
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.last_name?.message}
                        </span>
                      </Box> */}

                      <div className="outerinnerfeild">
                        <label htmlFor="phone_number">Contact No</label>
                        <Controller
                          name="phone_number"
                          control={customerProfileControl}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              defaultCountry="US"
                              id="phone_number"
                              // countrySelectProps={{ unicodeFlags: true }}
                              // international={true}
                            />
                          )}
                        />
                        {customerProfileErrors?.phone_number && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerProfileErrors.phone_number.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <button type="submit" className={"button-s"}>
                      Confirm
                    </button>
                  </div>
                </form>
              </HookForm>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="Login" show={showNewPassModal} centered>
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="mainformhere">
              <HookForm {...setPasswordMethods}>
                <form
                  autoComplete="off"
                  onSubmit={handleSetPasswordpSubmit(onSetPasswordSubmit)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyItems="center"
                    flexDirection="column"
                  >
                    <h2>Set your password</h2>
                    <h4>Welcome , {currentUser?.name}</h4>
                  </Box>

                  <Box display="flex" flexDirection="column" gap="36px">
                    <div className="outerinnerfeild hsdbhsdbhsdbsh">
                      <label htmlFor="password">Password</label>
                      <input
                        {...setPasswordRegister("password")}
                        type={hideshow === false ? "password" : "text"}
                        placeholder="Enter Password"
                        autoComplete="off"
                      />
                      {hideshow === true ? (
                        <img
                          onClick={() => sethideshow(!hideshow)}
                          src="\assets\dummy\show.png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      ) : (
                        <img
                          onClick={() => sethideshow(!hideshow)}
                          src="\assets\dummy\hide (1).png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      )}
                      {setPasswordErrors?.password && (
                        <span className="text-danger" style={{ paddingTop: 5 }}>
                          {setPasswordErrors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="outerinnerfeild hsdbhsdbhsdbsh">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        {...setPasswordRegister("confirmPassword")}
                        type={hideshow2 === false ? "password" : "text"}
                        placeholder="Confirm Password"
                        autoComplete="off"
                      />
                      {hideshow2 === true ? (
                        <img
                          onClick={() => sethideshow2(!hideshow2)}
                          src="\assets\dummy\show.png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      ) : (
                        <img
                          onClick={() => sethideshow2(!hideshow2)}
                          src="\assets\dummy\hide (1).png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      )}
                      {setPasswordErrors?.confirmPassword && (
                        <span className="text-danger" style={{ paddingTop: 5 }}>
                          {setPasswordErrors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                  </Box>

                  <button className="button-s" type="submit">
                    Submit
                  </button>
                </form>
              </HookForm>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login"
        onHide={closeAuthModal}
        show={showAuthModal}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="mainformhere">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Login via</h2>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="30px"
                  mt="50px"
                >
                  <Box
                    component="div"
                    onClick={() => handleAuthType("otp")}
                    sx={{
                      border: "0.5px solid rgba(0, 0, 0, 0.1)",
                      fontFamily: "Lexend",
                      backgroundColor: "#edf0ff",
                      borderRadius: "17px",
                      width: "200px",
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      color: "#7738a2",

                      "&:hover": {
                        backgroundColor: "#4B2763",
                        color: "white",
                      },
                    }}
                  >
                    <h6>OTP</h6>
                  </Box>
                  <Box
                    component="div"
                    onClick={() => handleAuthType("password")}
                    sx={{
                      border: "0.5px solid rgba(0, 0, 0, 0.1)",
                      fontFamily: "Lexend",
                      backgroundColor: "#edf0ff",
                      borderRadius: "17px",
                      width: "200px",
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      color: "#7738a2",

                      "&:hover": {
                        backgroundColor: "#4B2763",
                        color: "white",
                      },
                    }}
                  >
                    <h6>PASSWORD</h6>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login"
        show={showExistPassModal}
        onHide={() => setShowExistPassModal(false)}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="mainformhere">
              <HookForm {...merchantPasswordMethods}>
                <form
                  autoComplete="off"
                  onSubmit={handleMerchantPasswordpSubmit(
                    onMerchantPasswordSubmit
                  )}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyItems="center"
                    flexDirection="column"
                  >
                    <h2>Login via Password</h2>
                    <h4>{watchCustomerEmail("userEmail")}</h4>
                  </Box>
                  <div className="outerinnerfeild hsdbhsdbhsdbsh">
                    <label htmlFor="password">Password</label>
                    <input
                      {...merchantPasswordRegister("password")}
                      type={hideshow === false ? "password" : "text"}
                      placeholder="Enter Password"
                      autoComplete="off"
                    />
                    {hideshow === true ? (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\show.png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    ) : (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\hide (1).png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    )}
                    {merchantPasswordErrors?.password && (
                      <span className="text-danger" style={{ paddingTop: 5 }}>
                        {merchantPasswordErrors.password.message}
                      </span>
                    )}
                  </div>

                  <button className="button-s" type="submit">
                    Submit
                  </button>
                </form>
              </HookForm>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {showBookingModal && (
        <BookingCreationModal
          showModal={showBookingModal}
          navigateToHome={navigateToHome}
          bookingId={localBookingId}
        />
      )}
    </>
  );
};

export default Basket;
