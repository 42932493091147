import React, { useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  getGroupedSpecialistList,
  getSpecialistsData,
} from "../../api/apiListingService";
import {
  formatTime,
  addServiceTime,
  checkTimeOverlap,
  formatTimeslot,
  adjustStartTime,
  adjustEndTime,
} from "../../utils/dateFomater";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { cartState } from "../../recoil/atoms/cartAtom";
import { AddToCartModal } from "../../Components/Dialog/AddToCart";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import { formatAmount } from "../../utils/generalUtils";
import SlotReservationModal from "../../Components/Dialog/SlotReservation";

const ServiceSlider = (props: any) => {
  const [cartItemsList, setCartItemsList] = useRecoilState(cartState);
  const [show2, setShow2] = useState(false);
  const [specialists, setSpecialistData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleClose2 = () => setShow2(false);
  const [currentSpecialist, setCurrentSpecialist] = useState<any>({});
  const [currentService, setCurrentService] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [cartItem, setCartItem] = useState<any>(null);
  const [reservationData, setReservationData] = useState<any>(null);
  const [showReservationModal, setShowReservationModal] =
    useState<boolean>(false);
  const [groupServiceSpecialists, setGroupServiceSpecialist] = useState<any>(
    []
  );
  const [groupSpecialistModal, setGroupServiceSpecialistModal] =
    useState<boolean>(false);
  const history = useHistory();

  const handleShow2 = () => {
    setShow2(true);
  };
  const speciallist = () => {
    handleShow2();
  };

  const handleCloseGroupSpecialistModal = () => {
    setGroupServiceSpecialistModal(false);
  };

  // Filter data based on the search term
  const filteredData = props.data?.storesServicesRes?.filter((item: any) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const fetchServiceSpecialists = async (
    mId: number,
    sId: number,
    sTime: string,
    duration: number,
    serviceName: string
  ) => {
    const eTime: any = addServiceTime(sTime, duration);
    setSpecialistData([]);
    setGroupServiceSpecialist([]);
    try {
      const body = {
        date: props.data?.date,
        start: formatTimeslot(props.data?.date, sTime),
        end: formatTimeslot(props.data?.date, eTime),
        serviceId: sId,
        merchantId: mId,
      };
      setCurrentSpecialist(body);
      // Get services data from api
      const resp = await getSpecialistsData(body);
      if (!resp?.result?.length) {
        toast.error(
          `No specialists are available for the service '${serviceName}' on ${moment(
            props.data?.date
          ).format("MMM DD, YYYY")} `,
          {
            autoClose: 5000,
          }
        );
      } else {
        const prePrepDuration = resp?.prePrepDuration;
        const postPrepDuration = resp?.postPrepDuration;
        // Check availability
        resp?.result.forEach((el: any) => {
          let spCartServices = cartItemsList.filter(
            (arr: any, index: number) =>
              arr.employeeId === parseInt(el.employeeId)
          );
          el.cartStatus = el?.availibility
            ? `Reserve for $${formatAmount(el?.price)}`
            : "Not Available";
          spCartServices.forEach((element: any) => {
            if (element?.cartReserveStatus === "incart") {
              const newStartTime = adjustStartTime(sTime, prePrepDuration);
              // console.log("NEW START TIME: ", newStartTime);

              const newEndTime: any = addServiceTime(
                sTime,
                duration,
                postPrepDuration
              );
              // console.log("NEW END TIME: ", newEndTime);

              const newExistingStartTime = adjustStartTime(
                element?.start,
                element?.prePrepDuration
              );
              const newExistingEndTime = adjustEndTime(
                element?.end,
                element?.postPrepDuration
              );

              // let endTime: any = addServiceTime(sTime, duration);

              let checkOverlap = checkTimeOverlap(
                newExistingStartTime,
                newExistingEndTime,
                formatTimeslot(props.data?.date, newStartTime),
                formatTimeslot(props.data?.date, newEndTime)
              );

              if (!checkOverlap) {
                el.availibility = checkOverlap;
                el.cartStatus = checkOverlap
                  ? `Reserve for $${formatAmount(el?.price)}`
                  : "Reserved";
              }
            }
          });
        });
        setSpecialistData(resp.result);
        speciallist();
      }
    } catch (error: any) {
      toast.error(
        `No specialists exist for the service '${serviceName}' on ${moment(
          props.data?.date
        ).format("MMM DD, YYYY")}`,
        {
          autoClose: 5000,
        }
      );
      console.error("Error fetching data:", error);
    }
  };

  const fetchGroupServiceSpecialists = async (
    sId: number,
    serviceName: string
  ) => {
    try {
      setSpecialistData([]);
      setGroupServiceSpecialist([]);
      const body = {
        startTime: moment(props.data?.date).utc().startOf("day").toISOString(),
        endTime: moment(props.data?.date)
          .utc()
          .add(1, "day")
          .startOf("day")
          .toISOString(),
      };
      // Get services data from api
      const resp = await getGroupedSpecialistList(sId, body);
      if (!resp?.res?.length) {
        toast.error(
          `No specialists are available for the service '${serviceName}' on ${moment(
            props.data?.date
          ).format("MMM DD, YYYY")} `,
          {
            autoClose: 5000,
          }
        );
      } else {
        setGroupServiceSpecialist(resp?.res);
        setGroupServiceSpecialistModal(true);
      }
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(
          `No specialists exist for the service '${serviceName}' on ${moment(
            props.data?.date
          ).format("MMM DD, YYYY")}`,
          {
            autoClose: 5000,
          }
        );
      }
      console.error("Error fetching data:", error);
    }
  };

  const handleReservationModal = () => setShowReservationModal(false);

  const handleAddToCart = () => {
    setShowReservationModal(false);
    addServiceToCartCheck(
      reservationData?.sp,
      reservationData?.bookingDate,
      reservationData?.cartItemStatus
    );
  };

  const addServiceToCartCheck = (
    sp: any,
    bookingDate: any,
    cartItemStatus: any
  ) => {
    const tip = { tip: 0 };
    const bDate = { bookingDate: new Date(bookingDate) };
    const cartReserveStatus = { cartReserveStatus: cartItemStatus };
    let priceObj = {
      currentprice: "",
    };
    if (sp?.price) {
      priceObj.currentprice = formatAmount(sp?.price);
    }
    const mergedObject = Object.assign(
      {},
      props?.data?.merchantDetails,
      sp,
      currentService,
      currentSpecialist,
      tip,
      bDate,
      cartReserveStatus,
      priceObj
    );
    if (
      cartItemsList.length === 0 ||
      cartItemsList.every(
        (item: any) => item.merchantId === currentSpecialist.merchantId
      )
    ) {
      setCartItemsList((prv: any) => {
        return [...prv, mergedObject];
      });

      setSpecialistData([]);
      handleClose2();

      if (cartItemStatus === "incart") {
        toast.success("Service added to the basket", {
          autoClose: 5000,
        });
      }
    } else {
      setCartItem(mergedObject);
      setShowModal(true);
    }
  };

  const addGiftCard = (giftCard: any) => {
    giftCard = {
      ...giftCard,
      servicetype: giftCard?.serviceType,
      id: giftCard?.serviceId,
      merchantId: props?.data?.merchantDetails?.id,
      currentprice: giftCard?.price,
    };
    if (
      cartItemsList.length === 0 ||
      cartItemsList.every(
        (item: any) => item.merchantId === props?.data?.merchantDetails?.id
      )
    ) {
      setCartItemsList((prv: any) => {
        return [...prv, giftCard];
      });

      toast.success("Gift Card added to the basket", {
        autoClose: 5000,
      });
    } else {
      setCartItem(giftCard);
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleResetAndNewItems = () => {
    if (cartItem) {
      if (
        cartItem?.serviceType === "Giftcard" ||
        cartItem?.servicetype === "Giftcard"
      ) {
        setCartItemsList([cartItem]);
        handleClose();
        toast.success("Gift Card added to the basket", {
          autoClose: 5000,
        });
      } else {
        setCartItemsList([cartItem]);
        setSpecialistData([]);
        handleClose();

        handleClose2();

        toast.success("Service added to the basket", {
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <>
      <div className="FrontMain_Stores serviceslider">
        <div className="main_upper_bar">
          <div className="leftside">
            <div className="anme">
              <h4>Services</h4>
            </div>
          </div>
          <div className="rightside">
            <input
              type="text"
              placeholder="Filter by service name"
              onChange={(e) => setSearchTerm(e.target.value)}
            ></input>
            <img
              src="\assets\prof\Vector (11).svg"
              alt="cnctwltimg"
              className="storeimageleft"
            />
          </div>
        </div>
        <div className="row">
          {filteredData?.map((service: any, index: number) => (
            <div key={index} className="col col-xxl-2 pppppp">
              {/* <div className='cardmain'>
                                                <div className='uppercard'>
                                                    <img src={service.coverphoto} alt="cnctwltimg" className="cnctwltimg" />
                                                    <div className='rectangle_shape'>
                                                        <h4>{service.servicename}</h4>
                                                    </div>
                                                </div>
                                                <div className='lowercard'>
                                                    <div className='upperheading'>
                                                        <div className='rirr'>
                                                            <h5>{service.serviceDuration} mins</h5>
                                                        </div>
                                                        <div className='upperheadings'>
                                                            <h3>${service.currentprice}.0</h3>
                                                        </div>
                                                    </div>
                                                    <div className='slotshere'>
                                                        {shop.slots.map((sl: any) => (
                                                            <div
                                                                onClick={() => { setIsShown(true); fetchServiceSpecialists(shop.id, service.id, sl, service.serviceDuration); setService(service) }}
                                                                className='design'>
                                                                <h5>{formatTime(sl)}</h5>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div> */}

              <div className="cardmain">
                <div className="uppercard">
                  <img
                    src={
                      service?.cardPhoto
                        ? service?.cardPhoto
                        : "/assets/storeimage/placeholder-img.png"
                    }
                    alt="cnctwltimg"
                    className="cnctwltimg"
                  />
                  <div className="rectangle_shape">
                    <h4>{service?.name}</h4>
                  </div>
                </div>

                <div className="lowercard">
                  <div className="upperheading">
                    {/* <h4>{service?.name}</h4>
                                    <h3>${service?.price}</h3> */}
                    <div className="rirr">
                      {service.serviceType === "Giftcard" ? (
                        <h5 style={{ lineHeight: "unset" }}>Gift Card Value</h5>
                      ) : (
                        <h5 style={{ lineHeight: "unset" }}>
                          {service?.duration} mins
                        </h5>
                      )}
                    </div>
                    <div
                      className="upperheadings"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "2px",
                      }}
                    >
                      {service?.serviceType !== "Giftcard" && (
                        <span style={{ fontSize: "14px", lineHeight: "unset" }}>
                          Starts at
                        </span>
                      )}
                      <h3 style={{ lineHeight: "unset" }}>${service?.price}</h3>
                    </div>
                  </div>
                  {/* <div className='outrermainmain'>
                                    <div className='main-outer'>
                                        <div className='rightside'>
                                            <div className='ppppp'></div>
                                            <div className='rirr'>
                                                <h5>{service?.duration} mins</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                  <>
                    {service.serviceType === "Giftcard" ? (
                      <>
                        <div className="slotshere">
                          <div className="addtocart">
                            <button
                              onClick={() => {
                                addGiftCard(service);
                              }}
                            >
                              Add to basket
                            </button>
                          </div>
                        </div>
                      </>
                    ) : service?.serviceType === "Group" ? (
                      <div className={`slotshere slot-${index}`}>
                        <div className="addtocart">
                          <button
                            onClick={() => {
                              fetchGroupServiceSpecialists(
                                service?.serviceId,
                                service?.name
                              );
                            }}
                          >
                            View Group Session Details
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Box
                        className="slotshere"
                        justifyContent={
                          props.data?.merchantDetails?.timeSlots?.length > 2
                            ? "space-between"
                            : "flex-start!important"
                        }
                      >
                        {props.data?.merchantDetails?.timeSlots?.map(
                          (sl: any, index: number) => (
                            <div
                              key={index}
                              onClick={() => {
                                fetchServiceSpecialists(
                                  props.data?.merchantDetails?.id,
                                  service.serviceId,
                                  sl,
                                  service.duration,
                                  service?.name
                                );
                                setCurrentService({
                                  ...service,
                                  currentprice: service?.price,
                                });
                              }}
                              className="design"
                              style={{ width: "100%" }}
                            >
                              <h5>{formatTime(sl)}</h5>
                            </div>
                          )
                        )}
                      </Box>
                    )}
                  </>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal
          className="detailmodalspecialist"
          show={show2}
          onHide={handleClose2}
          centered
        >
          <Modal.Body>
            <div className="arrowimg">
              <div className="speciallist">
                <h6>Select Specialist</h6>
              </div>
              <div
                className="outerdivspeciallist"
                style={{
                  gap: "40px",
                  justifyContent:
                    specialists?.length > 0 ? "flex-start" : "center",
                }}
              >
                {specialists?.length > 0
                  ? specialists.map((sp: any, index: number) => (
                      <div
                        key={index}
                        className="innerprofile"
                        style={{ width: "auto" }}
                      >
                        <div className="upperprofile">
                          <Link
                            to={{
                              pathname: `/speciallist/detail/${sp.ssEmpId}`,
                              state: {
                                params: {
                                  merchantId: props.data?.merchantDetails?.id,
                                },
                              },
                            }}
                          >
                            <img
                              src="\assets\allstore\Group 30735.svg"
                              alt="cnctwltimg"
                              className="calenderimg"
                            />
                          </Link>
                          <img
                            src={
                              sp?.coverPhoto
                                ? sp?.coverPhoto
                                : "/assets/storeimage/placeholder-img.png"
                            }
                            alt="cnctwltimg"
                            className="profileimg"
                          />
                        </div>
                        <div className="lowerside">
                          <div
                            className="maintool"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h4>{sp.nickName}</h4>
                            <span className="tooltiptext">{sp.nickName}</span>
                          </div>
                          <div className="outmainh5">
                            <h5>Location</h5>
                            <div className="mainouterlocation">
                              <img
                                src="\assets\allstore\carbon_location.svg"
                                alt="cnctwltimg"
                                className="calenderimg"
                              />
                              <div className="text">
                                <p>{sp.location}</p>
                                <h6>
                                  {props.data?.merchantDetails?.merchant_name}
                                </h6>
                              </div>
                            </div>
                          </div>
                          {/* <div>
                          <span className="badge bg-secondary">
                            {sp?.price ? `$ ${formatAmount(sp?.price)}` : ""}
                          </span>
                        </div> */}
                          <button
                            // className={!sp.availibility ? "incart" : ""}
                            className={
                              sp?.cartStatus === "Reserved"
                                ? "incart"
                                : sp?.cartStatus === "Not Available"
                                ? "unavailable"
                                : ""
                            }
                            style={{ width: "138px" }}
                            onClick={() => {
                              setReservationData({
                                sp,
                                bookingDate: props.data?.date,
                                cartItemStatus: "incart",
                              });
                              setShowReservationModal(true);
                            }}
                          >
                            {/* {!sp.availibility
                            ? "Reserved"
                            : `Reserve for $${formatAmount(sp?.price)}`} */}
                            {sp?.cartStatus}
                          </button>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="detailmodalspecialist"
          show={groupSpecialistModal}
          onHide={handleCloseGroupSpecialistModal}
          centered
        >
          <Modal.Body>
            <div className="arrowimg">
              <div className="speciallist">
                <h6>Select Specialist</h6>
              </div>
              <div
                className="outerdivspeciallist"
                style={{
                  gap: "40px",
                  justifyContent:
                    groupServiceSpecialists?.length > 0
                      ? "flex-start"
                      : "center",
                }}
              >
                {groupServiceSpecialists?.length > 0
                  ? groupServiceSpecialists.map((sp: any, index: number) => (
                      <div
                        key={index}
                        className="innerprofile"
                        style={{ width: "auto" }}
                      >
                        <div className="upperprofile">
                          <img
                            src={
                              sp?.displayPicture
                                ? sp?.displayPicture
                                : "/assets/storeimage/placeholder-img.png"
                            }
                            alt="cnctwltimg"
                            className="profileimg"
                          />
                        </div>
                        <div className="lowerside">
                          <div
                            className="maintool"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h4>{sp?.employeeName}</h4>
                            <span className="tooltiptext">
                              {sp?.employeeName}
                            </span>
                          </div>
                          <div className="outmainh5">
                            <h5>Location</h5>
                            <div className="mainouterlocation">
                              <img
                                src="\assets\allstore\carbon_location.svg"
                                alt="cnctwltimg"
                                className="calenderimg"
                              />
                            </div>
                          </div>
                          <button
                            className={`reserveBtn`}
                            style={{ width: "138px" }}
                            onClick={() => {
                              history.push({
                                pathname: `/groupsession/specialist/${sp?.employeeId}`,
                                state: {
                                  params: {
                                    serviceId: sp?.serviceId,
                                    merchantId: props.data?.merchantDetails?.id,
                                  },
                                },
                              });
                            }}
                          >
                            View Availability
                          </button>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <AddToCartModal
        showModal={showModal}
        handleClose={handleClose}
        handleResetAndNewItems={handleResetAndNewItems}
      />
      {showReservationModal && reservationData && (
        <SlotReservationModal
          showModal={showReservationModal}
          handleClose={handleReservationModal}
          serviceName={currentService?.servicename}
          specialistName={reservationData?.sp?.nickName}
          timezone={reservationData?.sp?.timezone}
          startDate={currentSpecialist?.start}
          endDate={currentSpecialist?.end}
          bookingDate={reservationData?.bookingDate}
          handleAddToCart={handleAddToCart}
        />
      )}
    </>
  );
};

export default ServiceSlider;
