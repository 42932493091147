import React from 'react'
import './account.scss';
// import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
const MerchantPassword = () => {
    const history = useHistory()
    const Pushroutee = () =>{
        history.push("/")
    }
    return (
        <section className="Login samelogin">
            <div className='container-fluid p-0'>
                <div className='row p-0' >
                    <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 p-0'>
                        <div className='centerdiv_left'>
                            <img src="\assets\img5643755-01 1.svg" alt="cnctwltimg" className="cnctwltimg" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0">
                        <div className='centerdiv_right'>
                            <div className='outermain_form'>
                                <img src="\assets\logobookbetter.svg" alt="cnctwltimg" className="cnctwltimg" />
                                <h2>Registered as merchant</h2>
                                <h4>Welcome Back , Smoke show studio Merchant</h4>
                                {/* <div className='outerinnerfeild'>
                                    <label htmlFor="fname">Email</label>
                                    <input type="text" id="fname" name="fname" placeholder='Enter Email' />
                                </div> */}
                                <div className='outerinnerfeild'>
                                    <label htmlFor="fname">Password</label>
                                    <input type="password" id="fname" name="fname" placeholder='Enter Password' />
                                </div>
                                <div className='forgetpasswordfeild'>
                                    <h6>Forgot Password ?</h6>
                                </div>
                                <button onClick={Pushroutee} className='button-s'>Continue</button>
                                {/* <h3>Don’t have an account ? <span><Link className='opopopopop' to='/signup'>Register Now</Link></span></h3> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MerchantPassword