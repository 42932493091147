import React,{useState} from 'react'
import './account.scss';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { registerUser } from '../../api/apiAuthentications';

const SignUp = () => {
    const history = useHistory()
   
    const [userEmail, setUserEmail] = useState<any>();
    const [firstName, setFirstName] = useState<any>();
    const [lastName, setLastName] = useState<any>();
    const [phone, setPhone] = useState<any>();
    const [signUpResponse, setResp] = useState<any>();

    // Register user api call
    const registerCustomer = async () => {
        try {
            // Get services data from api
            const resp = await registerUser({first_name:firstName,last_name:lastName,email_address:userEmail,phone_number:phone});
            resp.statusCode = 200;
            setResp(resp);
            setTimeout(() => {
                history.push("/")
            }, 1000);
        } catch (error:any){
            setResp(error.response?.data);
            console.error('Error fetching data:', error.message);
        }
    }
    return (
        <section className="Signup samelogin">
            <div className='container-fluid p-0'>
                <div className='row p-0' >
                    <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 p-0'>
                        <div className='centerdiv_left'>
                            <img src="\assets\img5643755-01 1.svg" alt="cnctwltimg" className="cnctwltimg" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0">
                        <div className='centerdiv_right'>
                            <div className='outermain_form'>
                                <img src="\assets\logobookbetter.svg" alt="cnctwltimg" className="cnctwltimg" />
                                <h2>Lets Create Profile Account</h2>
                                <h4>Hello user , you have a grateful journey</h4>
                                { signUpResponse?.statusCode === 200 ? (
                                       <h6 className='c-g m-b-40'>Registration Successful. Please login</h6>
                                    ) : (
                                        <h6 className='c-red m-b-40'>{signUpResponse?.message}</h6>
                                    )
                                }
                                <div className='outerinnerfeild'>
                                    <label htmlFor="fname">First Name</label>
                                    <input type="text" id="fname" name="fname" placeholder='Enter First Name' onChange={(e: any) => setFirstName(e.target.value)}/>
                                </div>
                                <div className='outerinnerfeild'>
                                    <label htmlFor="fname">Last Name</label>
                                    <input type="text" id="fname" name="fname" placeholder='Enter Last Name' onChange={(e: any) => setLastName(e.target.value)}/>
                                </div>
                                <div className='outerinnerfeild'>
                                    <label htmlFor="fname">Email</label>
                                    <input type="text" id="fname" name="fname" placeholder='Enter Email' onChange={(e: any) => setUserEmail(e.target.value)}/>
                                    {/* <h6></h6> */}
                                </div>
                                <div className='outerinnerfeild'>
                                    <label htmlFor="fname">Phone</label>
                                    <input type="number" id="fname" name="fname" placeholder='Enter Phone' onChange={(e: any) => setPhone(e.target.value)}/>
                                </div>
                                <button className='button-s'  onClick={() =>{registerCustomer()}}>Create Profile</button>
                                <Link className='opopopopop' to='/'> <h3>Already have an account ? <span>Login</span></h3></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignUp