import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./booking.scss";
import { useHistory } from "react-router-dom";
import {
  acceptMutipleBookings,
  cancelMutipleBookings,
} from "../../api/apiListingService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import moment from "moment-timezone";

const TableBooking = (props: any) => {
  const history = useHistory();
  const calenderview = () => {
    history.push("/calender");
  };
  const [opens, setOpens] = useState<any>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectAlls, setSelectAlls] = useState<boolean>(false);
  const [userType, setUserType] = useState<any>();

  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  const [show24, setShow24] = useState(false);
  const handleClose24 = () => setShow24(false);
  const handleShow24 = () => setShow24(true);
  const [count, setCount] = useState(0);
  const handleSingleCheckboxChange = (rowId: number) => {
    const toggleAll = !selectAll;
    const updatedRows = props?.bookings?.map((row: any) =>
      row.id === rowId ? (row.checked = !row.checked) : false
    );
    setCount(
      props?.bookings?.filter((row: any) => row.checked === true).length
    );
    setSelectAll(toggleAll);
    setSelectAlls(updatedRows.every((row: any) => row.checked));
  };

  const handleAllCheckboxChange = () => {
    const toggleAll = !selectAlls;
    props?.bookings?.map((row: any) => (row.checked = toggleAll));
    setCount(
      props?.bookings?.filter((row: any) => row.checked === true).length
    );
    setSelectAlls(toggleAll);
  };
  const acceptBooking = async () => {
    try {
      setOpens(true);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let bookingIds: any = [];
        props?.bookings.forEach((element: any) => {
          if (element.checked && element?.bookingStatus !== "cancelled") {
            bookingIds.push(element.id);
          }
        });
        if (bookingIds.length === 0) {
          toast.error("Please Select Bookings", {
            autoClose: 5000,
          });
          setOpens(false);
          return false;
        }
        const resp = await acceptMutipleBookings(
          { bookingIds: bookingIds },
          data?.access_token
        );
        toast.success(resp.message, {
          autoClose: 5000,
        });
        props?.fetchBookingData();
        handleClose24();
        setOpens(false);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      handleClose24();
      setOpens(false);
    }
  };

  const cancelBooking = async () => {
    try {
      setOpens(true);
      if (sessionStorage.getItem("user")) {
        let data: any = sessionStorage.getItem("user");
        data = JSON.parse(data);
        let bookingIds: any = [];
        props?.bookings.forEach((element: any) => {
          if (element.checked && element?.bookingStatus !== "cancelled") {
            bookingIds.push(element.id);
          }
        });
        if (bookingIds.length === 0) {
          toast.error("Please Select Bookings", {
            autoClose: 5000,
          });
          setOpens(false);
          return false;
        }

        const resp = await cancelMutipleBookings(
          { bookingIds: bookingIds },
          data?.access_token
        );
        if (resp?.error && resp?.error?.error) {
          toast.error(resp?.error?.error, {
            autoClose: 5000,
          });
        } else {
          toast.success(resp.message, {
            autoClose: 5000,
          });
        }
        props?.fetchBookingData();
        handleClose23();
        setOpens(false);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      toast.error(error.response?.data?.message, {
        autoClose: 5000,
      });
      handleClose23();
      setOpens(false);
    }
  };
  useEffect(() => {
    let data: any = sessionStorage.getItem("user");
    data = JSON.parse(data);
    setUserType(data?.userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //var email = props?.bookings?.name;
  //var emailWithoutDomain = email?.split('@')[0];
  return (
    <>
      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="rightside action-btns">
        {/* <button className={userType === "customer" ? 'd-n' : 'mark oo'}>
                Mark Paid
            </button> */}
        {userType !== "customer" ? (
          <>
            <button
              className={
                props?.bookings?.status === "cancelled" ||
                props?.bookings?.status === "completed"
                  ? "d-n"
                  : "cancel oo"
              }
              onClick={handleShow23}
            >
              Cancel
            </button>
            <button
              className={
                props?.bookings?.status === "cancelled" ||
                props?.bookings?.status === "completed"
                  ? "d-n"
                  : "complete oo disable-btn"
              }
            >
              Complete
            </button>
            <button
              className={
                props?.bookings?.status === "accepted" ||
                props?.bookings?.status === "cancelled" ||
                props?.bookings?.status === "completed"
                  ? "d-n"
                  : "accept oo"
              }
              onClick={handleShow24}
            >
              Accept
            </button>
          </>
        ) : (
          <></>
        )}

        <button
          className={userType === "customer" ? "d-n" : "oooo"}
          onClick={calenderview}
        >
          <img
            src="\assets\allstore\calenderbooking.svg"
            alt="cnctwltimg"
            className="storeimageleft"
          />
          <span>Calendar</span>
        </button>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className={userType === "customer" ? "d-n" : "cancel oo"}>
                <div className="form-check">
                  <input
                    checked={selectAlls}
                    onChange={handleAllCheckboxChange}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </th>
              <th>Booking ID</th>
              <th className={userType === "customer" ? "d-n" : ""}>Name</th>
              <th>Store</th>
              <th>Created Date</th>
              <th>No Of Services</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props?.bookings?.map((booking: any) => (
              <tr>
                <td className={userType === "customer" ? "d-n" : "cancel oo"}>
                  <div className="form-check">
                    {booking?.bookingStatus === "cancelled" ? (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        disabled={true}
                      />
                    ) : (
                      <input
                        checked={booking.checked}
                        onChange={() => handleSingleCheckboxChange(booking.id)}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                      />
                    )}
                  </div>
                </td>
                <Link className="textssss" to={`/bookingdetail/${booking.id}`}>
                  <td>{booking.id}</td>{" "}
                </Link>
                <td className={userType === "customer" ? "d-n" : ""}>
                  {booking.customerName}
                </td>
                <td>{booking.store}</td>
                <td>{moment(booking.bookingDate).format("MMM DD, YYYY")}</td>
                <td>{booking.servicesNum}</td>
                <td>$ {booking.grossAmount}</td>
                <td>
                  <button className="accepted_inner">
                    <img
                      src="\assets\prof\subway_tick.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <p>{booking.bookingStatus}</p>
                  </button>
                </td>
                <td>
                  <Link
                    className="textssss"
                    to={`/bookingdetail/${booking.id}`}
                  >
                    <button className="buttonright">
                      <img
                        src="\assets\prof\🦆 icon _eye_.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        className="detailmodal detailbooking bookingdetailcancelbutton"
        show={show23}
        onHide={handleClose23}
        centered
      >
        <Modal.Body>
          <div className="arrowimg">
            <h4>
              Cancelling{" "}
              <span className={count > 1 ? "d-n" : ""}>this booking</span>
              <span className={count === 1 || count === 0 ? "d-n" : ""}>
                these bookings
              </span>{" "}
              will cancel all its services.
            </h4>
            {/* <h5>Your time is exceeded money will not refunded</h5> */}
            <h5> </h5>
            <button
              className="buttoncancel"
              onClick={() => {
                cancelBooking();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="detailmodal detailbooking bookingdetailcancelbutton"
        show={show24}
        onHide={handleClose24}
        centered
      >
        <Modal.Body>
          <div className="arrowimg">
            <h4>
              Accepting{" "}
              <span className={count > 1 ? "d-n" : ""}>this booking</span>
              <span className={count === 1 || count === 0 ? "d-n" : ""}>
                these bookings
              </span>{" "}
              will mark all its pending services as 'Accepted'.
            </h4>
            {/* <h5>Booking is successfully accepted
                                and added to calendar</h5> */}
            <h5> </h5>
            <button
              className="buttoncancel acceptbutton"
              onClick={() => {
                acceptBooking();
              }}
            >
              Accept
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TableBooking;
