import { useState, useEffect } from "react";
import "./account.scss";
import { Modal } from "react-bootstrap";
import {
  authenticateUserEmail,
  verifyUserEmail,
  checkUserEmail,
  registerUser,
  fetchUserProfile,
  updateUserProfile,
  setUserPasswordReq,
} from "../../api/apiAuthentications";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useForm, FormProvider as Form, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil/atoms/userAtom";
import { Box } from "@mui/material";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const phoneNumberSchema = yup
  .string()
  .required("Phone number is required")
  .test(
    "isValidPhoneNumber",
    "Invalid phone number",
    (value) => !value || isValidPhoneNumber(value)
  );

const customerEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email"),
});

const customerOtpSchema = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter a valid OTP")
    .matches(/^\d{6}$/, "Otp must be a 6-digit number"),
});

const customerSignupSchema = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter a valid OTP")
    .matches(/^\d{6}$/, "Otp must be a 6-digit number"),
  first_name: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(15, "First name must be at most 15 characters"),
  last_name: yup.lazy((value) =>
    value
      ? yup
          .string()
          .min(2, "Last name must be at least 2 characters")
          .max(15, "Last name must be at most 15 characters")
      : yup.string()
  ),
  phone_number: phoneNumberSchema,
});

const merchantPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters"),
});

const setPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const customerProfileSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(15, "First name must be at most 15 characters"),
  last_name: yup.lazy((value) =>
    value
      ? yup
          .string()
          .min(2, "Last name must be at least 2 characters")
          .max(15, "Last name must be at most 15 characters")
      : yup.string()
  ),
  phone_number: phoneNumberSchema,
});

const LoginModal = (props: any) => {
  const [cUser, setUserdata] = useState<any>([]);
  const [muser, setMUserdata] = useState<any>([]);
  const [userEmail, setUserEmail] = useState<any>();
  const [hideshow, sethideshow] = useState<any>(false);
  const [hideshow2, sethideshow2] = useState<any>(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [userType, setUserType] = useState<any>(currentUser?.userType || "");
  const [userId, setUserId] = useState<number | null>(null);
  const [isPasswordSet, setIsPasswordSet] = useState<boolean>(
    currentUser?.isPasswordSet || false
  );
  const [isProfileComplete, setIsProfileComplete] = useState<boolean>(
    currentUser?.isProfileComplete || false
  );
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showNewPassModal, setShowNewPassModal] = useState<boolean>(false);
  const [showExistPassModal, setShowExistPassModal] = useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  // const [rememberMe, setRememberMe] = useState<boolean>(false);

  const customerEmailMethods = useForm({
    resolver: yupResolver(customerEmailSchema),
  });
  const {
    register: customerEmailRegister,
    handleSubmit: handleCustomerEmailSubmit,
    formState: { errors },
    reset: customerEmailReset,
  } = customerEmailMethods;

  const onCustomerEmailSubmit = (data: any) => {
    setUserEmail(data?.email);
    authenticateUser(data?.email);
  };

  const handleClose1 = () => {
    props?.handleClose1();
    customerEmailReset();
  };

  const customerOtpMethods = useForm({
    resolver: yupResolver(customerOtpSchema),
  });
  const {
    register: customerOtpRegister,
    handleSubmit: handleCustomerOtpSubmit,
    formState: { errors: customerOtpErrors },
    reset: customerOtpReset,
  } = customerOtpMethods;

  const onCustomerOtpSubmit = (data: any) => {
    verifyOtp(data);
  };

  const handleClose4 = () => {
    setShow4(false);
    customerOtpReset();
  };

  const customerSignupMethods = useForm({
    resolver: yupResolver(customerSignupSchema),
  });
  const {
    register: customerSignupRegister,
    handleSubmit: handleCustomerSignupSubmit,
    formState: { errors: customerSignupErrors },
    reset: customerSignupReset,
    control: customerSignupControl,
  } = customerSignupMethods;

  const onCustomerSignupSubmit = (data: any) => {
    verifyOtp(data, true);
  };

  const handleClose5 = () => {
    setShow5(false);
    customerSignupReset();
  };

  const merchantPasswordMethods = useForm({
    resolver: yupResolver(merchantPasswordSchema),
  });
  const {
    register: merchantPasswordRegister,
    handleSubmit: handleMerchantPasswordpSubmit,
    formState: { errors: merchantPasswordErrors },
    reset: merchantPasswordReset,
  } = merchantPasswordMethods;

  const onMerchantPasswordSubmit = (data: any) => {
    if (isPasswordSet && userType === "customer") {
      loginUserWithPassword(data?.password);
    } else {
      verifyMerchant(data?.password);
    }
  };

  const handleClose3 = () => {
    setShow3(false);
    merchantPasswordReset();
  };

  const setPasswordMethods = useForm({
    resolver: yupResolver(setPasswordSchema),
  });
  const {
    register: setPasswordRegister,
    handleSubmit: handleSetPasswordpSubmit,
    formState: { errors: setPasswordErrors },
  } = setPasswordMethods;

  const onSetPasswordSubmit = (data: any) => {
    setUserPassword(data);
  };

  // Customer profile methods
  const customerProfileMethods = useForm({
    resolver: yupResolver(customerProfileSchema),
  });
  const {
    register: customerProfileRegister,
    handleSubmit: handleCustomerProfileSubmit,
    formState: { errors: customerProfileErrors },
    reset: customerProfileReset,
    control: customerProfileControl,
  } = customerProfileMethods;

  const onCustomerProfileSubmit = (data: any) => {
    updateUserProfileReq(data);
  };

  const handleCloseProfileModal = () => {
    customerProfileReset();
    setShowProfileModal(false);
  };

  const updateUserProfileReq = async (data: any) => {
    try {
      // Get services data from api
      const resp = await updateUserProfile(currentUser?.access_token, data);
      if (resp && resp?.message) {
        let profileData = {
          ...currentUser,
          isProfileComplete: true,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
          name: `${data?.first_name} ${data?.last_name}`,
        };
        setCurrentUser(profileData);
        toast.success(resp?.message);
        if (!isPasswordSet) {
          handleCloseProfileModal();
          handleClose4();
          setShowNewPassModal(true);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Check user email api call
  const authenticateUser = async (email = "") => {
    try {
      // Get services data from api
      const resp = await checkUserEmail({ email: email ? email : userEmail });
      setUserType(resp?.user_type);
      setUserId(resp?.user_id);
      if (resp?.user_type === "customer") {
        props?.setShow1(false);
        customerEmailReset();
        setIsPasswordSet(resp?.isPasswordSet);
        setIsProfileComplete(resp?.isProfileComplete);
        setUserdata(resp);
        if (resp?.auth_type === "otp" && resp?.isPasswordSet) {
          setShowAuthModal(true);
        } else if (resp?.auth_type === "otp" && !resp?.isPasswordSet) {
          setShow4(true);
          setMinutes(5);
          setSeconds(0);
        }
      } else if (
        resp?.user_type === "merchant" ||
        resp?.user_type === "specialist"
      ) {
        setUserType(resp?.user_type);
        props?.setShow1(false);
        customerEmailReset();
        setShow3(true);
        setMUserdata(resp);
      }
    } catch (error: any) {
      if (error.response?.data?.statusCode === 404) {
        setUserType("customer");
        props?.setShow1(false);
        customerEmailReset();
        setShow5(true);
        setMinutes(5);
        setSeconds(0);
        registerCustomer(email);
      }
      //setResp(error.response.data);
      console.error("Error fetching data:", error.message);
    }
  };

  const handleAuthType = (authType: string) => {
    closeAuthModal();
    if (authType === "otp") {
      loginUser();
      setShow4(true);
    } else if (authType === "password") {
      setShowExistPassModal(true);
    }
  };

  const closeAuthModal = () => {
    setShowAuthModal(false);
  };

  // Login user api call
  const loginUser = async () => {
    try {
      // Get services data from api
      const resp = await authenticateUserEmail({ email: userEmail });
      setMinutes(5);
      setSeconds(0);
      setUserdata(resp);
    } catch (error: any) {
      //setResp(error.response.data);
      console.error("Error fetching data:", error.message);
    }
  };

  function isObject(variable: any): variable is object {
    return (
      variable !== null &&
      typeof variable === "object" &&
      !Array.isArray(variable)
    );
  }

  // Verify OTP user api call
  const verifyOtp = async (data: any, newUser: boolean = false) => {
    const body: any = {
      user_id: cUser?.user_id,
      otp: data?.otp,
    };
    if (data?.first_name) {
      body.first_name = data?.first_name;
    }
    if (data?.last_name) {
      body.last_name = data?.last_name;
    }
    if (data?.phone_number) {
      body.phone_number = data?.phone_number;
    }
    let profileData = {};
    try {
      // Get services data from api
      const resp = await verifyUserEmail(body);
      resp.email = userEmail;
      resp.userType = userType;
      profileData = {
        ...resp,
        userId: userId ? userId : cUser?.user_id ? cUser?.user_id : null,
        isPasswordSet,
        isProfileComplete,
      };
      if (newUser && resp && resp?.access_token) {
        profileData = {
          ...profileData,
          isProfileComplete: true,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
          name: `${data?.first_name} ${data?.last_name}`,
        };
        setCurrentUser(profileData);
        sessionStorage.setItem("user", JSON.stringify(resp));
        handleCloseProfileModal();
        handleClose4();
        handleClose5();
        setShowNewPassModal(true);
      } else if (resp && resp?.access_token) {
        sessionStorage.setItem("user", JSON.stringify(resp));
        setCurrentUser(profileData);

        try {
          const userProfileResp = await fetchUserProfile(resp?.access_token);
          if (userProfileResp && isObject(userProfileResp)) {
            profileData = {
              ...profileData,
              ...userProfileResp,
              name: `${(userProfileResp as any)?.first_name ?? ""} ${
                (userProfileResp as any)?.last_name ?? ""
              }`,
            };
            setCurrentUser(profileData);
          }
        } catch (error: any) {
          toast.error(error.response?.data?.message);
        }

        if (isProfileComplete && isPasswordSet) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return;
        }
        if (!isProfileComplete) {
          customerProfileReset({
            first_name: (profileData as any)?.first_name ?? "",
            last_name: (profileData as any)?.last_name ?? "",
            phone_number: (profileData as any)?.phone_number ?? "",
          });
          handleClose4();
          setShowProfileModal(true);
        } else if (!isPasswordSet) {
          handleCloseProfileModal();
          handleClose4();
          setShowNewPassModal(true);
        }
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message);
      console.error("Error fetching data:", error.message);
    }
  };

  // Authenticate User with password api call
  const loginUserWithPassword = async (merchantPassword: string) => {
    let profileData = {};
    try {
      // Get services data from api
      const resp = await authenticateUserEmail({
        email: userEmail,
        password: merchantPassword,
      });

      resp.email = userEmail;
      resp.userType = userType;
      profileData = {
        ...resp,
        userId: userId ? userId : cUser?.user_id ? cUser?.user_id : null,
        isPasswordSet,
        isProfileComplete,
      };
      sessionStorage.setItem("user", JSON.stringify(profileData));
      setCurrentUser(profileData);

      try {
        const userProfileResp = await fetchUserProfile(resp?.access_token);
        if (userProfileResp && isObject(userProfileResp)) {
          profileData = {
            ...profileData,
            ...userProfileResp,
            name: `${(userProfileResp as any)?.first_name ?? ""} ${
              (userProfileResp as any)?.last_name ?? ""
            }`,
          };
          sessionStorage.setItem("user", JSON.stringify(profileData));
          setCurrentUser(profileData);
        }
      } catch (error: any) {
        toast.error(error.response?.data?.message);
      }

      if (isProfileComplete && isPasswordSet) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
        return;
      }
      if (!isProfileComplete) {
        customerProfileReset({
          first_name: (profileData as any)?.first_name ?? "",
          last_name: (profileData as any)?.last_name ?? "",
          phone_number: (profileData as any)?.phone_number ?? "",
        });
        handleClose4();
        setShowExistPassModal(false);
        setShowProfileModal(true);
      }
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }
  };

  const setUserPassword = async (data: any) => {
    try {
      // Get services data from api
      const resp = await setUserPasswordReq(currentUser?.access_token, data);
      if (resp && resp?.message) {
        toast.success(resp?.message);
        setCurrentUser({
          ...currentUser,
          isPasswordSet: true,
        });
        sessionStorage.setItem(
          "user",
          JSON.stringify({ ...currentUser, isPasswordSet: true })
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Verify Merchat user api call
  const verifyMerchant = async (merchantPassword: string) => {
    try {
      // Get services data from api
      const resp = await authenticateUserEmail({
        email: userEmail,
        password: merchantPassword,
      });
      resp.name = muser?.name;
      resp.userType = userType;
      resp.email = userEmail;
      sessionStorage.setItem("user", JSON.stringify(resp));
      setCurrentUser({ ...resp, userId });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 400) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error("Something went wrong, please try again later");
      }
    }
  };

  // Register user api call
  const registerCustomer = async (email = "") => {
    try {
      // Get services data from api
      const resp = await registerUser({
        email_address: email ? email : userEmail,
      });
      setUserdata(resp);
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    if (currentUser?.access_token) {
      if (!currentUser?.isProfileComplete) {
        customerProfileReset({
          first_name: currentUser?.first_name,
          last_name: currentUser?.last_name,
          phone_number: currentUser?.phone_number,
        });
        setShowProfileModal(true);
      } else if (!currentUser?.isPasswordSet) {
        setShowNewPassModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, minutes]);

  return (
    <>
      <Modal
        className="Login"
        show={props?.show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right">
            <Form {...customerEmailMethods}>
              <form onSubmit={handleCustomerEmailSubmit(onCustomerEmailSubmit)}>
                <div className="outermain_form">
                  <h2>Lets Sign you in</h2>
                  <h4>Please enter your Email Address to Login</h4>
                  <div className="outerinnerfeild">
                    <label htmlFor="email">Email</label>
                    <input
                      {...customerEmailRegister("email")}
                      type="text"
                      placeholder="Enter Email"
                    />
                    {errors?.email && (
                      <span className="text-danger" style={{ paddingTop: 5 }}>
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <button className="button-s" type="submit">
                    Login
                  </button>
                </div>
              </form>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="Login" show={show3} onHide={handleClose3} centered>
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="outermain_form">
              <Form {...merchantPasswordMethods}>
                <form
                  onSubmit={handleMerchantPasswordpSubmit(
                    onMerchantPasswordSubmit
                  )}
                >
                  <h2>Registered as merchant</h2>
                  <h4>Welcome Back , {muser?.name}</h4>
                  <div className="outerinnerfeild hsdbhsdbhsdbsh">
                    <label htmlFor="password">Password</label>
                    <input
                      {...merchantPasswordRegister("password")}
                      type={hideshow === false ? "password" : "text"}
                      placeholder="Enter Password"
                    />
                    {hideshow === true ? (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\show.png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    ) : (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\hide (1).png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    )}
                    {merchantPasswordErrors?.password && (
                      <span className="text-danger" style={{ paddingTop: 5 }}>
                        {merchantPasswordErrors.password.message}
                      </span>
                    )}
                  </div>

                  <button className="button-s" type="submit">
                    Login
                  </button>
                </form>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login veriifactionmain"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right Verification samelogin">
            <div className="Container">
              <Form {...customerOtpMethods}>
                <form onSubmit={handleCustomerOtpSubmit(onCustomerOtpSubmit)}>
                  <div className="mainformhere">
                    <h2>Please Verify OTP</h2>
                    <h4>Enter your OTP code received on Mail</h4>
                    <h5>{userEmail}</h5>
                    <div
                      className="outerinnerfeild"
                      style={{ paddingBottom: 15 }}
                    >
                      <input
                        {...customerOtpRegister("otp")}
                        type="text"
                        placeholder="Enter OTP"
                      />
                      {customerOtpErrors?.otp && (
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerOtpErrors.otp.message}
                        </span>
                      )}
                    </div>
                    <h6>
                      OTP code will expire in -{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h6>
                    <button type="submit" className={"button-s"}>
                      Confirm
                    </button>
                    <h3
                      onClick={() => authenticateUser()}
                      className={
                        seconds > 0 || minutes > 0 ? "disabled-link" : "c-p"
                      }
                      style={{
                        color:
                          seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                    >
                      Resend
                    </h3>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login veriifactionmain"
        show={show5}
        onHide={handleClose5}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right Verification samelogin">
            <div className="Container">
              <Form {...customerSignupMethods}>
                <form
                  onSubmit={handleCustomerSignupSubmit(onCustomerSignupSubmit)}
                >
                  <div className="mainformhere">
                    <h2>Please Verify OTP</h2>
                    <h4>Enter your OTP code received on Mail</h4>
                    <h5>{userEmail}</h5>
                    <div
                      className="outerinnerfeild"
                      style={{ paddingBottom: 15 }}
                    >
                      <input
                        {...customerSignupRegister("otp")}
                        type="text"
                        placeholder="Enter OTP"
                      />
                      {customerSignupErrors?.otp && (
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors.otp.message}
                        </span>
                      )}
                    </div>
                    <h6>
                      OTP code will expire in -{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h6>
                    <div className="logindetail">
                      <div className="fklexvalue">
                        <div className="outerinnerfeild">
                          <label htmlFor="first_name">First name</label>
                          <input
                            {...customerSignupRegister("first_name")}
                            type="text"
                            placeholder="First Name"
                          />
                        </div>
                        <div className="outerinnerfeild">
                          <label htmlFor="last_name">Last name</label>
                          <input
                            {...customerSignupRegister("last_name")}
                            type="text"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>

                      <Box
                        component="div"
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                        gap={3}
                      >
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.first_name?.message}
                        </span>
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.last_name?.message}
                        </span>
                      </Box>

                      <div className="outerinnerfeild">
                        <label htmlFor="phone_number">Contact No</label>
                        <Controller
                          name="phone_number"
                          control={customerSignupControl}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              defaultCountry="US"
                              id="phone_number"
                              // countrySelectProps={{ unicodeFlags: true }}
                              // international={true}
                            />
                          )}
                        />
                        {customerSignupErrors?.phone_number && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerSignupErrors.phone_number.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <button type="submit" className={"button-s"}>
                      Confirm
                    </button>
                    <h3
                      onClick={() => loginUser()}
                      className={
                        seconds > 0 || minutes > 0 ? "disabled-link" : "c-p"
                      }
                      style={{
                        color:
                          seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                    >
                      Resend
                    </h3>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login veriifactionmain"
        show={showProfileModal}
        // onHide={handleClose5}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right Verification samelogin">
            <div className="Container">
              <Form {...customerProfileMethods}>
                <form
                  onSubmit={handleCustomerProfileSubmit(
                    onCustomerProfileSubmit
                  )}
                >
                  <div className="mainformhere">
                    <h2>Complete your profile</h2>
                    {/* <h5>{userEmail}</h5> */}
                    {/* <h6>
                      OTP code will expire in -{" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h6> */}
                    <div className="logindetail">
                      <div
                        className="outerinnerfeild"
                        // style={{ paddingBottom: 15 }}
                      >
                        <label htmlFor="first_name">First name</label>
                        <input
                          {...customerProfileRegister("first_name")}
                          type="text"
                          placeholder="First Name"
                        />
                        {customerProfileErrors?.first_name && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerProfileErrors.first_name.message}
                          </span>
                        )}
                      </div>

                      <div
                        className="outerinnerfeild"
                        // style={{ paddingBottom: 15 }}
                      >
                        <label htmlFor="last_name">Last name</label>
                        <input
                          {...customerProfileRegister("last_name")}
                          type="text"
                          placeholder="Last Name"
                        />
                        {customerProfileErrors?.last_name && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerProfileErrors.last_name.message}
                          </span>
                        )}
                      </div>

                      {/* <Box
                        component="div"
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                        gap={3}
                      >
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.first_name?.message}
                        </span>
                        <span
                          className="text-danger text-start"
                          style={{ paddingTop: 5 }}
                        >
                          {customerSignupErrors?.last_name?.message}
                        </span>
                      </Box> */}

                      <div className="outerinnerfeild">
                        <label htmlFor="phone_number">Contact No</label>
                        <Controller
                          name="phone_number"
                          control={customerProfileControl}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              defaultCountry="US"
                              id="phone_number"
                              // countrySelectProps={{ unicodeFlags: true }}
                              // international={true}
                            />
                          )}
                        />
                        {customerProfileErrors?.phone_number && (
                          <span
                            className="text-danger text-start"
                            style={{ paddingTop: 5 }}
                          >
                            {customerProfileErrors.phone_number.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <button type="submit" className={"button-s"}>
                      Confirm
                    </button>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="Login" show={showNewPassModal} centered>
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="mainformhere">
              <Form {...setPasswordMethods}>
                <form
                  autoComplete="off"
                  onSubmit={handleSetPasswordpSubmit(onSetPasswordSubmit)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyItems="center"
                    flexDirection="column"
                  >
                    <h2>Set your password</h2>
                    <h4>Welcome , {currentUser?.name}</h4>
                  </Box>

                  <Box display="flex" flexDirection="column" gap="36px">
                    <div className="outerinnerfeild hsdbhsdbhsdbsh">
                      <label htmlFor="password">Password</label>
                      <input
                        {...setPasswordRegister("password")}
                        type={hideshow === false ? "password" : "text"}
                        placeholder="Enter Password"
                        autoComplete="off"
                      />
                      {hideshow === true ? (
                        <img
                          onClick={() => sethideshow(!hideshow)}
                          src="\assets\dummy\show.png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      ) : (
                        <img
                          onClick={() => sethideshow(!hideshow)}
                          src="\assets\dummy\hide (1).png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      )}
                      {setPasswordErrors?.password && (
                        <span className="text-danger" style={{ paddingTop: 5 }}>
                          {setPasswordErrors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="outerinnerfeild hsdbhsdbhsdbsh">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        {...setPasswordRegister("confirmPassword")}
                        type={hideshow2 === false ? "password" : "text"}
                        placeholder="Confirm Password"
                        autoComplete="off"
                      />
                      {hideshow2 === true ? (
                        <img
                          onClick={() => sethideshow2(!hideshow2)}
                          src="\assets\dummy\show.png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      ) : (
                        <img
                          onClick={() => sethideshow2(!hideshow2)}
                          src="\assets\dummy\hide (1).png"
                          alt="cnctwltimg"
                          className="eyeimageshere"
                        />
                      )}
                      {setPasswordErrors?.confirmPassword && (
                        <span className="text-danger" style={{ paddingTop: 5 }}>
                          {setPasswordErrors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                  </Box>

                  <button className="button-s" type="submit">
                    Submit
                  </button>
                </form>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login"
        onHide={closeAuthModal}
        show={showAuthModal}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="mainformhere">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Login via</h2>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="30px"
                  mt="50px"
                >
                  <Box
                    component="div"
                    onClick={() => handleAuthType("otp")}
                    sx={{
                      border: "0.5px solid rgba(0, 0, 0, 0.1)",
                      fontFamily: "Lexend",
                      backgroundColor: "#edf0ff",
                      borderRadius: "17px",
                      width: "200px",
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      color: "#7738a2",

                      "&:hover": {
                        backgroundColor: "#4B2763",
                        color: "white",
                      },
                    }}
                  >
                    <h6>OTP</h6>
                  </Box>
                  <Box
                    component="div"
                    onClick={() => handleAuthType("password")}
                    sx={{
                      border: "0.5px solid rgba(0, 0, 0, 0.1)",
                      fontFamily: "Lexend",
                      backgroundColor: "#edf0ff",
                      borderRadius: "17px",
                      width: "200px",
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      color: "#7738a2",

                      "&:hover": {
                        backgroundColor: "#4B2763",
                        color: "white",
                      },
                    }}
                  >
                    <h6>PASSWORD</h6>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Login"
        show={showExistPassModal}
        onHide={() => setShowExistPassModal(false)}
        centered
      >
        <Modal.Body>
          <div className="centerdiv_right p-18">
            <div className="mainformhere">
              <Form {...merchantPasswordMethods}>
                <form
                  autoComplete="off"
                  onSubmit={handleMerchantPasswordpSubmit(
                    onMerchantPasswordSubmit
                  )}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyItems="center"
                    flexDirection="column"
                  >
                    <h2>Login via Password</h2>
                    <h4>{userEmail}</h4>
                  </Box>
                  <div className="outerinnerfeild hsdbhsdbhsdbsh">
                    <label htmlFor="password">Password</label>
                    <input
                      {...merchantPasswordRegister("password")}
                      type={hideshow === false ? "password" : "text"}
                      placeholder="Enter Password"
                      autoComplete="off"
                    />
                    {hideshow === true ? (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\show.png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    ) : (
                      <img
                        onClick={() => sethideshow(!hideshow)}
                        src="\assets\dummy\hide (1).png"
                        alt="cnctwltimg"
                        className="eyeimageshere"
                      />
                    )}
                    {merchantPasswordErrors?.password && (
                      <span className="text-danger" style={{ paddingTop: 5 }}>
                        {merchantPasswordErrors.password.message}
                      </span>
                    )}
                  </div>

                  <button className="button-s" type="submit">
                    Submit
                  </button>
                </form>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
