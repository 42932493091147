import React, { useState, useEffect } from "react";
import "./specialdetail.scss";
import NavbarMain from "../../Components/NavbarMain";
import FilterNavbarMain from "../../Components/FilterNavbar";
import FooterMain from "../../Components/Footer";
import DatePicker from "react-datepicker";
// import { Link } from 'react-router-dom';
import { getGroupedSpecialistDetails } from "../../api/apiListingService";
import { addServiceTime, formatTime } from "../../utils/dateFomater";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { cartState } from "../../recoil/atoms/cartAtom";
import {
  capitalizeFirstLetterInSentence,
  formatAmount,
} from "../../utils/generalUtils";
import { AddToCartModal } from "../../Components/Dialog/AddToCart";
import moment from "moment-timezone";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SlotReservationModal from "../../Components/Dialog/SlotReservation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GroupSpecialistDetail = (props: any) => {
  const date: any = sessionStorage.getItem("dateTime");
  const currentDate: any = date ? new Date(JSON.parse(date)) : new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [specialistDetails, setSpecialistsDetails] = useState<any>([]);
  const [timeSlots, setTimeSlots] = useState<any>([]);
  const [cartItem, setCartItem] = useState<any>(null);
  const [activeIndex, setActIndex] = useState<any>();
  const [cartItemsList, setCartItemsList] = useRecoilState(cartState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const employeeId = window.location.pathname.split("/").pop() || null;
  const merchantId = props?.location?.state?.params?.merchantId || "";
  const serviceId = props?.location?.state?.params?.serviceId || "";
  const [value, setValue] = React.useState(0);
  const [showReservationModal, setShowReservationModal] =
    useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState<any>(null);
  const [opens, setOpens] = useState<any>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchGroupedSpecialistDetails = async (date: any) => {
    setOpens(true);
    try {
      setTimeSlots([]);
      setCartItem(null);
      setActIndex(-1);
      setCurrentLocation("");
      setLocationValue("");
      const body = {
        startTime: moment(date).utc().startOf("day").toISOString(),
        endTime: moment(date).utc().add(1, "day").startOf("day").toISOString(),
      };
      const resp = await getGroupedSpecialistDetails(
        serviceId,
        employeeId as any,
        body
      );
      setSpecialistsDetails(resp);
      if (!employeeDetails) {
        setEmployeeDetails(resp);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpens(false);
    }
  };
  const setTimeSlot = (service: any, startTime: any, index: any) => {
    const newService = { ...service };
    let existingCartService = cartItemsList.find(
      (arr: any) =>
        arr.bookingId === newService?.bookingId &&
        arr.cartReserveStatus !== "cart"
    );
    if (existingCartService) {
      newService.availableSeats =
        newService.totalSeats - existingCartService.seats;
    }

    const currentprice = formatAmount(specialistDetails?.serviceDetails?.price);
    let cartItem = {
      ...specialistDetails,
      ...newService,
      bookingDate: startDate,
      serviceType: "Group",
      timezone: newService?.timezoneId,
      start: startTime,
      end: addServiceTime(
        startTime,
        specialistDetails?.serviceDetails?.duration
      ),
      employeeId: employeeId,
      seats: 1,
      tip: 0,
      cartReserveStatus: "cart",
      servicename: specialistDetails?.serviceDetails?.name,
      nickName: specialistDetails?.employeeDetails?.name,
      currentprice: currentprice,
      serviceDuration: specialistDetails?.serviceDetails?.duration,
      location: currentLocation,
      merchantId: merchantId ? parseInt(merchantId) : "",
    };
    setCartItem(cartItem);

    setActIndex(index);
  };

  const handleReservationModal = () => setShowReservationModal(false);

  const handleAddToCart = () => {
    setShowReservationModal(false);
    addToBasket();
  };

  const addToBasket = () => {
    if (
      cartItemsList.length === 0 ||
      cartItemsList.every(
        (item: any) => item.merchantId === cartItem.merchantId
      )
    ) {
      cartItem.cartReserveStatus = "incart";
      const existingItem = cartItemsList.find(
        (item: any) => item.timeSlot === cartItem.timeSlot
      );
      if (existingItem) {
        setCartItemsList(
          cartItemsList.map((item: any) =>
            item.timeSlot === cartItem.timeSlot
              ? { ...item, seats: item.seats + cartItem.seats }
              : item
          )
        );
      } else {
        setCartItemsList([...cartItemsList, cartItem]);
      }
      toast.success("Service added to the basket", {
        autoClose: 5000,
      });

      setActIndex(-1);
      setCartItem(null);
      setCurrentLocation("");
      setLocationValue("");
    } else {
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleResetAndNewItems = () => {
    cartItem.cartReserveStatus = "incart";
    toast.success("Service added to the basket", {
      autoClose: 5000,
    });

    const mergedObject = Object.assign({}, cartItem);
    setCartItemsList([mergedObject]);
    setActIndex(-1);
    setCartItem(null);
    setCurrentLocation("");
    setLocationValue("");
    handleClose();
  };

  useEffect(() => {
    fetchGroupedSpecialistDetails(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, cartItemsList]);

  useEffect(() => {
    if (specialistDetails?.groupServices?.length === 1) {
      setCurrentLocation(specialistDetails?.groupServices[0]?.location);
      setLocationValue(JSON.stringify(specialistDetails?.groupServices[0]));
      // setCartItem(null);
      setTimeSlots(specialistDetails?.groupServices[0]?.services);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialistDetails?.groupServices]);

  const zoneAbbr =
    timeSlots?.length > 0 && timeSlots?.[0]?.timezoneId
      ? `(${moment.tz(timeSlots?.[0]?.timezoneId).format("z")})`
      : "";

  const serviceDuration = employeeDetails?.serviceDetails?.duration
    ? employeeDetails?.serviceDetails?.duration <= 30
      ? `${employeeDetails?.serviceDetails?.duration} minutes`
      : `${moment
          .duration(employeeDetails?.serviceDetails?.duration, "minutes")
          .asHours()} ${
          moment
            .duration(employeeDetails?.serviceDetails?.duration, "minutes")
            .asHours() > 1
            ? "hours"
            : "hour"
        }`
    : "";

  return (
    <>
      {opens === true ? (
        <div className="loaderStart">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="spciallistdetailpagecss">
        <NavbarMain />
        <FilterNavbarMain />
        <section className="Specialdetail">
          <div className="container-main-self">
            <div className="texthere">
              <h3>Specialist Detail</h3>
            </div>
            <div className="outermain">
              <div className="uppermainfirst" style={{ gap: "30px" }}>
                <div className="leftsidefirst">
                  <div className="outmain-main">
                    <div className="leftsideinner">
                      <img
                        src={
                          employeeDetails?.employeeDetails?.displayPicture || ""
                        }
                        alt="cnctwltimg"
                        className="storeimageleft"
                      />
                    </div>
                    <div className="rightsideinner">
                      <h2
                        style={{ lineHeight: "normal", whiteSpace: "nowrap" }}
                      >
                        {capitalizeFirstLetterInSentence(
                          employeeDetails?.employeeDetails?.name || ""
                        )}{" "}
                      </h2>
                      <h3 style={{ whiteSpace: "nowrap", padding: 0 }}>
                        {capitalizeFirstLetterInSentence(
                          employeeDetails?.serviceDetails?.merchantName || ""
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <Box pb={3.5} display="flex" flexDirection="column" gap={2}>
                <div className="textjust" style={{ marginBottom: 0 }}>
                  <div className="haiers"></div>
                  <h4>
                    {employeeDetails?.serviceDetails?.name || ""} at{" "}
                    {capitalizeFirstLetterInSentence(
                      employeeDetails?.serviceDetails?.merchantName || ""
                    )}
                  </h4>
                </div>
                {serviceDuration && (
                  <div className="textjust" style={{ marginBottom: 0 }}>
                    <div className="haiers"></div>
                    <h4>Service duration - {serviceDuration}</h4>
                  </div>
                )}
                {employeeDetails?.serviceDetails?.price && (
                  <div className="textjust" style={{ marginBottom: 0 }}>
                    <div className="haiers"></div>
                    <h4>
                      Service price - $
                      {formatAmount(employeeDetails?.serviceDetails?.price)}
                    </h4>
                  </div>
                )}
              </Box>
              <div className="bookingdate">
                <label>Booking Date</label>
                <div className="inner-11 ooooo">
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date);
                    }}
                    dateFormat="MMM dd, yyyy"
                  />
                  <img
                    src="\assets\allstore\Calender.png"
                    alt="cnctwltimg"
                    className="cnctwltimsdsdsddsg"
                  />
                </div>
              </div>
              <Box
                sx={{
                  border: "none!important",
                  marginBottom: "0px!important",
                }}
                flexDirection="column"
                gap={2}
                className="timeavaible"
              >
                <Box display="flex" alignItems="center">
                  <img
                    src="\assets\imag\clarity_map-solid.svg"
                    alt="cnctwltimg"
                    className="storeimageleft"
                  />
                  <div className="inneravable">
                    <h5>Select Location</h5>
                  </div>
                </Box>
                <Box
                  sx={{
                    touchAction: "none",
                  }}
                >
                  {specialistDetails?.groupServices?.length > 0 ? (
                    <FormControl>
                      {!currentLocation && (
                        <InputLabel id="outlined-select-location-label">
                          Select Location
                        </InputLabel>
                      )}
                      <TextField
                        variant="outlined"
                        id="outlined-select-location"
                        select
                        disabled={
                          specialistDetails?.groupServices?.length > 1
                            ? false
                            : true
                        }
                        value={locationValue || ""}
                        onChange={(e: any) => {
                          const data = JSON.parse(e.target.value);
                          setCartItem(null);
                          setCurrentLocation(data.location);
                          setLocationValue(JSON.stringify(data));
                          setTimeSlots(data?.services);
                        }}
                        sx={{
                          borderRadius: "5px",
                          border: "1px solid #4B2763",
                          background: "#EDF0FF",
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          width: "470px",
                          height: "55px",
                          paddingLeft: "12px",
                          outline: "none",
                        }}
                      >
                        {specialistDetails?.groupServices?.map(
                          (option: any, index: number) => (
                            <MenuItem
                              key={index}
                              value={JSON.stringify(option)}
                            >
                              {option.location}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </FormControl>
                  ) : (
                    <Box
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      No locations are available at this time!
                    </Box>
                  )}
                </Box>
              </Box>

              {timeSlots?.length > 0 &&
              timeSlots?.[0]?.timezoneId !== moment.tz.guess(true) ? (
                <Box sx={{ width: "100%" }}>
                  <Box display="flex" gap={1} alignItems="baseline" mb={2.4}>
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: "#fd7e14",
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      Indicates the time slots for{" "}
                      {moment(startDate).add(1, "day").format("MMM DD, YYYY")}
                    </Box>
                  </Box>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Timezone tabs"
                      sx={{
                        "&.MuiTabs-root": {
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },

                          "& .MuiButtonBase-root": {
                            // borderRadius: "20px",
                            backgroundColor: "#DADADA",
                            color: "#707070",
                            width: 235,

                            "&.local": {
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                            },

                            "&.store": {
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                            },

                            "&.Mui-selected": {
                              backgroundColor: "#4B2763",
                              color: "#fff",
                              fontWeight: 700,
                            },
                          },
                        },
                      }}
                    >
                      <Tab
                        className="local"
                        label="Local Time"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="store"
                        label={`Store Time ${zoneAbbr}`}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Box className="allslots">
                      {timeSlots?.length > 0 ? (
                        timeSlots.map((sl: any, index: any) => (
                          <div
                            key={index}
                            style={{ position: "relative" }}
                            onClick={() => {
                              setTimeSlot(sl, sl?.timeSlot, index);
                            }}
                            className="slotdesignsimple"
                          >
                            {moment(sl?.timeSlot).format("MMM DD YYYY") !==
                            moment(sl?.timeSlot)
                              .tz(timeSlots?.[0]?.timezoneId)
                              .format("MMM DD YYYY") ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: "#fd7e14",
                                    borderRadius: "50%",
                                  }}
                                />
                              </Box>
                            ) : null}

                            <h2
                              className={
                                index === activeIndex ? `slot-selected` : ""
                              }
                            >
                              {formatTime(sl.timeSlot)}
                            </h2>
                          </div>
                        ))
                      ) : currentLocation ? (
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          No slots available for{" "}
                          {specialistDetails?.employeeDetails?.name} at{" "}
                          {moment(startDate).format("MMM DD, YYYY")} in{" "}
                          {currentLocation}
                        </Box>
                      ) : null}
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="allslots">
                      {timeSlots?.length > 0 ? (
                        timeSlots.map((sl: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => {
                              setTimeSlot(sl, sl.timeSlot, index);
                            }}
                            className="slotdesignsimple"
                          >
                            <h2
                              className={
                                index === activeIndex ? `slot-selected` : ""
                              }
                            >
                              {moment(sl.timeSlot)
                                .tz(timeSlots?.[0]?.timezoneId)
                                .format("h:mm A")}
                            </h2>
                          </div>
                        ))
                      ) : currentLocation ? (
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          No slots available for{" "}
                          {specialistDetails?.employeeDetails?.name} at{" "}
                          {moment(startDate).format("MMM DD, YYYY")} in{" "}
                          {currentLocation}
                        </Box>
                      ) : null}
                    </div>
                  </CustomTabPanel>
                </Box>
              ) : (
                <div className="allslots">
                  {timeSlots?.length > 0 ? (
                    timeSlots.map((sl: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => {
                          setTimeSlot(sl, sl.timeSlot, index);
                        }}
                        className="slotdesignsimple"
                      >
                        <h2
                          className={
                            index === activeIndex ? `slot-selected` : ""
                          }
                        >
                          {moment(sl.timeSlot)
                            .tz(timeSlots?.[0]?.timezoneId)
                            .format("h:mm A")}
                        </h2>
                      </div>
                    ))
                  ) : currentLocation ? (
                    <Box
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      No slots available for{" "}
                      {specialistDetails?.employeeDetails?.name} at{" "}
                      {moment(startDate).format("MMM DD, YYYY")} in{" "}
                      {currentLocation}
                    </Box>
                  ) : null}
                </div>
              )}

              {cartItem && (
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt="6px"
                    gap="18px"
                    mb={"12px"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_45_3082)">
                        <path
                          d="M13.5413 20.7012H6.45801C6.11301 20.7012 5.83301 20.4212 5.83301 20.0762C5.83301 19.7312 6.11301 19.4512 6.45801 19.4512H13.5413C13.8863 19.4512 14.1663 19.7312 14.1663 20.0762C14.1663 20.4212 13.8863 20.7012 13.5413 20.7012Z"
                          fill="#4B2662"
                        />
                        <path
                          d="M14.2177 11.5345H5.78272C5.35106 11.5345 4.94439 11.3445 4.66689 11.0145C4.38856 10.6845 4.27189 10.2512 4.34606 9.82617L5.72272 1.90951C5.84439 1.20951 6.44856 0.701172 7.15939 0.701172H12.8402C13.5519 0.701172 14.1561 1.20951 14.2769 1.91034L15.6536 9.82701C15.7277 10.252 15.6111 10.6853 15.3327 11.0153C15.0561 11.3445 14.6494 11.5345 14.2177 11.5345ZM7.15939 1.95117C7.05772 1.95117 6.97106 2.02367 6.95356 2.12367L5.57689 10.0403C5.56272 10.1237 5.59939 10.182 5.62272 10.2103C5.64606 10.2387 5.69772 10.2845 5.78189 10.2845H14.2169C14.3019 10.2845 14.3536 10.2378 14.3761 10.2103C14.3994 10.1828 14.4361 10.1237 14.4219 10.0403L13.0452 2.12367C13.0286 2.02367 12.9427 1.95117 12.8411 1.95117H7.15939Z"
                          fill="#4B2662"
                        />
                        <path
                          d="M10 20.7012C9.655 20.7012 9.375 20.4212 9.375 20.0762V16.3262C9.375 15.9812 9.655 15.7012 10 15.7012C10.345 15.7012 10.625 15.9812 10.625 16.3262V20.0762C10.625 20.4212 10.345 20.7012 10 20.7012Z"
                          fill="#4B2662"
                        />
                        <path
                          d="M16.0413 16.9505H3.95801C3.61301 16.9505 3.33301 16.6705 3.33301 16.3255V14.6589C3.33301 13.3947 4.36051 12.3672 5.62467 12.3672H14.3747C15.6388 12.3672 16.6663 13.3947 16.6663 14.6589V16.3255C16.6663 16.6705 16.3863 16.9505 16.0413 16.9505ZM4.58301 15.7005H15.4163V14.6589C15.4163 14.0847 14.9488 13.6172 14.3747 13.6172H5.62467C5.05051 13.6172 4.58301 14.0847 4.58301 14.6589V15.7005Z"
                          fill="#4B2662"
                        />
                        <path
                          d="M16.042 15.9089C15.697 15.9089 15.417 15.6289 15.417 15.2839V12.7839C15.417 11.5197 16.4445 10.4922 17.7087 10.4922C18.0537 10.4922 18.3337 10.7722 18.3337 11.1172C18.3337 11.4622 18.0537 11.7422 17.7087 11.7422C17.1345 11.7422 16.667 12.2097 16.667 12.7839V15.2839C16.667 15.6289 16.387 15.9089 16.042 15.9089Z"
                          fill="#4B2662"
                        />
                        <path
                          d="M3.95866 15.9089C3.61366 15.9089 3.33366 15.6289 3.33366 15.2839V12.7839C3.33366 12.2097 2.86616 11.7422 2.29199 11.7422C1.94699 11.7422 1.66699 11.4622 1.66699 11.1172C1.66699 10.7722 1.94699 10.4922 2.29199 10.4922C3.55616 10.4922 4.58366 11.5197 4.58366 12.7839V15.2839C4.58366 15.6289 4.30366 15.9089 3.95866 15.9089Z"
                          fill="#4B2662"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_45_3082">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0 0.701172)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <div className="inneravable">
                      <h5>Available Seats</h5>
                    </div>
                  </Box>
                  {cartItem?.availableSeats > 0 ? (
                    <Box maxWidth="470px" mb="12px">
                      <Box
                        sx={{
                          borderRadius: "6px",
                          border: "0.5px solid #4B2763",
                          background: "#E6E6E6",
                          p: "10px 20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          minHeight: "55px",
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const item = { ...cartItem };
                            if (item.seats > 1) {
                              item.seats = item.seats - 1;
                              setCartItem(item);
                            }
                          }}
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="1.20117"
                              width="19"
                              height="19"
                              rx="5.5"
                              fill="#E6E6E6"
                            />
                            <rect
                              x="0.5"
                              y="1.20117"
                              width="19"
                              height="19"
                              rx="5.5"
                              stroke="#4B2763"
                            />
                            <path
                              d="M6 10.7012H14"
                              stroke="#4B2763"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                            />
                          </svg>
                        </Box>
                        <Box component="p" fontWeight="600" color="#4B2763">
                          {cartItem?.seats} Seat{cartItem?.seats > 1 ? "s" : ""}
                        </Box>
                        <Box
                          component="div"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const item = { ...cartItem };
                            if (item.seats < item.availableSeats) {
                              item.seats = item.seats + 1;
                              setCartItem(item);
                            }
                          }}
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="1.20117"
                              width="19"
                              height="19"
                              rx="5.5"
                              fill="#E6E6E6"
                            />
                            <rect
                              x="0.5"
                              y="1.20117"
                              width="19"
                              height="19"
                              rx="5.5"
                              stroke="#4B2763"
                            />
                            <path
                              d="M6 10.7012H14"
                              stroke="#4B2763"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                            />
                            <path
                              d="M10 6.70117L10 14.7012"
                              stroke="#4B2763"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                            />
                          </svg>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      No seats currently available!
                    </Box>
                  )}
                  {cartItem?.availableSeats > 0 && (
                    <Box
                      sx={{
                        color: "#707070",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      You can select up to{" "}
                      <span style={{ fontWeight: 600, color: "#4B2763" }}>
                        {cartItem?.availableSeats} Seat
                        {cartItem?.availableSeats > 1 ? "s" : ""}
                      </span>
                      .
                    </Box>
                  )}
                </Box>
              )}

              {cartItem?.availableSeats > 0 && (
                <button
                  onClick={() => {
                    setShowReservationModal(true);
                  }}
                  style={{
                    marginTop: "60px",
                  }}
                  className={
                    activeIndex >= 0
                      ? "buttonaddtobacket"
                      : "disabled-btn buttonaddtobacket"
                  }
                >
                  Add to Basket
                </button>
              )}
            </div>
          </div>
        </section>
        <FooterMain />
      </div>
      <AddToCartModal
        showModal={showModal}
        handleClose={handleClose}
        handleResetAndNewItems={handleResetAndNewItems}
      />
      {showReservationModal && cartItem && (
        <SlotReservationModal
          showModal={showReservationModal}
          handleClose={handleReservationModal}
          serviceName={cartItem?.servicename}
          specialistName={cartItem?.nickName}
          timezone={cartItem?.timezoneId}
          startDate={cartItem?.start}
          endDate={cartItem?.end}
          bookingDate={cartItem?.bookingDate}
          seats={cartItem?.seats}
          handleAddToCart={handleAddToCart}
        />
      )}
    </>
  );
};

export default GroupSpecialistDetail;
