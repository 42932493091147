import React from "react";

const Gallery = (props: any) => {
  if (!props.merchantDetails?.gallery?.length) return null;

  const bannerImage =
    props.merchantDetails?.gallery?.find(
      (image: any) => image?.featuredind === true
    )?.mediaurl || "";

  return (
    <div className="gallery">
      <div className="row p-0">
        <div className="col-lg-4 padding-main">
          <div className="galleryimages llliii">
            <img
              src={
                bannerImage
                  ? bannerImage
                  : "/assets/storeimage/placeholder-img.png"
              }
              alt="cnctwltimg"
              className="storeimageleft"
            />
          </div>
        </div>
        <div className="col-lg-8 p-0">
          <div className="row p-0">
            {props.merchantDetails?.gallery.map((img: any, index: number) => {
              if (
                img?.featuredind !== true &&
                img.ref !== "logo" &&
                index <= 7
              ) {
                return (
                  <div key={index} className="col-lg-4 padding-child">
                    <div className="galleryimages iiiooo">
                      <img
                        src={
                          img?.mediaurl
                            ? img.mediaurl
                            : "/assets/storeimage/placeholder-img.png"
                        }
                        alt="cnctwltimg"
                        className="storeimageleft"
                      />
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
