import moment from "moment-timezone";

export const formatTime = (date: any) => {
  // Split the time string into hours and minutes
  //  const [hours, minutes] = date.split(':');
  const currentDate = new Date(date);

  // Get the hour and minutes
  const hours: any = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");

  // Convert hours to a number
  const hoursNum = parseInt(hours);

  // Determine whether it's AM or PM
  const period = hoursNum >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hoursNum % 12 || 12;

  // Create the AM/PM time string
  const time12 = `${hours12}:${minutes} ${period}`;
  return time12;
};

export const adjustStartTime = (
  timestamp: string,
  prePrepDuration: number = 0
): string => {
  // Parse the initial timestamp
  const initialTime = moment(timestamp);

  // Subtract the prePrepDuration from the initial time
  const adjustedTime = initialTime.subtract(prePrepDuration, "minutes");

  // Return the adjusted time in the same format as the input timestamp
  return adjustedTime.toISOString();
};

export const adjustEndTime = (
  timestamp: string,
  postPrepDuration: number = 0
): string => {
  // Parse the initial timestamp
  const initialTime = moment(timestamp);

  // Add the postPrepDuration to the initial time
  const adjustedTime = initialTime.add(postPrepDuration, "minutes");

  // Return the adjusted time in the same format as the input timestamp
  return adjustedTime.toISOString();
};

export const addServiceTime = (
  date: any,
  duration: number,
  postPrepDuration: number = 0
) => {
  const originalTime = new Date(date);
  // Add service duration minutes to the start time of service
  originalTime.setMinutes(
    originalTime.getMinutes() + (duration + postPrepDuration)
  );
  const utcString = originalTime.toISOString(); // Convert to ISO string
  return utcString;
};

export const checkTimeOverlap = (
  mStartTime: any,
  mEndTime: any,
  spStartTime: any,
  spEndTime: any
) => {
  const start1 = new Date(`${mStartTime}`);
  const end1 = new Date(`${mEndTime}`);
  const start2 = new Date(`${spStartTime}`);
  const end2 = new Date(`${spEndTime}`);

  if (start2.getTime() === end2.getTime()) {
    return false;
  }

  if (start1 < end2 && end1 > start2) {
    return false;
  } else {
    return true;
  }
};

export const formatTimeslot = (dateProp: any, startTime: any) => {
  const propsDate = moment.utc(dateProp).startOf("day"); // Initialize with UTC
  let sTime = moment.utc(startTime).startOf("day"); // Initialize with UTC

  // console.log("COMING DATE: ", propsDate);
  // console.log("COMING S TIME: ", sTime);

  // console.log("Formatted propsDate: ", propsDate.format()); // Log formatted date
  // console.log("Formatted sTime: ", sTime.format()); // Log formatted date

  // console.log("SAME DAY CHECK: ", propsDate.isSame(sTime));

  const startTimeMoment = moment.utc(startTime);

  if (propsDate.isSame(sTime)) {
    // console.log("SAME DAY");
    sTime = sTime.set({
      hour: moment(startTimeMoment).get("hour"),
      minute: moment(startTimeMoment).get("minute"),
      second: moment(startTimeMoment).get("second"),
    });
    // console.log("ADJUSTED S TIME: ", sTime.toISOString());
    return sTime.toISOString();
  } else {
    // Otherwise, subtract 1 day from start time and return it
    // console.log("NOT SAME DAY");
    const adjustedTime = sTime
      .set({
        hour: moment(startTimeMoment).get("hour"),
        minute: moment(startTimeMoment).get("minute"),
        second: moment(startTimeMoment).get("second"),
      })
      .subtract(1, "day");
    return adjustedTime.toISOString();
  }
};

export function formatSearchDateTime(dateString: string) {
  // Parse the date string using Moment.js
  const dateTime = moment(dateString);

  // Extract the date components (year, month, day)
  const year = dateTime.year();
  const month = dateTime.month() + 1; // Months are zero-based
  const day = dateTime.date();

  // Convert the time to UTC and extract the time components (hour, minute, second, millisecond)
  const utcTime = dateTime.utc();
  const hour = utcTime.hours();
  const minute = utcTime.minutes();
  const second = utcTime.seconds();
  const millisecond = utcTime.milliseconds();

  // Create a new Moment.js object with the original date but the time in UTC
  const result = moment.utc([
    year,
    month - 1,
    day,
    hour,
    minute,
    second,
    millisecond,
  ]);

  // console.log("Original date/time: " + dateTime.toISOString());
  // console.log("Adjusted UTC date/time: " + result.toISOString());

  return result.toISOString();
}

export const calculateDurationInMinutes = (
  start: string,
  end: string
): number => {
  const startTime: Date = new Date(start);
  const endTime: Date = new Date(end);

  // Calculate the time difference in minutes
  const timeDiff: number =
    (endTime.getTime() - startTime.getTime()) / (1000 * 60);

  return Math.floor(timeDiff);
};
