import { Box } from "@mui/material";

const AdditionalNotesList = ({ notes }: any) => {
  return (
    <Box display="flex" flexDirection="column" gap="24px" mb="24px">
      {notes.map((note: any, index: number) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#F0F2F5",
            color: "#050505",
            py: "22px",
            px: "28px",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            borderRadius: "17px",
          }}
        >
          <Box component="p" fontWeight={600} fontSize="20px">
            {note?.sender}
          </Box>
          <Box component="p" fontSize="18px">
            {note?.content}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AdditionalNotesList;
